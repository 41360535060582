import React, { memo } from 'react';
import classes from './banner-mobile.module.scss';

interface Props {
    closeMobileBanner: () => void;
    handleInterstitialClick: () => void;
}

export default memo(function BannerMobile(props: Props) {
    return (
        <>
            <div className={classes['parent-wrapper']}>
                <div className={classes['heading']}>
                    <img
                        className={classes['banner']}
                        src="https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg"
                        alt="Job Image"
                    />
                    <img
                        className={classes['logo']}
                        src={'https://jobget-static.s3.amazonaws.com/new/job-get-baner.svg'}
                        alt="Job Logo"
                    />
                </div>
                <div className={classes['body']}>
                    <div className={classes['title']}>Get Hired!</div>
                    <span className={classes['info']}>Power up your job search</span>
                    <span className={classes['info']}>anytime, anywhere.</span>
                    <button className={classes['btn']} onClick={props.handleInterstitialClick}>
                        Download the App
                    </button>
                    <span className={classes['dismiss-banner']} onClick={() => props.closeMobileBanner()}>
                        I already have the app
                    </span>
                </div>
            </div>
        </>
    );
});

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo, useEffect, useState } from 'react';
import classes from './country.module.scss';
import * as options from '../../utils/country-code.json';
interface Props {
    confirm: (value: string) => void;
}
//@ts-ignore
const countryCode = options.default;
export default memo(function CountryPicker(props: Props) {
    const [searchText, setSearchText] = useState<string>('');
    const [filterCountryCode, setFilterCountryCode] = useState([]);
    useEffect(() => {
        setFilterCountryCode(countryCode);
    }, []);
    function confirm(value: string) {
        props.confirm(value);
    }
    const className = `short-list ${classes['country-code-modal']}`;
    return (
        <div className={classes['country-container']}>
            <div className={classes['field-container']}>
                <input
                    className={classes['country-field-input']}
                    type="text"
                    placeholder="Enter country name"
                    value={searchText}
                    onChange={(event) => {
                        setSearchText(event.target.value);
                        if (event.target.value) {
                            const value = event.target.value || '';
                            const filterCountryCode = countryCode.filter((item: any) =>
                                item.label.toLowerCase().includes(value.toLowerCase()),
                            );
                            setFilterCountryCode(filterCountryCode);
                        } else {
                            setFilterCountryCode(countryCode);
                        }
                    }}
                    aria-label="Input a value for country name"
                />
            </div>
            {filterCountryCode && (
                <ul className={className}>
                    {filterCountryCode.map((item: any, index: number) => (
                        <li className={classes['country-code-text']} key={index} onClick={() => confirm(item.value)}>
                            <span>{item.label}</span>
                            <span> {item.value}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import rootSaga from '../sagas/rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();

const store = (): any => {
    const middleware = [sagaMiddleware];
    //remove dev tools before production deployment
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
    sagaMiddleware.run(rootSaga);
    return store;
};

export default store;

import * as types from '../actionTypes/profile';
import { ProfileDetails, ContactUsPayloadType, ReviewEmployerPayloadType } from '../../utils/types';

export function addProfileInfo(payload: Partial<ProfileDetails>): types.AddProfileInfo {
    return {
        type: types.ADD_PROFILE_INFO,
        payload,
    };
}

export function addProfileInfoSuccess(payload: Partial<ProfileDetails>): types.AddProfileInfoSuccess {
    return {
        type: types.ADD_PROFILE_INFO_SUCCESS,
        payload,
    };
}

export function editProfileInfo(payload: Partial<ProfileDetails>): types.EditProfileInfo {
    return {
        type: types.EDIT_PROFILE_INFO,
        payload,
    };
}

export function editProfileInfoSuccess(payload: Partial<ProfileDetails>): types.EditProfileInfoSuccess {
    return {
        type: types.EDIT_PROFILE_INFO_SUCCESS,
        payload,
    };
}

export function setProfileInfoAfterOnboarding(payload: ProfileDetails): types.SetProfileInfoAfterOnBoarding {
    return {
        type: types.SET_PROFILE_INFO_AFTER_ONBOARDING,
        payload,
    };
}

export function setProfileOnRefresh(): types.SetProfileOnRefresh {
    return {
        type: types.SET_PROFILE_ON_REFRESH,
    };
}

export function setProfileOnRefreshSuccess(payload: ProfileDetails): types.SetProfileOnRefreshSuccess {
    return {
        type: types.SET_PROFILE_ON_REFRESH_SUCCESS,
        payload,
    };
}

export function contactUs(payload: ContactUsPayloadType): types.ContactUs {
    return {
        type: types.CONTACT_US,
        payload,
    };
}

export function reviewEmployer(payload: ReviewEmployerPayloadType): types.ReviewEmployer {
    return {
        type: types.REVIEW_EMPLOYER,
        payload,
    };
}

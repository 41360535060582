import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { variantType } from '../../utils/services/sessions';
import Utils from '../../utils';
import axios from 'axios';
import FacebookEvent from '../../utils/services/facebookEvent';
import './banner.scss';
import BannerDownload from './banner-download';
import BannerParent from './banner-parent';
import CountryPicker from './country';
import { getCookie } from '../../utils/services/sessions';
import MobileBannerChild from './banner-mobile';
import BannerJob from './banner-job';

interface Props {
    show?: boolean;
    setShowBanner: () => void;
}
const Banner = memo(function BannerComponent(props: Props) {
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [mobile, setMobile] = useState<any>('');
    const [error, setError] = useState<any>('');
    const [code, setCode] = useState<string>('+1');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [donwloadType, setDownloadType] = useState<number>(0);
    const [buttonenable, setButtonEnable] = useState<boolean>(true);
    const [showCountryCodeModal, setShowCountryCodeModal] = useState<boolean>(false);

    const userDataReducer = useSelector((state: any) => state.profileDetails);
    React.useEffect(() => {
        if (userDataReducer.isMobileAppUsed === 1) {
            props.setShowBanner();
        }
    }, [userDataReducer.isMobileAppUsed]);

    function downloadApp() {
        if (variantType() === 1) {
            FacebookEvent.logCustomEvent('banner');
            setDownloadType(1);
            setShowDownloadModal(true);
        } else {
            FacebookEvent.logCustomEvent('banner_variant');
            setDownloadType(2);
        }
    }
    async function sendDownloadLink(payload: any) {
        const endPoint = 'https://sms.jobget.com/sms';
        await axios.post(endPoint, payload);
        setSuccessMessage('Horray! download link has been sent to your phone');
        setTimeout(() => {
            setSuccessMessage('');
        }, 4000);
        setDownloadType(0);
        // log sucess full muessage
        if (variantType() === 1) {
            FacebookEvent.logCustomEvent('banner_click');
        } else {
            FacebookEvent.logCustomEvent('banner_variant_click');
        }
        props.setShowBanner();
    }
    function sendDownload(banerType: number) {
        if (!mobile) {
            setError('Please specify your Phone Number');
        } else if (!Utils.constant.phoneRegex.test(mobile.replace(/ |-/g, ''))) {
            setError('Please specify valid Phone Number');
        } else {
            setError('');
            const phone = mobile.replace(/ |-/g, '');
            const src = getCookie('source') || '';
            const payload = {
                phoneNumber: phone,
                countryCode: code,
                bannerType: banerType,
                downloadLink: Utils.constant.getJobUrlLink(src),
            };
            sendDownloadLink(payload);
        }
    }
    function getMobile(value: string) {
        setMobile(formatPhoneNumber(value));
        if (!value) {
            setButtonEnable(true);
        } else if (!Utils.constant.phoneRegex.test(value.replace(/ |-/g, '')) || code === '') {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }
    function updateCode(val: string) {
        setCode(val);
        if (!val || (!mobile && !Utils.constant.phoneRegex.test(mobile.replace(/ |-/g, '')))) {
            setButtonEnable(true);
        } else {
            setButtonEnable(false);
        }
    }
    function countryCodeSelect(val: string) {
        updateCode(val);
        toggleCountryCode();
    }
    function toggleCountryCode() {
        setShowCountryCodeModal(!showCountryCodeModal);
    }
    function formatPhoneNumber(str: string) {
        const input = str.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);
        if (input.length > 6) {
            return `${zip} - ${middle} - ${last}`;
        } else if (input.length > 3) {
            return `${zip} - ${middle}`;
        } else if (input.length > 0) {
            return `${zip}`;
        }
    }
    return (
        <>
            <div className="bottom-banner" id="bottom-banner">
                <video autoPlay loop muted playsInline>
                    <source
                        src={'https://s3.amazonaws.com/www.jobget.com/app-assets/App-download.webm'}
                        type="video/webm"
                    />
                </video>
                <div className="bottom-banner-right">
                    <div>
                        <span>IMPORTANT NEXT STEPS</span>
                        <p>Download the JobGet app so hiring managers can instantly message you for an interview.</p>
                    </div>
                    <div className="bottom-banner-cta">
                        {successMessage === '' ? (
                            <>
                                <input
                                    value={mobile}
                                    onChange={(e) => getMobile(e.target.value)}
                                    placeholder={'Your Phone Number'}
                                    type={'text'}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            sendDownload(2);
                                        }
                                    }}
                                    aria-label="Input a value for mobile number"
                                />
                                <button onClick={() => sendDownload(2)}>Get the App</button>
                                {error ? <span className={'error-text'}>{error}</span> : ''}
                            </>
                        ) : (
                            <span className="download-success">Hooray! Download link has been sent to your phone</span>
                        )}
                    </div>
                </div>
                {/* <div className="bottom-banner-phone-container-desktop">
                    {donwloadType == 2 && (
                        <>
                            {successMessage === '' ? (
                                <div className="field-container">
                                    <div className={`banner-field-input-code`} onClick={() => toggleCountryCode()}>
                                        <span className={`input-icon phone`}></span>
                                        <span className="text-center  w-100">{code}</span>
                                    </div>
                                    <input
                                        className={`banner-field-input`}
                                        value={mobile}
                                        onChange={(e) => getMobile(e.target.value)}
                                        placeholder={'Enter your mobile number'}
                                        type={'text'}
                                        onKeyUp={(event) => {}}
                                        aria-label="Input a value for mobile number"
                                    />
                                    <button
                                        className={`bottom-banner-button bottom-banner-button-mobile ${
                                            buttonenable ? 'bottom-button-disable' : ''
                                        }`}
                                        onClick={() => sendDownload(2)}
                                        disabled={buttonenable}
                                    >
                                        Send
                                    </button>
                                </div>
                            ) : (
                                <span className="download-success">
                                    Hooray! Download link has been sent to your phone
                                </span>
                            )}
                        </>
                    )}

                    {(donwloadType === 0 || donwloadType === 1) && (
                        <button className="bottom-banner-button" onClick={() => downloadApp()}>
                            Get the App
                        </button>
                    )}
                </div> */}

                <span className="banner-close" onClick={() => props.setShowBanner()}>
                    X
                </span>
            </div>

            {showDownloadModal && (
                <BannerParent clickHandler={() => setShowDownloadModal(false)}>
                    <BannerDownload
                        code={code}
                        mobile={mobile}
                        getMobile={getMobile}
                        buttonEnable={buttonenable}
                        sendDownload={sendDownload}
                        successMessage={successMessage}
                        toggleCountryCode={toggleCountryCode}
                    />
                </BannerParent>
            )}
            {showCountryCodeModal && (
                <BannerParent clickHandler={toggleCountryCode}>
                    <CountryPicker confirm={countryCodeSelect} />
                </BannerParent>
            )}
        </>
    );
});
export default Banner;

export interface MobileBannerProps {
    source: any;
    show?: boolean;
    setShowBanner: () => void;
    closeMobileBanner: () => void;
}
export const MobileBanner = memo(function MobileBanner(props: MobileBannerProps) {
    const userDataReducer = useSelector((state: any) => state.profileDetails);
    React.useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                FacebookEvent.logCustomEvent('mbanner');
                FacebookEvent.logCustomEvent('minterstitial');
            }, 3000);
        }
    }, [props.show]);

    React.useEffect(() => {
        if (userDataReducer.isMobileAppUsed === 1) {
            props.setShowBanner();
        }
    }, [userDataReducer.isMobileAppUsed]);
    function close() {
        props.setShowBanner();
    }
    function handleBannerClick() {
        const source = props.source;
        FacebookEvent.logCustomEvent('mbanner_click');
        Utils.constant.handleRedirection('mweb1', source);
    }
    function handleInterstitialClick() {
        const source = props.source;
        FacebookEvent.logCustomEvent('minterstitial_click');
        props.closeMobileBanner();
        Utils.constant.handleRedirection('mweb2', source);
    }
    return (
        <>
            <div className="bottom-banner" id="bottom-banner">
                <div className="bottom-banner-heading text-center" onClick={handleBannerClick}>
                    Open in App
                </div>
                <span className="banner-close" onClick={() => props.setShowBanner()}>
                    X
                </span>
            </div>
            {/* {props.source && (
                <BannerParent clickHandler={props.closeMobileBanner}>
                    <MobileBannerChild
                        handleInterstitialClick={handleInterstitialClick}
                        closeMobileBanner={props.closeMobileBanner}
                    />
                </BannerParent>
            )} */}
        </>
    );
});

interface JobDetailMobileBannerProps {
    show?: boolean;
    jobName: string;
    jobImage: string;
    hideCloseBtn?: boolean;
    recruiterName: string;
    setShowBanner: () => void;
    closeMobileBanner: () => void;
    pageType?: string;
}
export const JobDetailMobileBanner = memo(function JobDetailMobileBanner(props: JobDetailMobileBannerProps) {
    const userDataReducer = useSelector((state: any) => state.profileDetails);
    React.useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                if (FacebookEvent) {
                    FacebookEvent.logCustomEvent('mbanner');
                    FacebookEvent.logCustomEvent('minterstitial_aff');
                }
            }, 3000);
        }
    }, [props.show]);

    React.useEffect(() => {
        if (userDataReducer.isMobileAppUsed === 1) {
            props.setShowBanner();
        }
    }, [userDataReducer.isMobileAppUsed]);
    function handleInterstitialClick() {
        const source = getCookie('source') || '';
        FacebookEvent.logCustomEvent('minterstitial_aff_click');
        props.closeMobileBanner();
        if (props.pageType && props.pageType === 'posting') {
            Utils.constant.handleRedirection('mweb2', 'g4j');
        } else {
            Utils.constant.handleRedirection('mweb2', source);
        }
    }
    return (
        <BannerParent clickHandler={props.closeMobileBanner} hideCloseBtn={props.hideCloseBtn}>
            <BannerJob
                jobName={props.jobName}
                jobImage={props.jobImage}
                recruiterName={props.recruiterName}
                handleInterstitialClick={handleInterstitialClick}
            />
        </BannerParent>
    );
});

export interface MobileBannerApplyProps {
    show?: boolean;
    setShowBanner: () => void;
    closeMobileBanner: () => void;
}
export const MobileBannerApply = memo(function MobileBanner(props: MobileBannerApplyProps) {
    const userDataReducer = useSelector((state: any) => state.profileDetails);
    React.useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                FacebookEvent.logCustomEvent('mbanner');
                FacebookEvent.logCustomEvent('minterstitial');
            }, 3000);
        }
    }, [props.show]);

    React.useEffect(() => {
        if (userDataReducer.isMobileAppUsed === 1) {
            props.setShowBanner();
        }
    }, [userDataReducer.isMobileAppUsed]);
    function close() {
        props.setShowBanner();
    }
    function handleBannerClick() {
        // const source = props.source;
        // FacebookEvent.logCustomEvent('mbanner_click');
        // Utils.constant.handleRedirection('mweb1', source);
    }
    function handleInterstitialClick() {
        FacebookEvent.logCustomEvent('minterstitial_click');
        props.closeMobileBanner();
        window.open(`https://link.jobget.com/mweb1`, '_blank');
    }
    return (
        <>
            <BannerParent clickHandler={() => {}}>
                <MobileBannerChild
                    handleInterstitialClick={handleInterstitialClick}
                    closeMobileBanner={props.closeMobileBanner}
                />
            </BannerParent>
        </>
    );
});

import React, { memo } from 'react';
import classes from './banner-parent.module.scss';
import Backdrop from '../backdrop';

interface Props {
    children?: React.ReactNode;
    clickHandler: any;
    hideCloseBtn?: boolean;
}
export default memo(function BannerParent(props: Props) {
    return (
        <>
            <Backdrop show={true} clickHandler={() => (!props.hideCloseBtn ? props.clickHandler() : null)} />
            <div className={classes['parent-wrapper']}>
                {!props.hideCloseBtn ? (
                    <div className={classes['close-image']} onClick={props.clickHandler}></div>
                ) : null}
                {props.children}
            </div>
        </>
    );
});

/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, ReactNode } from 'react';
import './custom-button.scss';

interface Props {
    disable?: boolean;
    styleClass?: string;
    children?: ReactNode;
    onClickHandler?: () => void;
    onClickWithEventHandler?: (event: React.MouseEvent) => void;
}

export default memo(function CustomButton(props: Props) {
    return (
        <button
            data-test="custom-btn"
            className={`custom-button ${props.styleClass}`}
            onClick={(e) =>
                props.onClickWithEventHandler
                    ? props.onClickWithEventHandler(e)
                    : props.onClickHandler
                    ? props.onClickHandler()
                    : {}
            }
            disabled={props.disable ? props.disable : false}
            aria-label="Click to select"
        >
            {props.children}
        </button>
    );
});

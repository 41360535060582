import { MessageModal } from '../../modals';
import * as types from '../actionTypes/chat';
import { ChatStateType } from '../../utils/types';
const initialState: ChatStateType = new MessageModal();

export default function MessageReducer(state: ChatStateType = initialState, action: types.types): ChatStateType {
    switch (action.type) {
        case types.SET_INBOX:
            return {
                ...state,
                inbox: action.payload.inbox,
                lastInboxKey: action.payload.lastInboxKey,
                hasMoreInbox: action.payload.hasMoreInbox,
            };
        case types.UPDATE_INBOX:
            return {
                ...state,
                inbox: action.payload.inbox,
            };
        case types.SET_MESSAGE:
            return {
                ...state,
                roomId: action.payload.roomId,
                message: action.payload.message,
                user_name: action.payload.user_name,
                user_email: action.payload.user_email,
                user_image: action.payload.user_image,
                user_mobile: action.payload.user_mobile,
                recruiterId: action.payload.recruiterId,
                lastMessageKey: action.payload.lastMessageKey,
                hasMoreMessage: action.payload.hasMoreMessage,
            };
        case types.ADD_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
            };
        case types.GET_TYPING:
            return {
                ...state,
                typingStatus: action.payload.typingStatus,
            };
        case types.SET_BADGE_COUNT:
            return {
                ...state,
                badgeCount: action.payload.badgeCount,
            };

        default:
            return state;
    }
}

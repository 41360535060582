/* eslint-disable @typescript-eslint/ban-ts-comment */
class FacebookEvent {
    private EVENT_PARAM_EMAIL = 'Email';
    private EVENT_PARAM_USER_TYPE = 'User Type';
    private EVENT_PARAM_JOB_POSTING_TITLE = 'Job Posting Title';

    private EVENT_NAME_COMPLETED_PROFILE = 'Complete Profile';
    private EVENT_NAME_APPLY_TO_JOB = 'Apply to Job';
    private EVENT_NAME_VISIT_REFERRAL_SCREEN = 'Visited Referral Screen';
    private EVENT_NAME_INVITE_FROM_REFERRAL = 'Invite From Referral';
    private EVENT_NAME_INVITE_FROM_SETTING = 'Invite From Settings';
    private FB = {};
    initialize(FB: any) {
        this.FB = FB;
    }

    logCompleteRegistrationEvent(registrationMethod: string, email: string) {
        //@ts-ignore
        const FB = this.FB.AppEvents;
        const params = {
            [FB.ParameterNames.EVENT_PARAM_REGISTRATION_METHOD]: registrationMethod,
            [this.EVENT_PARAM_EMAIL]: email,
        };
        //@ts-ignore
        FB?.logEvent(FB.EventNames.COMPLETED_REGISTRATION, null, params);
    }

    logCompleteProfileEvent(email: string) {
        //@ts-ignore
        const FB = this.FB.AppEvents;
        const params = {
            [this.EVENT_PARAM_EMAIL]: email,
        };
        //@ts-ignore
        FB.logEvent(FB.EventNames.EVENT_NAME_COMPLETED_PROFILE, null, params);
    }
    logApplyToJobEvent(email: string, jobPostingTitle: string, jobId: string) {
        //@ts-ignore
        const FB = this.FB.AppEvents;
        const params = {
            [this.EVENT_PARAM_EMAIL]: email,
            [this.EVENT_PARAM_JOB_POSTING_TITLE]: jobPostingTitle,
            [this.EVENT_PARAM_JOB_POSTING_TITLE]: jobPostingTitle,
        };
        //@ts-ignore
        FB?.logEvent(this.EVENT_NAME_APPLY_TO_JOB, null, params);
        this.logAddToWishlistEvent(jobPostingTitle, jobId);
    }
    logAddToWishlistEvent(jobPostingTitle: string, jobId: string) {
        //@ts-ignore
        const FB = this.FB.AppEvents;
        const params = {
            [FB.EventNames.EVENT_PARAM_CONTENT]: jobPostingTitle,
            [FB.EventNames.EVENT_PARAM_CONTENT_ID]: jobId,
            [FB.EventNames.EVENT_PARAM_CONTENT_TYPE]: 'Job',
            [FB.EventNames.EVENT_PARAM_CURRENCY]: 'USD',
        };
        FB?.logEvent(FB.EventNames.EVENT_NAME_ADDED_TO_WISHLIST, null, params);
    }
    logCustomEvent(bannerType: string) {
        //@ts-ignore
        const FB = this.FB.AppEvents;
        FB?.logEvent(bannerType);
    }
}

export default new FacebookEvent();

/* eslint-disable @typescript-eslint/ban-ts-comment */
import firebase from 'firebase';
import moment from 'moment';
import { initializeFirebase, sendPushNotification } from './firebase-config';
import Store from '../../redux/store/store';

//import Utils from '../utils';

class FirebaseService {
    usersRef: firebase.database.Reference;
    inboxRef: firebase.database.Reference;
    lastMessageRef: firebase.database.Reference;
    messagesRef: firebase.database.Reference;
    badgeCountRef: firebase.database.Reference;
    unreadMessageRef: firebase.database.Reference;
    roomInfoRef: firebase.database.Reference;
    lastMessage: {
        isBlock: boolean;
        isDeleted: boolean;
        latitude: number;
        longitude: number;
        mediaUrl: string;
        messageId: string;
        messageText: string;
        receiverId: string;
        roomId: string;
        senderId: string;
        status: string;
        timestamp: number;
        type: string;
        unreadCount: number;
        user: any;
    };

    constructor() {
        initializeFirebase();
        this.usersRef = firebase.database().ref('users');
        this.inboxRef = firebase.database().ref('newInbox');
        this.lastMessageRef = firebase.database().ref('lastMessage');
        this.messagesRef = firebase.database().ref('messages');
        this.badgeCountRef = firebase.database().ref('badgeCount');
        this.unreadMessageRef = firebase.database().ref('unreadMessage');
        this.roomInfoRef = firebase.database().ref('room_info');
        this.lastMessage = {
            isBlock: false,
            isDeleted: false,
            latitude: 0,
            longitude: 0,
            mediaUrl: '',
            messageId: '',
            messageText: '',
            receiverId: '',
            roomId: '',
            senderId: '',
            status: '',
            timestamp: 0,
            type: 'text',
            unreadCount: 0,
            user: {},
        };
    }
    /**
     * logged in firebase
     */
    async connectUserByAuthorizingWith(email: string, password: string, callback: () => void = () => {}) {
        try {
            const response = await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
            const errorMessage = error.message;
            //Utils.constant.apiErrorMessage(errorMessage);
        }
    }
    async createUserWithEmailAndPassword(email: string, password: string) {
        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password);
        } catch (error) {
            const errorMessage = error.message;
            //Utils.constant.apiErrorMessage(errorMessage);
        }
    }
    async createUserNode(userInfo: any) {
        await this.usersRef.child(userInfo.user_id).set({
            device_token: '',
            device_type: '3',
            email: userInfo.email,
            first_name: userInfo.first_name,
            image: userInfo.image,
            isOnline: true,
            last_name: userInfo.last_name,
            mobile: userInfo.first_name,
            user_id: userInfo.user_id,
        });
    }
    /**
     * logout from firebase
     */
    logoutWithFirebase(user_id: string) {
        this.usersRef.child(user_id).child('isOnline').set(false);
        firebase
            .auth()
            .signOut()
            .then(function (response) {})
            .catch(function (error) {});
    }
    /**
     * load initial message
     * @param userId : current logged in user
     * @param callback
     */
    async getInitialInbox(userId: string, recruiterId: string, selectedRoomId: string, messageLoad = false) {
        const inboxSnap = await this.inboxRef.child(userId).orderByChild('timestamp').limitToLast(100).once('value');
        const inboxList = inboxSnap.val();
        if (inboxList !== null) {
            console.log('inboxList', inboxList);
            const inbox = await this.getInitialLastMessage(
                inboxList,
                userId,
                recruiterId,
                selectedRoomId,
                1,
                messageLoad,
            );
            return inbox;
        } else {
            if (recruiterId) {
                this.lastMessage = Object.assign(this.lastMessage, {
                    senderId: userId,
                    receiverId: recruiterId,
                    timestamp: moment().valueOf(),
                });
                const userSnapshot = await this.usersRef.child(recruiterId).once('value');
                const user = userSnapshot.val();
                if (user !== null) {
                    this.lastMessage.user = user;
                    return { inbox: [this.lastMessage], totalInbox: 0, lastInboxTimeStamp: 0 };
                } else {
                    return { inbox: [], totalInbox: 0, lastInboxTimeStamp: 0 };
                }
            } else {
                return { inbox: [], totalInbox: 0, lastInboxTimeStamp: 0 };
            }
        }
    }
    registerInboxListener(userId: string, recruiterId: string, callback: (inbox: any, type: number) => void) {
        // this.inboxRef
        //     .child(userId)
        //     .orderByChild('timestamp')
        //     .limitToLast(1)
        //     .on('child_added', () => {});

        this.inboxRef
            .child(userId)
            .limitToLast(1)
            .on('child_added', async (snapshot: any) => {
                const val = snapshot.val();
                if (val !== null) {
                    const inbox = await this.getInitialMessageListener(val.roomId, userId, recruiterId, 1, false);
                    callback(inbox, 1);
                }
            });
        this.inboxRef.child(userId).on('child_changed', async (snapshot: any) => {
            const val = snapshot.val();
            if (val !== null) {
                const inbox = await this.getInitialMessageListener(val.roomId, userId, recruiterId, 1, false);
                callback(inbox, 2);
            }
        });
    }
    /**
     * load more inbox
     * @param userId logged in user id
     * @param lastInboxKey - last timestamp
     * @param callback
     */
    async loadMoreInbox(
        userId: string,
        recruiterId: string,
        selectedRoomId: string,
        lastInboxKey: number,
        callback: () => void,
    ) {
        const inbox: any = await this.inboxRef
            .child(userId)
            .orderByChild('timestamp')
            .limitToLast(10)
            .endAt(lastInboxKey)
            .once('value');
        if (inbox.val() === null) {
            return [];
        } else {
            const keyName = Object.keys(inbox)[0];
            delete inbox[keyName];
            if (Object.keys(inbox).length > 0) {
                this.getInitialLastMessage(inbox, userId, recruiterId, selectedRoomId, 2);
            } else {
                return [];
            }
        }
    }

    async getInitialLastMessage(
        roomIds: any,
        userId = '',
        recruiterId = '',
        selectedRoomId = '',
        type = 1,
        messageLoad = true,
        callback?: () => void,
    ) {
        let chatInboxList: Array<any> = [];
        for (const key in roomIds) {
            if (roomIds.hasOwnProperty(key)) {
                if (roomIds[key].roomId) {
                    const response = await this.lastMessageRef.child(roomIds[key].roomId).once('value');
                    let inboxList = response.val();
                    if (inboxList === null) {
                        inboxList = {};
                    } else {
                        const user = await this.usersRef.child(key).once('value');
                        if (user.val() === null) {
                            inboxList.chatLastMessage.user = { first_name: '', last_name: '', image: '' };
                        } else {
                            inboxList = {
                                chatLastMessage: { ...inboxList.chatLastMessage, ...{ user: user.val() } },
                            };
                        }
                        const unRead = await this.unreadMessageRef
                            .child(roomIds[key].roomId)
                            .child(userId)
                            .once('value');
                        const inboxItem = { ...inboxList.chatLastMessage, ...{ unreadCount: 0 } };
                        if (unRead.val() !== null) {
                            inboxItem.unreadCount = unRead.val();
                        } else {
                            inboxItem.unreadCount = 0;
                        }
                        inboxList = inboxItem;
                    }
                    if (Object.keys(inboxList).length > 0) {
                        chatInboxList.push(inboxList);
                    }
                }
            }
        }
        chatInboxList = chatInboxList.sort(function (a: any, b: any) {
            return b.timestamp - a.timestamp;
        });
        console.log({ chatInboxList });
        const lastInboxTimeStamp = chatInboxList.length > 0 ? chatInboxList[chatInboxList.length - 1].timestamp : 0;
        const recruiterIndex = chatInboxList.findIndex((item: any) => item.user.user_id === recruiterId);
        if (recruiterId && type === 1 && recruiterIndex === -1) {
            this.lastMessage = Object.assign(this.lastMessage, {
                senderId: userId,
                receiverId: recruiterId,
                timestamp: moment().valueOf(),
            });
            const recruiterInfo = await this.usersRef.child(recruiterId).once('value');
            if (recruiterInfo.val() !== null) {
                this.lastMessage.user = recruiterInfo.val();
                chatInboxList.unshift(this.lastMessage);
            }

            const messageInboxById: any = await this.getMessageById(userId, recruiterId);
            if (typeof messageInboxById == 'object') {
                if (messageInboxById.user && messageInboxById.roomId === selectedRoomId && messageInboxById.user._id) {
                    const index = chatInboxList.findIndex(
                        (item: any, index: number) => item.roomId === messageInboxById.roomId,
                    );
                    if (index !== -1) {
                        chatInboxList.splice(index, 1);
                    }
                    chatInboxList.unshift(messageInboxById);
                }
            }
        } else if (!messageLoad && recruiterId && type === 1 && recruiterIndex !== 0) {
            const recruiterMessage = chatInboxList.splice(recruiterIndex, 1);
            if (recruiterMessage.length > 0) {
                chatInboxList.unshift(recruiterMessage[0]);
            }
        }
        console.log('chatInboxList', chatInboxList);
        return { inbox: chatInboxList, total: 0, lastInboxTimeStamp: lastInboxTimeStamp };
        // if (userId) {
        //     this.inboxRef.child(userId).once('value', function (data: any) {
        //         if (data.val() === null) {
        //             callback(chatInboxList);
        //         } else {
        //             callback(chatInboxList, data.numChildren(), lastInboxTimeStamp);
        //         }
        //     });
        // }
    }

    async getInitialMessageListener(roomId: string, userId = '', recruiterId = '', type = 1, messageLoad: boolean) {
        const chatInboxList: Array<any> = [];
        if (roomId) {
            const response = await this.lastMessageRef.child(roomId).once('value');
            let inboxList = response.val();
            if (inboxList === null) {
                inboxList = {};
            } else {
                const otherUserId =
                    userId === inboxList.chatLastMessage.senderId
                        ? inboxList.chatLastMessage.receiverId
                        : inboxList.chatLastMessage.senderId;

                const user = await this.usersRef.child(otherUserId).once('value');
                if (user.val() === null) {
                    inboxList.chatLastMessage.user = { first_name: '', last_name: '', image: '' };
                } else {
                    inboxList = {
                        chatLastMessage: { ...inboxList.chatLastMessage, ...{ user: user.val() } },
                    };
                }
                const unRead = await this.unreadMessageRef.child(roomId).child(userId).once('value');
                const inboxItem = { ...inboxList.chatLastMessage, ...{ unreadCount: 0 } };
                if (unRead.val() !== null) {
                    inboxItem.unreadCount = unRead.val();
                } else {
                    inboxItem.unreadCount = 0;
                }
                inboxList = inboxItem;
            }
            // if (Object.keys(inboxList).length > 0) {
            //     chatInboxList.push(inboxList);
            // }

            // chatInboxList = chatInboxList.sort(function (a: any, b: any) {
            //     return b.timestamp - a.timestamp;
            //});
            // const lastInboxTimeStamp = chatInboxList.length > 0 ? chatInboxList[chatInboxList.length - 1].timestamp : 0;
            // const recruiterIndex = chatInboxList.findIndex((item: any) => item.user.user_id === recruiterId);
            // if (recruiterId && type === 1 && recruiterIndex === -1) {
            //     this.lastMessage = Object.assign(this.lastMessage, {
            //         senderId: userId,
            //         receiverId: recruiterId,
            //         timestamp: moment().valueOf(),
            //     });
            //     const recruiterInfo = await this.usersRef.child(recruiterId).once('value');
            //     if (recruiterInfo.val() !== null) {
            //         this.lastMessage.user = recruiterInfo.val();
            //         chatInboxList.unshift(this.lastMessage);
            //     }

            //     const messageInboxById: any = await this.getMessageById(userId, recruiterId);
            //     if (typeof messageInboxById == 'object') {
            //         if (messageInboxById.user && messageInboxById.user._id) {
            //             const index = chatInboxList.findIndex(
            //                 (item: any, index: number) => item.roomId === messageInboxById.roomId,
            //             );
            //             if (index !== -1) {
            //                 chatInboxList.splice(index, 1);
            //             }
            //             chatInboxList.unshift(messageInboxById);
            //         }
            //     }
            // } else if (!messageLoad && recruiterId && type === 1 && recruiterIndex !== 0) {
            //     const recruiterMessage = chatInboxList.splice(recruiterIndex, 1);
            //     if (recruiterMessage.length > 0) {
            //         chatInboxList.unshift(recruiterMessage[0]);
            //     }
            // }
            // console.log('chatInboxList', chatInboxList);
            return inboxList;
        }
    }
    async getMessageById(userId: string, selectedId: string) {
        let messageInboxById;
        const roomIds: any = await this.inboxRef.child(userId).child(selectedId).once('value');
        if (roomIds.val() === null) {
            const user = await this.usersRef.child(selectedId).once('value');
            if (user.val() !== null) {
                this.lastMessage = Object.assign(this.lastMessage, {
                    senderId: userId,
                    receiverId: selectedId,
                    timestamp: moment().valueOf(),
                });
                this.lastMessage.user = user.val();
                messageInboxById = this.lastMessage;
            } else {
                this.lastMessage = Object.assign(this.lastMessage, {
                    senderId: userId,
                    receiverId: selectedId,
                    timestamp: moment().valueOf(),
                });
                this.lastMessage.user = {};
                messageInboxById = this.lastMessage;
            }
        } else {
            let inboxList: any = await this.lastMessageRef.child(roomIds.roomId).once('value');
            if (inboxList.val() !== null) {
                const user = await this.usersRef.child(selectedId).once('value');
                inboxList.chatLastMessage.user = user.val();
            } else {
                inboxList = {};
            }
            messageInboxById = inboxList;
        }
        return messageInboxById;
    }
    async getMessage(userId: string, roomId: string) {
        const message = await this.messagesRef
            .child(roomId)
            .orderByChild('isDeleted')
            .equalTo(false)
            //.limitToLast(25)
            .once('value');
        if (message.val() === null) {
            return { message: [], total: 0 };
        } else {
            let messageList = message.val();
            messageList = this.sortMessage(messageList);
            //const { roomId: prevRoomId } = Store.getState().messageReducer;
            const prevRoomId = '';
            const messageIds = messageList
                .filter((item: any) => item.receiverId === userId && item.status !== 'seen')
                .map((item: any) => item.messageId);
            if (messageIds && messageIds.length) {
                this.seenMessage(roomId, userId, messageIds);
            }
            if (roomId) {
                //const total = await this.getTotalMessage(roomId);
                //console.log({ message: messageList, total: total });
                return { message: messageList, total: 0 };
            }
        }
    }
    getMessageListener(userId: string, roomId: string, callback: (message: any) => void) {
        this.messagesRef
            .child(roomId)
            .orderByChild('isDeleted')
            .equalTo(false)
            .limitToLast(1)
            .on('child_added', (data) => {
                const message = data.val();
                console.log('message', message);
                if (message === null) {
                    callback('');
                } else {
                    callback(message);
                }
            });
    }
    removeMessageListener(roomId: string, listener: any) {
        this.messagesRef.child(roomId).off(listener);
    }
    async getMessageUpdate(userId: string, roomId: string, callback: (messages: Array<any>, total: number) => void) {
        const onLoad = async (inbox: any) => {
            if (inbox.val() === null) {
                callback([], 0);
                return;
            } else {
                let messageList = inbox.val();
                messageList = this.sortMessage(messageList);
                //const { roomId: prevRoomId } = Store.getState().messageReducer;
                const prevRoomId = '';
                if (prevRoomId === roomId) {
                    const total = await this.getTotalMessage(roomId);
                    callback(messageList, total);
                }
                const messageIds = messageList
                    .filter((item: any) => item.receiverId === userId && item.status !== 'seen')
                    .map((item: any) => item.messageId);
                if (messageIds && messageIds.length) {
                    this.seenMessage(roomId, userId, messageIds);
                }
            }
        };
        this.messagesRef.child(roomId).off('value', onLoad);
        this.messagesRef.child(roomId).orderByChild('isDeleted').equalTo(false).limitToLast(25).on('value', onLoad);
    }
    async loadMoreMessage(roomId: string, messageLimit: number, lastMessageKey: number, userId: string) {
        if (!roomId || !lastMessageKey) {
            return [];
        }

        let messageList: any = await this.messagesRef
            .child(roomId)
            .orderByChild('timestamp')
            .endAt(lastMessageKey)
            .limitToLast(messageLimit)
            .once('value');
        if (messageList.val() === null) {
            return [];
        } else {
            messageList = this.sortMessage(messageList);
            messageList.pop();
            const messageIds = messageList
                .filter((item: any) => item.receiverId === userId && item.status !== 'seen')
                .map((item: any) => item.messageId);

            if (messageIds && messageIds.length) {
                this.seenMessage(roomId, userId, messageIds);
            }
            return messageList;
        }
    }
    async getTotalMessage(roomId: string) {
        return 0;
        // let totalMessage: any = 0;
        // if (roomId) {
        //     totalMessage = await new Promise((resolve) =>
        //         this.messagesRef.child(roomId).once('value', function (data: any) {
        //             if (data.val() === null) {
        //                 resolve(0);
        //             } else {
        //                 resolve(data.numChildren());
        //             }
        //         }),
        //     );

        //     callback(messageList, totalMessage);
        //     const messageIds = messageList
        //         .filter((item) => item.receiverId === userId && item.status !== 'seen')
        //         .map((item) => item.messageId);
        //     if (messageIds && messageIds.length) {
        //         this.seenMessage(roomId, userId, messageIds);
        //     }
        // }
    }
    seenMessage(roomId: string, userId: string, messageIds: Array<any>) {
        if (roomId && userId) {
            messageIds.forEach((element) => {
                if (element) {
                    this.messagesRef.child(roomId).child(element).child('status').set('seen');
                }
            });
        }
    }
    sortMessage(obj: any) {
        const arr = [];
        let prop;
        for (prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                arr.push(obj[prop]);
            }
        }
        arr.sort(function (a, b) {
            return a.timestamp - b.timestamp;
        });
        return arr; // returns array
    }

    /***
     * Send message
     */
    sendMessage(
        roomId: string,
        messageText: string,
        userId: string,
        receiverId: string,
        first_name: string,
        last_name: string,
        deviceToken: string,
        deviceType: number,
        email: string,
        user_image: string,
        mobile: string,
    ) {
        if (!roomId || !messageText) {
            return;
        }
        //@ts-ignore
        const key = this.messagesRef.child(roomId).push().getKey();
        this.messagesRef.child(roomId).child(key).set({
            isBlock: false,
            isDeleted: false,
            latitude: 0,
            longitude: 0,
            mediaUrl: '',
            messageId: key,
            messageText: messageText,
            receiverId: receiverId,
            roomId: roomId,
            senderId: userId,
            status: 'sent',
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            type: 'text',
        });
        this.lastMessageRef.child(roomId).set({
            chatLastMessage: {
                isBlock: false,
                isDeleted: false,
                latitude: 0,
                longitude: 0,
                mediaUrl: '',
                messageId: key,
                messageText: messageText,
                receiverId: receiverId,
                roomId: roomId,
                senderId: userId,
                status: 'sent',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                type: 'text',
            },
        });
        this.lastMessageRef.child(roomId).set({
            chatLastMessage: {
                isBlock: false,
                isDeleted: false,
                latitude: 0,
                longitude: 0,
                mediaUrl: '',
                messageId: key,
                messageText: messageText,
                receiverId: receiverId,
                roomId: roomId,
                senderId: userId,
                status: 'sent',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                type: 'text',
            },
        });
        this.setUnReadCount(roomId, userId, receiverId);
        this.setBadgeCount(receiverId);

        const token = deviceToken;
        const message = `${first_name}  ${last_name ? last_name.charAt(0).toUpperCase() : ''} ${messageText}`;
        this.sendNotification(
            key,
            roomId,
            token,
            message,
            deviceType,
            first_name,
            last_name,
            email,
            user_image,
            mobile,
            userId,
        );
        //sendPushNotification('sendPushNotification',payload);
    }

    getBadgeCount(userId: string, callback: (badgeCount: number) => void) {
        if (userId) {
            const onLoad = async (data: any) => {
                const badgeCount = data.val();
                if (badgeCount === null) {
                    callback(0);
                } else {
                    callback(badgeCount);
                }
            };
            this.badgeCountRef.child(userId).on('value', onLoad);
        }
    }
    removeGetBadgeCount(userId: string, listener?: any) {
        this.badgeCountRef.child(userId).off(listener);
    }
    updateUnreadBadgeCount(userId: string, roomId: string, badgeCount: number, unreadCount = 0) {
        if (userId && roomId && !unreadCount) {
            this.unreadMessageRef
                .child(roomId)
                .child(userId)
                .once('value', (unRead: any) => {
                    if (unRead.val() !== null) {
                        const unreadCount = unRead.val();
                        const newBadgeCount = badgeCount - unreadCount;
                        this.unreadMessageRef.child(roomId).child(userId).set(0);
                        this.badgeCountRef.child(userId).set(newBadgeCount > -1 ? newBadgeCount : 0);
                    }
                });
        }
    }
    deleteMessage(roomId: string, messageId: string, lastMessage: any) {
        if (roomId && messageId) {
            this.messagesRef.child(roomId).child(messageId).child('isDeleted').set(true);
            this.lastMessageRef.child(roomId).child('chatLastMessage').set(lastMessage);
        }
    }
    deleteAllMessage(userId: string, receiverId: string) {
        if (userId && receiverId) {
            this.inboxRef.child(userId).child(receiverId).remove();
        }
    }

    setTypingStatus(roomId: string, userId: string, status: boolean) {
        this.roomInfoRef.child(roomId).child('chatRoomIsTyping').child(userId).set(status);
    }
    getTypingStatus(roomId: string, receiverId: string, callback: (typingStatus: boolean) => void) {
        this.roomInfoRef
            .child(roomId)
            .child('chatRoomIsTyping')
            .child(receiverId)
            .on('value', (data: any) => {
                const typingStatus = data.val();
                console.log({ typingStatus });
                if (typingStatus === null) {
                    callback(false);
                } else {
                    callback(typingStatus);
                }
            });
    }
    removeGetTypingStatus(roomId: string, receiverId: string, listener?: any) {
        this.messagesRef.child(roomId).child('chatRoomIsTyping').child(receiverId).off(listener);
    }
    async sendInitialMessage(
        messageText: string,
        userId: string,
        receiverId: string,
        first_name: string,
        last_name: string,
    ) {
        if (messageText && userId && receiverId) {
            const roomId = this.createRoom(userId, receiverId);
            this.checkInbox(userId, receiverId, roomId);
            this.checkInbox(receiverId, userId, roomId);
            this.sendFirstMessage(roomId, userId, receiverId, messageText, first_name, last_name);
            this.setUnReadCount(roomId, userId, receiverId);
            this.setBadgeCount(receiverId);
            return roomId;
        }
    }

    createRoom(userId: string, receiverId: string) {
        //@ts-ignore
        const roomKey = this.roomInfoRef.push().getKey();
        this.roomInfoRef.child(roomKey).set({
            chatLastUpdate: firebase.database.ServerValue.TIMESTAMP,
            chatLastUpdates: {
                [userId]: firebase.database.ServerValue.TIMESTAMP,
                [receiverId]: firebase.database.ServerValue.TIMESTAMP,
            },
            chatRoomId: roomKey,
            chatRoomIsTyping: {
                [userId]: false,
                [receiverId]: false,
            },
            chatRoomMembers: {
                [userId]: {
                    memberDelete: 0,
                    memberJoin: 0,
                    memberLeave: 0,
                },
                [receiverId]: {
                    memberDelete: 0,
                    memberJoin: 0,
                    memberLeave: 0,
                },
            },
            chatRoomPic: '',
            chatRoomTitle: '',
            chatRoomType: 'single',
        });
        return roomKey;
    }
    checkInbox(userId: string, receiverId: string, roomId: string) {
        this.inboxRef.child(userId).once('value', (data: any) => {
            if (data.val() === null) {
                this.createNewInbox(userId, receiverId, roomId);
            } else {
                this.updateInbox(userId, receiverId, roomId);
            }
        });
    }
    createNewInbox(userId: string, receiverId: string, roomId: string) {
        this.inboxRef.child(userId).set({
            [receiverId]: {
                roomId: roomId,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
            },
        });
    }
    updateInbox(userId: string, receiverId: string, roomId: string) {
        this.inboxRef.child(userId).child(receiverId).set({
            roomId: roomId,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        });
    }
    setUnReadCount(roomId: string, userId: string, receiverId: string) {
        if (roomId && userId && receiverId) {
            this.unreadMessageRef
                .child(roomId)
                .child(receiverId)
                .once('value', (unRead: any) => {
                    if (unRead.val() !== null) {
                        const unreadCount = unRead.val();
                        if (unreadCount !== null) {
                            this.unreadMessageRef.child(roomId).set({
                                [userId]: 0,
                                [receiverId]: unreadCount + 1,
                            });
                        }
                    } else {
                        this.unreadMessageRef.child(roomId).set({
                            [userId]: 0,
                            [receiverId]: 1,
                        });
                    }
                });
        }
    }
    setBadgeCount(receiverId: string) {
        if (receiverId) {
            const onLoad = async (data: any) => {
                const badgeCount = data.val();
                if (badgeCount === null) {
                    this.badgeCountRef.child(receiverId).set(1);
                } else {
                    this.badgeCountRef.child(receiverId).set(badgeCount + 1);
                }
            };
            this.badgeCountRef.child(receiverId).once('value', onLoad);
        }
    }
    sendFirstMessage(
        roomId: string,
        userId: string,
        receiverId: string,
        messageText: string,
        first_name: string,
        last_name: string,
    ) {
        if (!roomId || !messageText || !userId || !receiverId) {
            console.log('its returned');
            return;
        }
        //@ts-ignore
        const key = this.messagesRef.child(roomId).push().getKey();
        this.messagesRef.child(roomId).child(key).set({
            isBlock: false,
            isDeleted: false,
            latitude: 0,
            longitude: 0,
            mediaUrl: '',
            messageId: key,
            messageText: messageText,
            receiverId: receiverId,
            roomId: roomId,
            senderId: userId,
            status: '',
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            type: 'text',
        });
        this.lastMessageRef.child(roomId).set({
            chatLastMessage: {
                isBlock: false,
                isDeleted: false,
                latitude: 0,
                longitude: 0,
                mediaUrl: '',
                messageId: key,
                messageText: messageText,
                receiverId: receiverId,
                roomId: roomId,
                senderId: userId,
                status: '',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                type: 'text',
            },
        });
        this.lastMessageRef.child(roomId).set({
            chatLastMessage: {
                isBlock: false,
                isDeleted: false,
                latitude: 0,
                longitude: 0,
                mediaUrl: '',
                messageId: key,
                messageText: messageText,
                receiverId: receiverId,
                roomId: roomId,
                senderId: userId,
                status: '',
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                type: 'text',
            },
        });
    }
    updateUserProfile(payload: any) {
        this.usersRef.child(payload._id).update({
            email: payload.email,
            image: payload.userImage,
            first_name: payload.firstName,
            last_name: payload.lastName,
            mobile: payload.mobile,
        });
    }
    sendNotification(
        messageId: string,
        roomId: string,
        device_token: string,
        message: string,
        deviceType: number,
        first_name: string,
        last_name: string,
        email: string,
        user_image: string,
        mobile: string,
        userId: string,
    ) {
        if (deviceType === 2) {
            const payload = {
                badge: 1,
                device_type: 2,
                priority: 'High',
                mutableContent: true,
                to: device_token,
                notification: {
                    badge: 1,
                    body: message,
                    messageId: messageId,
                    messageText: message,
                    roomId: roomId,
                    roomName: '',
                    roomType: 'single',
                    sender: {
                        device_token: device_token,
                        device_type: 2,
                        email: email,
                        first_name: first_name,
                        image: user_image,
                        isOnline: true,
                        last_name: last_name,
                        mobile: mobile,
                        user_id: userId,
                    },
                    sound: 'default',
                    type: 'chat',
                },
            };
            sendPushNotification(payload);
        } else {
            const payload = {
                badge: 1,
                device_type: 1,
                priority: 'High',
                mutableContent: true,
                to: device_token,
                data: {
                    body: {
                        badge: 1,
                        body: message,
                        messageId: messageId,
                        messageText: message,
                        roomId: roomId,
                        roomName: '',
                        roomType: 'single',
                        sender: {
                            device_token: device_token,
                            device_type: 1,
                            email: email,
                            first_name: first_name,
                            image: user_image,
                            isOnline: true,
                            last_name: last_name,
                            mobile: mobile,
                            user_id: userId,
                        },
                        sound: 'default',
                        type: 'chat',
                    },
                },
            };

            sendPushNotification(payload);
        }
    }
}
export default new FirebaseService();

import * as types from '../actionTypes/chat';
import {
    FetchInboxType,
    ChatStateType,
    FetchMessageType,
    InboxStateType,
    MessageStateType,
    SendMessageStateType,
} from '../../utils/types';

export function fetchInboxList(payload: FetchInboxType): types.FetchInboxList {
    return {
        type: types.FETCH_INBOX,
        payload,
    };
}

export function setInboxList(payload: InboxStateType): types.SetInboxList {
    return {
        type: types.SET_INBOX,
        payload,
    };
}
export function updateInboxList(payload: { inbox: Array<any> }): types.UpdateInbox {
    return {
        type: types.UPDATE_INBOX,
        payload,
    };
}

export function fetchMessageList(payload: FetchMessageType): types.FetchMessage {
    return {
        type: types.FETCH_MESSAGE,
        payload,
    };
}

export function fetchInitialMessageList(payload: FetchMessageType): types.FetchInitialMessage {
    return {
        type: types.FETCH_INITIAL_MESSAGE,
        payload,
    };
}

export function setMessageList(payload: MessageStateType): types.SetMessage {
    return {
        type: types.SET_MESSAGE,
        payload,
    };
}
export function addMessageList(payload: { message: Array<any> }): types.AddMessage {
    return {
        type: types.ADD_MESSAGE,
        payload,
    };
}

export function setTypingStatus(payload: { typingStatus: boolean }): types.SetTyping {
    return {
        type: types.SET_TYPING,
        payload,
    };
}

export function getTypingStatus(payload: { typingStatus: boolean }): types.GetTyping {
    return {
        type: types.GET_TYPING,
        payload,
    };
}

export function sendMessage(payload: SendMessageStateType): types.SendMessage {
    return {
        type: types.SEND_MESSAGE,
        payload,
    };
}

export function setBadgeCount(payload: { badgeCount: number }): types.SetBadgeCount {
    return {
        type: types.SET_BADGE_COUNT,
        payload,
    };
}

export function getBadgeCount(): types.GetBadgeCount {
    return {
        type: types.GET_BADGE_COUNT,
    };
}

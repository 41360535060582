import { FormFieldType } from './types';
import { CustomDropdownList } from '../modals';

const nameRegex = /^[^ +]([^0-9~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]{1,})$/;
const lastNameRegex = /^[^ +]([^0-9~!@#$%^&*()_|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]{0,})$/;
const phonenumberRegx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^((?=.*[0-9])|(?=.*[!@#\$%\^&\*]))(?=.{8,})/;
const otpRegex = /^[0-9]{4}$/;
function validateEmail(email: string) {
    let error = '';
    if (!email) {
        error = 'Please enter your email id';
    } else if (!emailRegex.test(email)) {
        error = 'Please enter valid email';
    } else {
        error = '';
    }
    return error;
}

function validatePassword(password: string, msg = 'Please enter your password'): string {
    let error = '';
    if (!password) {
        error = msg;
    } else if (password.length < 6) {
        error = 'Password must contain at least 6 characters';
    } else if (password.length > 30) {
        error = 'Password should contain maximum 30 characters';
    } else {
        error = '';
    }
    return error;
}

function validateChangePassword(newPassword: string, confirmPassword: string): string {
    let error = '';

    if (!confirmPassword) {
        error = 'Please enter confirm password';
    } else if (confirmPassword !== newPassword) {
        error = 'Confirm password is not matching';
    } else {
        error = '';
    }
    return error;
}

function validateCompanyName(companyname: string) {
    let error = '';
    if (!companyname) {
        error = 'Please enter Company  name';
    } else if (companyname.length < 2) {
        error = 'company name must be more than 2 characters';
    } else {
        error = '';
    }
    return error;
}

function validatePosition(position: string): string {
    let error = '';
    if (!position) {
        error = 'Please enter a Position';
    } else if (position.length < 2) {
        error = 'Position must be more than 2 characters';
    } else {
        error = '';
    }
    return error;
}

function validateCategory(category: CustomDropdownList | undefined): string {
    let error = '';
    if (category === undefined) {
        error = 'Please select a Category';
    } else {
        error = '';
    }
    return error;
}

function validateDuration(duration: number) {
    let error = '';
    if (duration < 0) {
        error = 'Please select a valid Duration';
    } else {
        error = '';
    }
    return error;
}

function validateName(key: string, name: string) {
    let error = '';
    if (!name.trim()) {
        error = `Please enter your ${key}`;
    } else if (name.length < 2) {
        error = `${key} must be more than 2 characters`;
    } else if (name.length > 55) {
        error = `${key} must be less than 55 characters`;
    } else {
        error = '';
    }
    return error;
}

function validateDesc(key: string, name: string): string {
    let error = '';
    if (!name.trim()) {
        error = `Please enter your ${key}`;
    } else if (name.length < 2) {
        error = `${key} must be more than 10 characters`;
    } else if (name.length > 10000) {
        error = `${key} must be less than 10000 characters`;
    } else {
        error = '';
    }
    return error;
}

function validateFirstName(firstname: string): string {
    let error = '';
    if (!firstname) {
        error = 'Please enter your first name';
    } else if (firstname.length < 2) {
        error = 'First Name must be more than 2 characters';
    } else if (!lastNameRegex.test(firstname)) {
        error = 'Please enter valid First Name';
    } else {
        error = '';
    }
    return error;
}

function validateLastName(lastname: string): string {
    let error = '';
    if (!lastname) {
        error = 'Please enter your last name';
    } else if (lastname.length < 2) {
        error = 'Last Name must be more than 2 characters';
    } else if (!lastNameRegex.test(lastname)) {
        error = 'Please enter valid last Name';
    } else {
        error = '';
    }
    return error;
}

function validatePhoneNumber(phonenumber: string): string {
    phonenumber = phonenumber.replace(/ |-/g, '');
    let error = '';

    if (!phonenumber) {
        error = 'Please specify your Mobile Number';
    } else if (phonenumber.length < 10) {
        error = 'Mobile Number must be at least 10 Digit';
    } else if (!phonenumberRegx.test(phonenumber)) {
        error = 'Please specify valid Mobile Number';
    } else {
        error = '';
    }
    return error;
}

function validateForm(state: FormFieldType): any {}

function validateOtp(otp: string): string {
    let error = '';

    if (!otpRegex.test(otp)) {
        error = 'Please enter a valid OTP.';
    }

    return error;
}

function validateLocation(location: string): string {
    if (location.length > 0) {
        return '';
    } else {
        return 'Please enter a location';
    }
}
export default {
    validateOtp,
    validateName,
    validateDesc,
    validateForm,
    validateEmail,
    validatePosition,
    validateCategory,
    validateDuration,
    validateLastName,
    validatePassword,
    validateFirstName,
    validateLocation,
    validatePhoneNumber,
    validateCompanyName,
    validateChangePassword,
};

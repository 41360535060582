import constant from '../constant';
const evenRegex = /^\d*[02468]$/;
/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = function (token: string): void {
    if (token) {
        constant.axiosService.defaults.headers.common['authtoken'] = `${token}`;
    } else {
        delete constant.axiosService.defaults.headers.common['authtoken'];
    }
};
/**
 * set user session in browser
 */
export const setSession = function (payload: { accessToken: string; expiresIn: number; user_id: string }): void {
    const expiresIn = JSON.stringify(payload.expiresIn * 5000 + new Date().getTime());
    setAuthorizationToken(payload.accessToken);
    localStorage.setItem('expiresIn', expiresIn);
    localStorage.setItem('candidate_user_id', payload.user_id);
    localStorage.setItem('accessToken', payload.accessToken);
};
/**
 * remove session on user logout
 */
export const removeSession = function (): void {
    localStorage.removeItem('candidate_user_id');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('is_profile_added');
    setAuthorizationToken('');
};
/**
 * get user access token form loclastorage
 */
export const getAccessToken = function (): string {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        setAuthorizationToken(accessToken);
    }
    return accessToken || '';
};
/**
 * get user id form localStorage
 */
export const getUserId = function (): string {
    const userId = localStorage.getItem('candidate_user_id') || null;
    return userId || '';
};
export const setBannerSession = function (isClose: boolean): void {
    sessionStorage.setItem('isBannerClose', isClose.toString());
};
export const getBannerSession = function (): boolean {
    const isBannerClose = sessionStorage.getItem('isBannerClose');
    return isBannerClose !== null ? (isBannerClose == 'true' ? true : false) : false;
};

export const getCookie = function (name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export function setCookie(name: string, value: string, days: any): void {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function variantType(): number {
    const variantType = getCookie('variantType');
    if (variantType === null) {
        const date = new Date();
        const minute = date.getMinutes();
        if (evenRegex.test(minute.toString()) && variantType !== '1') {
            setCookie('variantType', '2', 20);
            return 2;
        } else {
            setCookie('variantType', '1', 20);
            return 1;
        }
    } else {
        return variantType ? parseInt(variantType) : 1;
    }
}

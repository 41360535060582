import React, { useState, useEffect } from 'react';
import ProgressiveImage from 'react-progressive-image';
import CustomButton from '../custom-elements/custom-button';
import classes from './index.module.scss';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers/rootReducer';
import { JobModalType, OnBoardingType, Error, IndependentJobApplicationPayload } from '../../utils/types';
import Utils from '../../utils';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { setError, SetJobAppliedWhenLoggedOut, setAppliedRecruiterDetailId } from '../../redux/actions/miscellaneous';
import { setShowOnBoardingModal } from '../../redux/actions/onBoarding';
import Loader from '../loader';
import history from '../../utils/history';
import { RouteComponentProps } from 'react-router-dom';
import { getCall } from '../../utils/services/network';
import OfflineError from '../../utils/offlineError';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { applyJobFromIndependent } from '../../redux/actions/job';

interface MatchProps {}

interface Props extends RouteComponentProps<MatchProps> {
    userId: string;
    applyJobLoading: boolean;
    is_profile_added?: number;
    setError?: (param: Error) => void;
    appliedJobFromRecruiterIdPayload?: string;
    setAppliedRecruiterDetailId?: (param: string) => void;
    setShowOnBoardingModal?: (param: OnBoardingType) => void;
    applyJobFromIndependent?: (param: Pick<JobModalType, 'recruiterId' | '_id' | 'categoryId'>) => void;
    SetJobAppliedWhenLoggedOut?: (param: Pick<JobModalType, '_id' | 'categoryId' | 'recruiterId'>) => void;
}

const RecruiterDetail = function RecruiterDetail(props: Props) {
    const [fetchingLoader, setFetchingLoader] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [user_image, setUserImage] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [next, setNext] = useState(false);
    const [page, setPage] = useState(0);
    const [jobs, setJobs] = useState<Array<JobModalType>>([]);
    const [_id, setId] = useState('');

    useEffect(() => {
        setFetchingLoader(true);
        props.setAppliedRecruiterDetailId && props.setAppliedRecruiterDetailId('');
        return () => {
            props.setAppliedRecruiterDetailId && props.setAppliedRecruiterDetailId('');
        };
    }, []);

    useEffect(() => {
        if (props.appliedJobFromRecruiterIdPayload) {
            const jobsCollection = [...jobs];
            for (let i = 0; i < jobsCollection.length; i++) {
                if (jobsCollection[i]._id === props.appliedJobFromRecruiterIdPayload) {
                    jobsCollection[i].isApplied = 1;
                    setJobs(jobsCollection);
                    break;
                }
            }
        }
    }, [props.appliedJobFromRecruiterIdPayload]);

    useEffect(() => {
        if (fetchingLoader) {
            (async function () {
                await fetchData();
            })();
        }
    }, [fetchingLoader]);

    const fetchData = async () => {
        try {
            console.log(props);
            //@ts-ignore
            const { recruiterId } = props.match.params;
            console.log(recruiterId);
            const endPoint = Utils.endpoints.recruiterView(recruiterId, 0);
            const response = await getCall(endPoint, '');
            console.log(response);
            if (response.data.code === 200) {
                const { detail, jobs } = response.data.data;
                const { next, page } = response.data;
                setFirstName(detail.first_name);
                setLastName(detail.last_name);
                setUserImage(detail.user_image);
                setCompanyName(detail.company.companyName);
                setId(detail._id);
                setNext(next);
                setPage(page);
                setJobs(jobs);
            } else {
                history.push('/');
                const message = 'Something went wrong while fetching the data!';
                const payload: Error = {
                    msg: message,
                };
                props.setError && props.setError(payload);
            }
            setFetchingLoader(false);
        } catch (error) {
            console.log(error);
            history.push('/');
            let message = 'Something went wrong!';
            if (error instanceof OfflineError) {
                message = error.message;
                const payload: Error = {
                    msg: message,
                };
                props.setError && props.setError(payload);
            } else {
                if (error.response) {
                    message = error.response.data.message;
                }
                const payload: Error = {
                    msg: message,
                };
                props.setError && props.setError(payload);
            }
        }
    };

    const applyJob = (_id: string, categoryId: string, recruiterId: string) => {
        const isProfileAdded = parseInt(localStorage.getItem('is_profile_added') || '0');
        if (isProfileAdded) {
            const payload: IndependentJobApplicationPayload = {
                _id: _id,
                categoryId: categoryId,
                recruiterId: recruiterId,
                isRecruiter: true,
            };
            props.applyJobFromIndependent && props.applyJobFromIndependent(payload);
        } else {
            const payload: Pick<JobModalType, '_id' | 'categoryId' | 'recruiterId'> = {
                _id: _id,
                categoryId: categoryId,
                recruiterId: recruiterId,
            };
            props.SetJobAppliedWhenLoggedOut && props.SetJobAppliedWhenLoggedOut(payload);
            history.push('/profile/profile-complete');
        }
    };

    // const messageRecruiter = () => {
    //     props.setJobAppliedModal && props.setJobAppliedModal(false);
    // };

    // const cancelMessageRecruiter = () => {
    //     props.setJobAppliedModal && props.setJobAppliedModal(false);
    // };

    const getRecruiterNameInitialsLetters = (first_name: string, last_name: string): string => {
        return `${first_name.slice(0, 1)}${last_name.slice(0, 1)}`.toUpperCase();
    };

    return (
        <>
            <Loader show={fetchingLoader || props.applyJobLoading} backdropStyle={classes['custom-loader-backdrop']} />
            {!fetchingLoader && (
                <div className={classes['recruiter-detail-parent-wrapper']}>
                    <section>
                        <div className={classes['details']}>
                            <figure>
                                <ProgressiveImage
                                    src={user_image}
                                    placeholder={'https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg'}
                                >
                                    {(src: string) => <img src={src} alt="job" />}
                                </ProgressiveImage>
                                {user_image ? (
                                    ''
                                ) : (
                                    <label className={classes['recruiter-name-initials']}>
                                        {getRecruiterNameInitialsLetters(first_name, last_name)}
                                    </label>
                                )}
                            </figure>

                            <div>
                                <div className={classes['name']}>
                                    {first_name + '' + last_name.slice(0, 1).toUpperCase() + '.'}
                                </div>
                                <div className={classes['company-name']}>{companyName}</div>
                            </div>
                        </div>
                        <div className={classes['btn-wrapper']}>
                            <CustomButton
                                styleClass="btn-primary"
                                onClickHandler={() => {
                                    if (props.is_profile_added) {
                                        history.push('/messages?recruiterId=' + _id);
                                    } else {
                                        history.push('/profile/profile-complete');
                                    }
                                }}
                                disable={false}
                            >
                                Message
                            </CustomButton>
                        </div>
                    </section>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => {}}
                        hasMore={false}
                        loader={
                            <div className="row justify-content-center" key={0}>
                                {' '}
                                {'Loading ...'}{' '}
                            </div>
                        }
                        className={`${classes['jobs']} custom-child-scrollbar-global`}
                    >
                        {jobs.length > 0 ? (
                            jobs.map((items, index) => (
                                <section key={index}>
                                    <div className={classes['first-row']}>
                                        <div className={classes['job-title-company-exp-wrapper']}>
                                            <div className={classes['job-title']}>{items.jobTitle}</div>
                                            <div className={classes['company-name-exp']}>
                                                <span>{items.companyName}</span>
                                            </div>
                                            <div className={classes['exp']}>
                                                {items.isExp
                                                    ? Utils.constant.experienceParser(parseInt(items.totalExperience))
                                                          .parsedExperience +
                                                      ' ' +
                                                      Utils.constant.experienceParser(parseInt(items.totalExperience))
                                                          .experienceLabel
                                                    : 'No exp req'}
                                            </div>
                                        </div>

                                        <div className={classes['location-salary-wrapper']}>
                                            <div className={classes['salary']}>
                                                {items.salaryFrom
                                                    ? Utils.constant.salaryParser(
                                                          items.salaryFrom,
                                                          items.salaryTo,
                                                          items.duration,
                                                      )
                                                    : ''}
                                            </div>
                                            <div className={classes['location']}>
                                                <img
                                                    src={'https://jobget-static.s3.amazonaws.com/new/locationgrey.svg'}
                                                    alt="location-icon"
                                                />
                                                {`${Utils.constant.distance(
                                                    items.latitude,
                                                    items.longitude,
                                                    Utils.constant.defaultLat,
                                                    Utils.constant.defaultLng,
                                                )} away`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes['second-row']}>
                                        <div className={classes['category']}>{items.categoryName}</div>
                                        <div
                                            className={`${classes['adjustment']} ${
                                                items.isApplied || items.isShortlisted
                                                    ? classes['btn-disabled-applied']
                                                    : ''
                                            }`}
                                        >
                                            <CustomButton
                                                styleClass={'btn-primary'}
                                                onClickHandler={() =>
                                                    applyJob(items._id, items.categoryId, items.recruiterId._id)
                                                }
                                                disable={items.isApplied || items.isShortlisted ? true : false}
                                            >
                                                {items.isApplied || items.isShortlisted
                                                    ? items.isApplied
                                                        ? 'Applied'
                                                        : 'Shortlisted'
                                                    : 'Apply'}
                                            </CustomButton>
                                        </div>
                                    </div>

                                    <div className={classes['third-row']}>
                                        <div className={classes['time']}>
                                            {items.created_at && `posted ${moment(items.created_at).fromNow()}`}
                                        </div>
                                    </div>
                                </section>
                            ))
                        ) : (
                            <></>
                        )}
                    </InfiniteScroll>
                </div>
            )}
        </>
    );
};

function mapStateToProps(state: AppState) {
    const { applyJobLoading } = state.globalLoaders;
    const { is_profile_added } = state.profileDetails;
    const { appliedJobFromRecruiterIdPayload } = state.miscellaneous;
    return {
        applyJobLoading,
        is_profile_added,
        appliedJobFromRecruiterIdPayload,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators(
        {
            setError,
            setShowOnBoardingModal,
            applyJobFromIndependent,
            SetJobAppliedWhenLoggedOut,
            setAppliedRecruiterDetailId,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterDetail);

import * as types from '../actionTypes/job';
import {
    FetchJobListType,
    JobListType,
    JobModalType,
    AppliedJobModalType,
    ShortListedJobModalType,
    ReportJobPayloadType,
    SimilarJobListType,
    OtherJobListType,
    IndependentJobApplicationPayload,
} from '../../utils/types';

export function fetchJobList(payload: FetchJobListType): types.FetchJobList {
    return {
        type: types.FETCH_JOB_LIST,
        payload,
    };
}

export function loadMoreJobList(payload: FetchJobListType): types.LoadMoreJobList {
    return {
        type: types.LOAD_MORE_JOB_LIST,
        payload,
    };
}

export function setJobList(payload: Partial<JobListType>): types.SetJobList {
    return {
        type: types.SET_JOB_LIST,
        payload,
    };
}

export function setJobInfoSuccess(payload: JobModalType): types.SetJobInfoSuccess {
    return {
        type: types.SET_JOB_INFO_SUCCESS,
        payload,
    };
}

export function setJobInfo(payload: number): types.SetJobInfo {
    return {
        type: types.SET_JOB_INFO,
        payload,
    };
}

export function unsetJobInfo(): types.UnsetJobInfo {
    return {
        type: types.UNSET_JOB_INFO,
    };
}

export function applyJob(payload: number): types.ApplyJob {
    return {
        type: types.APPLY_JOB,
        payload,
    };
}

export function applyJobSuccess(payload: Pick<JobListType, 'jobList' | 'jobInfo'>): types.ApplyJobSuccess {
    return {
        type: types.APPLY_JOB_SUCCESS,
        payload,
    };
}

export function fetchAppliedJobList(): types.FetchAppliedJobList {
    return {
        type: types.FETCH_APPLIED_JOB_LIST,
    };
}

export function loadMoreAppliedJobList(): types.LoadMoreAppliedJobList {
    return {
        type: types.LOAD_MORE_APPLIED_JOB_LIST,
    };
}

export function setAppliedJobList(payload: Partial<AppliedJobModalType>): types.SetAppliedJobList {
    return {
        type: types.SET_APPLIED_JOB_LIST,
        payload,
    };
}

export function fetchShortlistedJobList(): types.FetchShortlistedJobList {
    return {
        type: types.FETCH_SHORTLISTED_JOB_LIST,
    };
}

export function loadMoreShortlistedJobList(): types.LoadMoreShortlistedJobList {
    return {
        type: types.LOAD_MORE_SHORTLISTED_JOB_LIST,
    };
}

export function setShortlistedJobList(payload: Partial<ShortListedJobModalType>): types.SetShortlistedJobList {
    return {
        type: types.SET_SHORTLISTED_JOB_LIST,
        payload,
    };
}

export function setAppliedJobInfo(payload: number): types.SetAppliedJobInfo {
    return {
        type: types.SET_APPLIED_JOB_INFO,
        payload,
    };
}

export function setShortlistedJobInfo(payload: number): types.SetShortlistedJobInfo {
    return {
        type: types.SET_SHORTLISTED_JOB_INFO,
        payload,
    };
}

export function applyJobAfterLogin(): types.ApplyJobAfterLogin {
    return {
        type: types.APPLY_JOB_AFTER_LOGIN,
    };
}

export function applyJobAfterLoginSuccess(payload: Pick<JobListType, 'jobList'>): types.ApplyJobAfterLoginSuccess {
    return {
        type: types.APPLY_JOB_AFTER_LOGIN_SUCCESS,
        payload,
    };
}

export function applyJobFromIndependent(payload: IndependentJobApplicationPayload): types.ApplyJobFromIndependent {
    return {
        type: types.APPLY_JOB_FROM_INDEPENDENT,
        payload,
    };
}

export function reportJob(payload: ReportJobPayloadType): types.ReportJob {
    return {
        type: types.REPORT_JOB,
        payload,
    };
}

export function reportJobSuccess(payload: Pick<JobListType, 'jobList' | 'jobInfo'>): types.ReportJobSuccess {
    return {
        type: types.REPORT_JOB_SUCCESS,
        payload,
    };
}

export function reportAppliedJobSuccess(
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>,
): types.ReportAppliedJobSuccess {
    return {
        type: types.REPORT_APPLIED_JOB_SUCCESS,
        payload,
    };
}

export function reportShortlistedJobSuccess(
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>,
): types.ReportShortlistedJobSuccess {
    return {
        type: types.REPORT_SHORTLISTED_JOB_SUCCESS,
        payload,
    };
}

/**
 * Similar Job actions
 */

export function fetchSimilarJobs(jobId: string): types.FetchSimilarJobList {
    return {
        type: types.FETCH_SIMILAR_JOB_LIST,
        payload: jobId,
    };
}

export function loadMoreSimilarJobs(jobId: string): types.LoadMoreSimilarJobList {
    return {
        type: types.LOAD_MORE_SIMILAR_JOB_LIST,
        payload: jobId,
    };
}

export function setSimilarJobs(payload: Partial<SimilarJobListType>): types.SetSimilarJobList {
    return {
        type: types.SET_SIMILAR_JOB_LIST,
        payload,
    };
}

/**
 * Other Jobs Actions
 */

export function fetchOtherJobs(companyName: string): types.FetchOtherJobList {
    return {
        type: types.FETCH_OTHER_JOB_LIST,
        payload: companyName,
    };
}

export function setOtherJobs(payload: Partial<OtherJobListType>): types.SetOtherJobList {
    return {
        type: types.SET_OTHER_JOB_LIST,
        payload,
    };
}

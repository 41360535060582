import { ChangePasswordType, RateAppType, DeactivateType } from '../../utils/types';

export const SET_FAQS = 'SET_FAQS';
export interface SetFaqs {
    type: typeof SET_FAQS;
}

export const SET_FAQS_SUCCESS = 'SET_FAQS_SUCCESS';
export interface SetFaqsSuccess {
    type: typeof SET_FAQS_SUCCESS;
    payload: string;
}

export const SET_POLICY = 'SET_POLICY';
export interface SetPolicy {
    type: typeof SET_POLICY;
}

export const SET_POLICY_SUCCESS = 'SET_POLICY_SUCCESS';
export interface SetPolicySuccess {
    type: typeof SET_POLICY_SUCCESS;
    payload: string;
}

export const SET_TERMS = 'SET_TERMS';
export interface SetTerms {
    type: typeof SET_TERMS;
}

export const SET_TERMS_SUCCESS = 'SET_TERMS_SUCCESS';
export interface SetTermsSuccess {
    type: typeof SET_TERMS_SUCCESS;
    payload: string;
}

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export interface ChangePassword {
    type: typeof CHANGE_PASSWORD;
    payload: ChangePasswordType;
}

export const RATE_APP = 'RATE_APP';
export interface RateApp {
    type: typeof RATE_APP;
    payload: RateAppType;
}

export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export interface DeactivateAccount {
    type: typeof DEACTIVATE_ACCOUNT;
    payload: DeactivateType;
}

export type types =
    | SetFaqs
    | SetFaqsSuccess
    | SetTerms
    | SetTermsSuccess
    | SetPolicy
    | SetPolicySuccess
    | ChangePassword
    | RateApp
    | DeactivateAccount;

import React, { memo, useState, useEffect } from 'react';
import CustomButton from '../../../components/custom-elements/custom-button';
import ProgressiveImage from 'react-progressive-image';
import classes from './forgot-reset-password.module.scss';
import CustomInput from '../../../components/custom-elements/custom-input';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Success, ForgotPasswordPayloadType, OnBoardingType } from '../../../utils/types';
import Utils from '../../../utils';
import { forgotPasswordInitiate, verifyOtp, resetPassword } from '../../../redux/actions/onBoarding';
import { setShowOnBoardingModal } from '../../../redux/actions/onBoarding';

interface Props {
    store?: any;
    show: boolean;
    success?: Success;
    showHandler: () => void;
    passwordResetToken?: string;
    forgotPasswordFlag: boolean;
    forgotPasswordInitiate?: (payload: string) => void;
    setShowOnBoardingModal?: (payload: OnBoardingType) => void;
    verifyOtp?: (payload: Pick<ForgotPasswordPayloadType, 'email' | 'otp'>) => void;
    resetPassword?: (payload: Pick<ForgotPasswordPayloadType, 'email' | 'password'>) => void;
}

const ForgotResetPassword = memo(function ForgotResetPassword(props: Props) {
    const [newPasswordTypeText, setNewPasswordTypeText] = useState(false);
    const [confirmPasswordTypeText, setConfirmPasswordTypeText] = useState(false);
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');
    const [otpSentFlag, setOtpSentFlag] = React.useState(false);
    const [errorCheck, setErrorCheck] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [cnfPassword, setCnfPassword] = useState('');

    useEffect(() => {
        if (
            props.success &&
            props.success.msg.toLowerCase().indexOf('otp') > -1 &&
            props.success.msg.toLowerCase().indexOf('sent') > -1
        ) {
            setOtpSentFlag(true);
        }
    }, [props.success]);

    useEffect(() => {
        if (!props.show) {
            resetHandler();
        }
    }, [props.show]);

    function validateCnfPassword() {
        if (cnfPassword && newPassword !== cnfPassword) {
            return "Confirm password doesn't match";
        }
        return '';
    }

    function sendOtp() {
        if (!Utils.validators.validateEmail(email)) {
            props.forgotPasswordInitiate && props.forgotPasswordInitiate(email);
            setErrorCheck(false);
        } else {
            setErrorCheck(true);
        }
    }

    function verifyOtp() {
        if (!Utils.validators.validateOtp(otp)) {
            const payload = {
                email,
                otp,
            };
            props.verifyOtp && props.verifyOtp(payload);
            setErrorCheck(false);
        } else {
            setErrorCheck(true);
        }
    }

    function resetPassword() {
        if (
            !Utils.validators.validatePassword(newPassword) &&
            !Utils.validators.validatePassword(cnfPassword) &&
            newPassword === cnfPassword
        ) {
            const payload = {
                email,
                password: cnfPassword.trim(),
            };
            props.resetPassword && props.resetPassword(payload);
        } else {
            setErrorCheck(true);
        }
    }

    function resetHandler() {
        setNewPasswordTypeText(false);
        setConfirmPasswordTypeText(false);
        setOtp('');
        setEmail('');
        setOtpSentFlag(false);
        setErrorCheck(false);
        setNewPassword('');
        setCnfPassword('');
    }

    function backToLogin() {
        props.setShowOnBoardingModal &&
            props.setShowOnBoardingModal({ showOnBoardingModal: true, onBoardingModalName: 'login' });
        resetHandler();
    }

    return props.show ? (
        <div
            className={classes['forgot-reset-password-parent-wrapper']}
            data-test="forgot-reset-password-parent-wrapper"
        >
            <div className={classes['close-image']} onClick={() => props.showHandler()} data-test="close-image"></div>
            <ProgressiveImage
                src={'https://jobget-static.s3.amazonaws.com/new/Group38.png'}
                placeholder={'https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg'}
            >
                {(src: string, loading: boolean) => <img style={{ cursor: 'pointer' }} src={src} alt="main" />}
            </ProgressiveImage>
            <div className={classes['title']}>{props.forgotPasswordFlag ? 'Forgot Password' : 'Reset Password'}</div>
            <div className={classes['description']}>
                {props.forgotPasswordFlag
                    ? !otpSentFlag
                        ? 'Please enter your registered email address. We will send you an OTP to reset your password.'
                        : ''
                    : "It's almost done! Now you can enter your new password."}
            </div>
            <div className={classes['details-wrapper']}>
                {props.forgotPasswordFlag ? (
                    <div className={classes['details']}>
                        {!otpSentFlag && (
                            <>
                                <label htmlFor="email">Email</label>
                                <CustomInput
                                    type="text"
                                    styleClass={classes['custom-input-adjustments']}
                                    onChangeHandler={(e: any) => {
                                        setEmail(e.target.value);
                                        setOtpSentFlag(false);
                                    }}
                                    placeHolder="Enter Email"
                                    id="email"
                                    value={email}
                                    onSubmitHandler={sendOtp}
                                    data-test="email"
                                />
                                {errorCheck && <div className="error">{Utils.validators.validateEmail(email)}</div>}
                            </>
                        )}
                        {otpSentFlag && (
                            <>
                                <label htmlFor="email">OTP</label>
                                <CustomInput
                                    type="text"
                                    maxLength={4}
                                    styleClass={classes['custom-input-adjustments']}
                                    onChangeHandler={(e: any) => setOtp(e.target.value)}
                                    placeHolder="Enter OTP"
                                    id="otp"
                                    value={otp}
                                    onSubmitHandler={verifyOtp}
                                    data-test="otp"
                                />
                                {errorCheck && <div className="error">{Utils.validators.validateOtp(otp)}</div>}
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        <div className={classes['details']}>
                            <label htmlFor="password">New Password</label>
                            <CustomInput
                                type={newPasswordTypeText ? 'text' : 'password'}
                                styleClass={classes['custom-input-adjustments']}
                                onChangeHandler={(e: any) => setNewPassword(e.target.value)}
                                placeHolder="Enter Password"
                                id="password"
                                imageRequired={true}
                                imageSrc="Group 4@3x.svg"
                                imageStyle={classes['password-image']}
                                imageClickHandler={() => {
                                    setNewPasswordTypeText(!newPasswordTypeText);
                                }}
                                value={newPassword}
                                data-test="new-password"
                            />
                            {errorCheck && (
                                <div className="error">{Utils.validators.validatePassword(newPassword)}</div>
                            )}
                        </div>
                        <div className={classes['details']}>
                            <label htmlFor="password">Confirm Password</label>
                            <CustomInput
                                type={confirmPasswordTypeText ? 'text' : 'password'}
                                styleClass={classes['custom-input-adjustments']}
                                onChangeHandler={(e: any) => setCnfPassword(e.target.value)}
                                placeHolder="Enter Password"
                                id="cnfpassword"
                                imageRequired={true}
                                imageSrc="Group 4@3x.svg"
                                imageStyle={classes['password-image']}
                                imageClickHandler={() => {
                                    setConfirmPasswordTypeText(!confirmPasswordTypeText);
                                }}
                                value={cnfPassword}
                                onSubmitHandler={resetPassword}
                                data-test="confirm-password"
                            />
                            <div className="error">{validateCnfPassword()}</div>
                            {errorCheck && validateCnfPassword().length === 0 && (
                                <div className="error">{Utils.validators.validatePassword(cnfPassword)}</div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={classes['btn-wrapper']} data-test="btn-wrapper">
                <CustomButton
                    styleClass={`btn-primary ${classes['adjustments']}`}
                    onClickHandler={props.forgotPasswordFlag ? (!otpSentFlag ? sendOtp : verifyOtp) : resetPassword}
                >
                    {props.forgotPasswordFlag ? (!otpSentFlag ? 'Send' : 'Verify') : 'Reset'}
                </CustomButton>
            </div>
            <div className={classes['back-login']} onClick={backToLogin} data-test="back-to-login">
                Back to Login
            </div>
        </div>
    ) : null;
});

function mapStateToProps(state: AppState) {
    const { success, passwordResetToken } = state.miscellaneous;
    return {
        success,
        passwordResetToken,
    };
}
function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({ forgotPasswordInitiate, verifyOtp, resetPassword, setShowOnBoardingModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotResetPassword);

import * as types from '../actionTypes/miscellaneous';
import { MiscellaneousState } from '../../utils/types';
import { MiscellaneousStateInitial } from '../../modals';

const initialState: MiscellaneousState = new MiscellaneousStateInitial();

export default function miscellaneousReducer(
    state: MiscellaneousState = initialState,
    action: types.types,
): MiscellaneousState {
    switch (action.type) {
        case types.SET_SIDE_DRAWER_SHOW:
            return { ...state, sideDrawerShow: action.payload };

        case types.SET_PREVIOUS_LOCATION:
            return { ...state, previousLocation: action.payload };

        case types.SET_ERROR:
            return { ...state, error: { ...action.payload } };

        case types.SET_JOB_APPLIED_MODAL:
            return { ...state, jobAppliedFlag: action.payload };

        case types.SET_REFRESH_FLAG:
            return { ...state, refreshFlag: action.payload };

        case types.SET_SUCCESS:
            return { ...state, success: { ...action.payload } };

        case types.SET_JOB_APPLIED_WHEN_LOGGED_OUT:
            return { ...state, jobAppliedWhenLoggedOutPayload: { ...action.payload } };

        case types.UNSET_JOB_APPLIED_WHEN_LOGGED_OUT:
            return { ...state, jobAppliedWhenLoggedOutPayload: { categoryId: '', _id: '', recruiterId: '' } };

        case types.ACCOUNT_DEACTIVATED:
            return { ...state, accountDeactivateModal: { ...action.payload } };

        case types.CONTACT_US_SUCCESS:
            return { ...state, contactUsSuccess: action.payload };

        case types.SET_PASSWORD_RESET_TOKEN:
            return { ...state, passwordResetToken: action.payload };

        case types.REVIEW_EMP_SUCCESS:
            return { ...state, reviewEmpSuccess: action.payload };

        case types.SET_APPLIED_JOB_RECRUITER_ID:
            return { ...state, appliedJobRecruiterId: action.payload };

        case types.SET_JOB_DETAIL_CATEGORY_FILTER:
            return { ...state, jobDetailCategoryFilter: action.payload };

        case types.SET_JOB_DETAIL_AVAILABILITY_FILTER:
            return { ...state, jobDetailAvailabilityFilter: action.payload };

        case types.SET_JOB_DETAIL_RADIUS_FILTER:
            return { ...state, jobDetailRadiusFilter: action.payload };

        case types.RESET_JOB_DETAIL_FILTER_SUCCESS:
            return { ...action.payload };

        case types.APPLIED_RECRUITER_DETAIL_ID:
            return { ...state, appliedJobFromRecruiterIdPayload: action.payload };

        case types.WEB_CONTACTS_CONFIRMATION:
            return { ...state, webContactsConfirmationFlag: action.payload };
        case types.DOWNLOAD_LINK_SEND_CONFIRMATION:
            return { ...state, downloadLinkSendConfirmationFlag: action.payload };
        default:
            return state;
    }
}

import { ProfileDetails, ContactUsPayloadType, ReviewEmployerPayloadType } from '../../utils/types';

export const ADD_PROFILE_INFO = 'ADD_PROFILE_INFO';
export interface AddProfileInfo {
    type: typeof ADD_PROFILE_INFO;
    payload: Partial<ProfileDetails>;
}

export const ADD_PROFILE_INFO_SUCCESS = 'ADD_PROFILE_INFO_SUCCESS';
export interface AddProfileInfoSuccess {
    type: typeof ADD_PROFILE_INFO_SUCCESS;
    payload: Partial<ProfileDetails>;
}

export const EDIT_PROFILE_INFO = 'EDIT_PROFILE_INFO';
export interface EditProfileInfo {
    type: typeof EDIT_PROFILE_INFO;
    payload: Partial<ProfileDetails>;
}

export const EDIT_PROFILE_INFO_SUCCESS = 'EDIT_PROFILE_INFO_SUCCESS';
export interface EditProfileInfoSuccess {
    type: typeof EDIT_PROFILE_INFO_SUCCESS;
    payload: Partial<ProfileDetails>;
}

export const SET_PROFILE_INFO_AFTER_ONBOARDING = 'SET_PROFILE_INFO_AFTER_ONBOARDING';
export interface SetProfileInfoAfterOnBoarding {
    type: typeof SET_PROFILE_INFO_AFTER_ONBOARDING;
    payload: ProfileDetails;
}

export const SET_PROFILE_ON_REFRESH = 'SET_PROFILE_ON_REFRESH';
export interface SetProfileOnRefresh {
    type: typeof SET_PROFILE_ON_REFRESH;
}

export const SET_PROFILE_ON_REFRESH_SUCCESS = 'SET_PROFILE_ON_REFRESH_SUCCESS';
export interface SetProfileOnRefreshSuccess {
    type: typeof SET_PROFILE_ON_REFRESH_SUCCESS;
    payload: ProfileDetails;
}

export const CONTACT_US = 'CONTACT_US';
export interface ContactUs {
    type: typeof CONTACT_US;
    payload: ContactUsPayloadType;
}

export const REVIEW_EMPLOYER = 'REVIEW_EMPLOYER';
export interface ReviewEmployer {
    type: typeof REVIEW_EMPLOYER;
    payload: ReviewEmployerPayloadType;
}

export type types =
    | AddProfileInfo
    | AddProfileInfoSuccess
    | EditProfileInfo
    | EditProfileInfoSuccess
    | SetProfileInfoAfterOnBoarding
    | SetProfileOnRefresh
    | SetProfileOnRefreshSuccess
    | ContactUs
    | ReviewEmployer;

export const SET_ACTION_LOADER = 'SET_ACTION_LOADER';
export interface SetActionLoader {
    type: typeof SET_ACTION_LOADER;
    payload: boolean;
}

export const SET_NOTIFICATION_LOADER = 'SET_NOTIFICATION_LOADER';
export interface SetNotificationLoader {
    type: typeof SET_NOTIFICATION_LOADER;
    payload: boolean;
}

export const SET_SIGNUP_LOGIN_LOADER = 'SET_SIGNUP_LOGIN_LOADER';
export interface SetSignUpLogInLoader {
    type: typeof SET_SIGNUP_LOGIN_LOADER;
    payload: boolean;
}

export const SET_APPLY_JOB_LOADER = 'SET_APPLY_JOB_LOADER';
export interface SetApplyJobLoader {
    type: typeof SET_APPLY_JOB_LOADER;
    payload: boolean;
}

export const SET_SETTINGS_LOADER = 'SET_SETTINGS_LOADER';
export interface SetSettingsLoader {
    type: typeof SET_SETTINGS_LOADER;
    payload: boolean;
}

export const CONTACT_US_LOADER = 'CONTACT_US_LOADER';
export interface ContactUsLoader {
    type: typeof CONTACT_US_LOADER;
    payload: boolean;
}

export const REVIEW_EMPLOYER_LOADER = 'REVIEW_EMPLOYER_LOADER';
export interface ReviewEmployerLoader {
    type: typeof REVIEW_EMPLOYER_LOADER;
    payload: boolean;
}

export const SET_SIMILAR_JOB_LOADER = 'SET_SIMILAR_JOB_LOADER';
export interface SetSimilarJobLoader {
    type: typeof SET_SIMILAR_JOB_LOADER;
    payload: boolean;
}

export const SET_CHAT_MESSAGE_LOADER = 'SET_CHAT_MESSAGE_LOADER';
export interface SetChatMessageLoader {
    type: typeof SET_CHAT_MESSAGE_LOADER;
    payload: boolean;
}

export const SET_OTHER_JOB_LOADER = 'SET_OTHER_JOB_LOADER';
export interface SetOtherJobLoader {
    type: typeof SET_OTHER_JOB_LOADER;
    payload: boolean;
}

export type types =
    | SetActionLoader
    | SetNotificationLoader
    | SetSignUpLogInLoader
    | SetApplyJobLoader
    | SetSettingsLoader
    | ContactUsLoader
    | ReviewEmployerLoader
    | SetSimilarJobLoader
    | SetOtherJobLoader
    | SetChatMessageLoader;

import * as types from '../actionTypes/settings';
import { SettingsStateType } from '../../utils/types';
import { SettingsStateInitial } from '../../modals';

export default function SettingsReducer(
    state: SettingsStateType = new SettingsStateInitial(),
    action: types.types,
): SettingsStateType {
    switch (action.type) {
        case types.SET_FAQS_SUCCESS:
            return {
                ...state,
                faqs: action.payload,
            };
        case types.SET_POLICY_SUCCESS:
            return {
                ...state,
                policy: action.payload,
            };
        case types.SET_TERMS_SUCCESS:
            return {
                ...state,
                terms: action.payload,
            };
        default:
            return state;
    }
}

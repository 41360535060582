import {
    FetchJobListType,
    JobListType,
    JobModalType,
    AppliedJobModalType,
    ShortListedJobModalType,
    ReportJobPayloadType,
    SimilarJobListType,
    OtherJobListType,
    IndependentJobApplicationPayload,
} from '../../utils/types';

export const FETCH_SIMILAR_JOB_LIST = 'FETCH_SIMILAR_JOB_LIST';
export interface FetchSimilarJobList {
    type: typeof FETCH_SIMILAR_JOB_LIST;
    payload: string; //jobId
}

export const LOAD_MORE_SIMILAR_JOB_LIST = 'LOAD_MORE_SIMILAR_JOB_LIST';
export interface LoadMoreSimilarJobList {
    type: typeof LOAD_MORE_SIMILAR_JOB_LIST;
    payload: string; //jobId
}

export const SET_SIMILAR_JOB_LIST = 'SET_SIMILAR_JOB_LIST';
export interface SetSimilarJobList {
    type: typeof SET_SIMILAR_JOB_LIST;
    payload: Partial<SimilarJobListType>;
}
export const FETCH_JOB_LIST = 'FETCH_JOB_LIST';
export interface FetchJobList {
    type: typeof FETCH_JOB_LIST;
    payload: FetchJobListType;
}

export const LOAD_MORE_JOB_LIST = 'LOAD_MORE_JOB_LIST';
export interface LoadMoreJobList {
    type: typeof LOAD_MORE_JOB_LIST;
    payload: FetchJobListType;
}

export const SET_JOB_LIST = 'SET_JOB_LIST';
export interface SetJobList {
    type: typeof SET_JOB_LIST;
    payload: Partial<JobListType>;
}

export const SET_JOB_INFO = 'SET_JOB_INFO';
export interface SetJobInfo {
    type: typeof SET_JOB_INFO;
    payload: number;
}

export const SET_JOB_INFO_SUCCESS = 'SET_JOB_INFO_SUCCESS';
export interface SetJobInfoSuccess {
    type: typeof SET_JOB_INFO_SUCCESS;
    payload: JobModalType;
}

export const UNSET_JOB_INFO = 'UNSET_JOB_INFO';
export interface UnsetJobInfo {
    type: typeof UNSET_JOB_INFO;
}

export const APPLY_JOB = 'APPLY_JOB';
export interface ApplyJob {
    type: typeof APPLY_JOB;
    payload: number;
}

export const APPLY_JOB_SUCCESS = 'APPLY_JOB_SUCCESS';
export interface ApplyJobSuccess {
    type: typeof APPLY_JOB_SUCCESS;
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>;
}

export const FETCH_APPLIED_JOB_LIST = 'FETCH_APPLIED_JOB_LIST';
export interface FetchAppliedJobList {
    type: typeof FETCH_APPLIED_JOB_LIST;
}

export const LOAD_MORE_APPLIED_JOB_LIST = 'LOAD_MORE_APPLIED_JOB_LIST';
export interface LoadMoreAppliedJobList {
    type: typeof LOAD_MORE_APPLIED_JOB_LIST;
}

export const SET_APPLIED_JOB_LIST = 'SET_APPLIED_JOB_LIST';
export interface SetAppliedJobList {
    type: typeof SET_APPLIED_JOB_LIST;
    payload: Partial<AppliedJobModalType>;
}

export const FETCH_SHORTLISTED_JOB_LIST = 'FETCH_SHORTLISTED_JOB_LIST';
export interface FetchShortlistedJobList {
    type: typeof FETCH_SHORTLISTED_JOB_LIST;
}

export const LOAD_MORE_SHORTLISTED_JOB_LIST = 'LOAD_MORE_SHORTLISTED_JOB_LIST';
export interface LoadMoreShortlistedJobList {
    type: typeof LOAD_MORE_SHORTLISTED_JOB_LIST;
}

export const SET_SHORTLISTED_JOB_LIST = 'SET_SHORTLISTED_JOB_LIST';
export interface SetShortlistedJobList {
    type: typeof SET_SHORTLISTED_JOB_LIST;
    payload: Partial<ShortListedJobModalType>;
}

export const SET_APPLIED_JOB_INFO = 'SET_APPLIED_JOB_INFO';
export interface SetAppliedJobInfo {
    type: typeof SET_APPLIED_JOB_INFO;
    payload: number;
}

export const SET_SHORTLISTED_JOB_INFO = 'SET_SHORTLISTED_JOB_INFO';
export interface SetShortlistedJobInfo {
    type: typeof SET_SHORTLISTED_JOB_INFO;
    payload: number;
}

export const APPLY_JOB_AFTER_LOGIN = 'APPLY_JOB_AFTER_LOGIN';
export interface ApplyJobAfterLogin {
    type: typeof APPLY_JOB_AFTER_LOGIN;
}

export const APPLY_JOB_AFTER_LOGIN_SUCCESS = 'APPLY_JOB_AFTER_LOGIN_SUCCESS';
export interface ApplyJobAfterLoginSuccess {
    type: typeof APPLY_JOB_AFTER_LOGIN_SUCCESS;
    payload: Pick<JobListType, 'jobList'>;
}

export const APPLY_JOB_FROM_INDEPENDENT = 'APPLY_JOB_FROM_INDEPENDENT';
export interface ApplyJobFromIndependent {
    type: typeof APPLY_JOB_FROM_INDEPENDENT;
    payload: IndependentJobApplicationPayload;
}

export const REPORT_JOB = 'REPORT_JOB';
export interface ReportJob {
    type: typeof REPORT_JOB;
    payload: ReportJobPayloadType;
}

export const REPORT_JOB_SUCCESS = 'REPORT_JOB_SUCCESS';
export interface ReportJobSuccess {
    type: typeof REPORT_JOB_SUCCESS;
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>;
}

export const REPORT_APPLIED_JOB_SUCCESS = 'REPORT_APPLIED_JOB_SUCCESS';
export interface ReportAppliedJobSuccess {
    type: typeof REPORT_APPLIED_JOB_SUCCESS;
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>;
}

export const REPORT_SHORTLISTED_JOB_SUCCESS = 'REPORT_SHORTLISTED_JOB_SUCCESS';
export interface ReportShortlistedJobSuccess {
    type: typeof REPORT_SHORTLISTED_JOB_SUCCESS;
    payload: Pick<JobListType, 'jobList' | 'jobInfo'>;
}

export const FETCH_OTHER_JOB_LIST = 'FETCH_OTHER_JOB_LIST';
export interface FetchOtherJobList {
    type: typeof FETCH_OTHER_JOB_LIST;
    payload: string; //companyName
}

export const SET_OTHER_JOB_LIST = 'SET_OTHER_JOB_LIST';
export interface SetOtherJobList {
    type: typeof SET_OTHER_JOB_LIST;
    payload: Partial<OtherJobListType>;
}

export type types =
    | FetchJobList
    | FetchSimilarJobList
    | SetSimilarJobList
    | LoadMoreJobList
    | SetJobList
    | SetJobInfo
    | SetJobInfoSuccess
    | UnsetJobInfo
    | ApplyJob
    | ApplyJobSuccess
    | FetchAppliedJobList
    | LoadMoreAppliedJobList
    | SetAppliedJobList
    | FetchShortlistedJobList
    | LoadMoreShortlistedJobList
    | SetShortlistedJobList
    | SetAppliedJobInfo
    | SetShortlistedJobInfo
    | ApplyJobAfterLogin
    | ApplyJobAfterLoginSuccess
    | ApplyJobFromIndependent
    | ReportJob
    | ReportJobSuccess
    | ReportAppliedJobSuccess
    | ReportShortlistedJobSuccess
    | FetchOtherJobList
    | SetOtherJobList;

import axios from 'axios';
import moment from 'moment';
import { addressProps } from './types';
import GeoCode from 'react-geocode';
import { CustomDropdownList } from '../modals';
import * as countryCodes from './country-code.json';

/**
 * setup axios instance
 */
const axiosService = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_VERSION}/web/`,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_AUTHORIZATION,
    },
    auth: {
        username: process.env.REACT_APP_API_USER_NAME || '',
        password: process.env.REACT_APP_API_USER_PASS || '',
    },
});
const secondAxiosService = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN?.replace('api/', '')}`,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_AUTHORIZATION,
    },
    auth: {
        username: process.env.REACT_APP_API_USER_NAME || '',
        password: process.env.REACT_APP_API_USER_PASS || '',
    },
});
const history = { replace: (payload = ''): any => {} };
enum userType {
    candidate = 1,
    recruiter = 2,
}

enum api_error_code {
    emailExits = 409,
    timeout = 408,
    invalidEmail = 400,
    inValidUser = 501,
    // sessionExpire: 410,
}
enum jobType {
    fullTime = 1,
    partTime = 2,
    both = 3,
}

enum requestType {
    INITIATE = 'initiate',
    LOAD_MORE = 'load_more',
}

/*** Reusable functions ******/

/* salary range parser*/
function conversionToK(value: number): string {
    let convertedNumberString = '';
    if (value > 999) {
        convertedNumberString = `${Math.floor(value / 1000)}K`;
    } else {
        convertedNumberString = value.toString();
    }

    return convertedNumberString;
}

function conversionToInt(value: string): any {
    const regex = /[^a-zA-Z0-9. ]/g;
    return value.replace(regex, ''); //parseInt(value.replace(regex, ''));
}

function salaryParser(salaryFrom: string, salaryTo: string, duration: string): string {
    if (salaryFrom && salaryTo) {
        return `$${conversionToK(conversionToInt(salaryFrom))}-$${conversionToK(
            conversionToInt(salaryTo),
        )} ${duration}`;
    } else if (salaryFrom) return `$${conversionToK(conversionToInt(salaryFrom))} ${duration}`;
    else return 'Salary not disclosed';
}

/**
 * job experience formator
 */
function experienceParser(totalExp: any): { parsedExperience: any; experienceLabel: string } {
    const totalExperience: number = parseInt(totalExp);
    if (totalExperience <= 11) {
        return { parsedExperience: totalExperience, experienceLabel: totalExperience === 1 ? 'month' : 'months' };
    }
    if (totalExperience >= 12 && totalExperience < 18) {
        return { parsedExperience: 1, experienceLabel: 'year' };
    }
    if (totalExperience > 12 && totalExperience <= 36) {
        const count = Math.floor(totalExperience / 12);
        const remainder = totalExperience % 12;
        return { parsedExperience: remainder > 5 ? count + 0.5 : count, experienceLabel: 'years' };
    }
    if (totalExperience > 36 && totalExperience <= 60) {
        return { parsedExperience: Math.floor(totalExperience / 12), experienceLabel: 'years' };
    }
    if (totalExperience > 60) {
        return { parsedExperience: `5+`, experienceLabel: 'years' };
    }
    return { parsedExperience: -1, experienceLabel: 'error' };
}

function timeDuration(time: string): number {
    const x = time.toString();
    const tempTime = moment.duration(x);
    const y = tempTime.hours() + tempTime.minutes() + tempTime.seconds();
    return y;
}

function formatSearchUrl(search = '', location = '', hasChange: boolean, org = ''): void {
    if (location === 'undefined' || location.includes('undefined')) {
        location = '';
    }
    const pathName = window.location.pathname;
    if (
        (search || location) &&
        (pathName.includes('/search') || pathName.includes('/jobs') || pathName === '/') &&
        hasChange
    ) {
        let url = `/search?q=${search}&w=${location}`;
        if (org) {
            url = url + '&org=' + org;
        }
        history.replace(url);
    }
}
/**Capitalize string */
function capitalize(s: string): string {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function addressFromLatLong(lat: number, long: number, callback: (props: addressProps) => void): void {
    GeoCode.fromLatLng(lat.toString(), long.toString()).then(
        (response: any) => {
            const address = response.results[0].formatted_address;
            const storableLocation = formatAddress(response.results[0].address_components);
            callback({
                fullAddress: address,
                city: storableLocation.city,
                state: storableLocation.state,
                country: storableLocation.country,
                lat: lat,
                lng: long,
            });
        },
        (error: any) => {
            console.error('address error', error.message);
        },
    );
}

/** format address and return city, state, country */
function formatAddress(address: any) {
    const storableLocation: any = {};

    for (let ac = 0; ac < address.length; ac++) {
        const component = address[ac];
        if (component.types.includes('sublocality') || component.types.includes('locality')) {
            storableLocation.city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
            storableLocation.state = component.short_name;
        } else if (component.types.includes('country')) {
            storableLocation.country = component.long_name;
            storableLocation.registered_country_iso_code = component.short_name;
        }
    }
    return storableLocation;
}

function getLatitude() {
    const latitude = localStorage.getItem('latitude') !== null ? localStorage.getItem('latitude') : '42.361145';
    if (latitude !== '0' && latitude !== null) {
        return parseFloat(latitude);
    } else {
        return 42.361145;
    }
}
function getLongitude() {
    const longitude = localStorage.getItem('longitude') !== null ? localStorage.getItem('longitude') : '-71.057083';
    if (longitude !== '0' && longitude !== null) {
        return parseFloat(longitude);
    } else {
        return -71.057083;
    }
}

function distance(lat1: any, lng1: any, lat2: any, lng2: any, miles = true): string {
    // miles optional

    lat2 = lat2 || localStorage.getItem('latitude') || 0;
    lng2 = lng2 || localStorage.getItem('longitude') || 0;
    if (typeof miles === 'undefined') {
        miles = false;
    }
    function deg2rad(deg: number) {
        return deg * (Math.PI / 180);
    }
    function square(x: number) {
        return Math.pow(x, 2);
    }
    const r = 6371; // radius of the earth in km
    lat1 = deg2rad(lat1);
    lat2 = deg2rad(lat2);
    const lat_dif = lat2 - lat1;
    const lng_dif = deg2rad(lng2 - lng1);
    const a = square(Math.sin(lat_dif / 2)) + Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
    const d = 2 * r * Math.asin(Math.sqrt(a));
    if (miles) {
        return `${parseInt((d * 0.621371).toString())} miles`;
    } //return miles
    else {
        return `${d} km`;
    } //return km
}
function callTimeFormatter(messageText: string): string {
    //Math.trunc(moment.duration(parseInt(item.messageText)).asHours()):${moment.duration(parseInt(item.messageText)).minutes()}:${moment.duration(parseInt(item.messageText)).seconds()}
    let hours = Math.trunc(moment.duration(parseInt(messageText)).asHours()).toString();
    let minutes = moment.duration(parseInt(messageText)).minutes().toString();
    let seconds = moment.duration(parseInt(messageText)).seconds().toString();
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;
    return `${minutes.length < 2 ? '0' + minutes : minutes}:${seconds.length < 2 ? '0' + seconds : seconds}`;
}

function timeFormator(timeStamp: number) {
    if (!timeStamp) {
        return 0;
    }
    const date = moment(timeStamp).format('MM/DD/YYYY');
    const today = moment().format('MM/DD/YYYY');
    if (date === today) {
        return moment(timeStamp).format('LT');
    } else {
        return date;
    }
}
function thirdPartJobImageParser(companyName: string): string {
    const slug = companyName?.replace(/[^0-9a-z]/gi, '')?.toLowerCase();
    return `https://logos.jobget.com/${slug}`;
}

/** MONTHS ENUM */
enum MONTHS {
    JAN,
    FEB,
    MAR,
    APR,
    MAY,
    JUN,
    JUL,
    AUG,
    SEP,
    OCT,
    NOV,
    DEC,
}

function getEnumMonthsMatchResult(key: string): number {
    switch (key.toUpperCase()) {
        case 'JAN':
            return MONTHS.JAN;
        case 'FEB':
            return MONTHS.FEB;
        case 'MAR':
            return MONTHS.MAR;
        case 'APR':
            return MONTHS.APR;
        case 'MAY':
            return MONTHS.MAY;
        case 'JUN':
            return MONTHS.JUN;
        case 'JUL':
            return MONTHS.JUL;
        case 'AUG':
            return MONTHS.AUG;
        case 'SEP':
            return MONTHS.SEP;
        case 'OCT':
            return MONTHS.OCT;
        case 'NOV':
            return MONTHS.NOV;
        case 'DEC':
            return MONTHS.DEC;
        default:
            return 0;
    }
}

function getYearsDropDownList() {
    const arr: Array<CustomDropdownList | any> = [];
    const maxYear: number = new Date().getFullYear();
    const minYear: number = maxYear - 60;

    for (let i = maxYear; i >= minYear; i--) {
        arr.push({ text: `${i}`, value: `${i}` });
    }
    return arr;
}

function getMonthsDropDownList() {
    return [
        { text: 'Jan', value: 'Jan' },
        { text: 'Feb', value: 'Feb' },
        { text: 'Mar', value: 'Mar' },
        { text: 'Apr', value: 'Apr' },
        { text: 'May', value: 'May' },
        { text: 'Jun', value: 'Jun' },
        { text: 'Jul', value: 'Jul' },
        { text: 'Aug', value: 'Aug' },
        { text: 'Sep', value: 'Sep' },
        { text: 'Oct', value: 'Oct' },
        { text: 'Nov', value: 'Nov' },
        { text: 'Dec', value: 'Dec' },
    ];
}

function countryCodeFinder(countryFullName: string): string {
    // @ts-ignore
    const countryCodesArray = countryCodes.default;

    const filteredCountry: any = countryCodesArray.filter(
        (countryCodeObject: any) => countryCodeObject.label === countryFullName,
    );
    return filteredCountry[0]?.code || '';
}

function getJobUrlLink(source: any) {
    console.log(source);
    if (source === 'mhcentraljobs') {
        return `https://link.jobget.com/mhcentraljobs`;
    } else {
        if (source === 'adzuna') {
            source = `mweb2adz`;
        } else if (source === 'appcast') {
            source = `mweb2app`;
        } else if (source === 'top-usa-jobs') {
            source = `mweb2top`;
        } else if (source === 'ziprecruiter') {
            source = `mweb2zip`;
        } else if (source === 'neuvoo') {
            source = `mweb2neu`;
        } else if (source === 'jooble') {
            source = `mweb2joo`;
        } else if (source === 'trendingjobs') {
            source = 'trendingjobs';
        } else if (source === 'findjobs') {
            source = 'findjobs';
        } else if (source === 'jobadx') {
            source = 'jobadx';
        } else if (source === 'lensa') {
            source = 'lensa';
        } else if (source === 'whatjobs') {
            source = 'whatjobs';
        } else if (source === 'jobvertise') {
            source = 'jobvertise';
        } else if (source === 'attb') {
            source = 'attb';
        } else if (source === 'clicktrader') {
            source = 'clicktrader';
        } else if (source === 'upward') {
            source = 'upward';
        } else if (source === 'geebo') {
            source = 'geebo';
        } else if (source === 'aspen') {
            source = 'aspen';
        } else if (source === 'juju') {
            source = 'juju';
        } else if (source === 'jobg8') {
            source = 'jobg8';
        } else if (source === 'careerjet') {
            source = 'careerjet';
        } else if (source === 'recruitnet') {
            source = 'recruitnet';
        } else if (source === 'zippia') {
            source = 'zippia';
        } else if (source === 'talroo') {
            source = 'talroo';
        } else if (source === 'careerbliss') {
            source = 'careerbliss';
        } else if (source === 'myjobhelper') {
            source = 'myjobhelper';
        } else if (source === 'jobtome') {
            source = 'jobtome';
        } else if (source === 'googleforjobs') {
            source = 'googleforjobs';
        } else if (source === 'appjobs') {
            source = 'appjobs';
        } else if (source === 'cmp') {
            source = 'cmp';
        } else if (source === 'postjobfree') {
            source = 'postjobfree';
        } else if (source === 'jobrapido') {
            source = 'jobrapido';
        } else if (source === 'tideri') {
            source = 'tideri';
        } else if (source === 'flashrecruit') {
            source = 'flashrecruit';
        } else if (source === 'jora') {
            source = 'jora';
        } else if (source === 'jobswipe') {
            source = 'jobswipe';
        } else if (source === 'xpatjobs') {
            source = 'xpatjobs';
        } else if (source === 'firebrickjobs') {
            source = 'firebrickjobs';
        } else if (source === 'jobserve') {
            source = 'jobserve';
        } else if (source === 'joblift') {
            source = 'joblift';
        } else if (source === 'jobsflag') {
            source = 'jobsflag';
        } else if (source === 'jobdiagnosis') {
            source = 'jobdiagnosis';
        } else if (source === 'snagajob') {
            source = 'snagajob';
        } else if (source === 'mercadojobs') {
            source = 'mercadojobs';
        } else if (source === 'jobcom') {
            source = 'jobcom';
        } else if (source === 'jobnet') {
            source = 'jobnet';
        } else if (source === 'sercanto') {
            source = 'sercanto';
        } else if (source === 'jobilize') {
            source = 'jobilize';
        } else if (source === 'itjobcafe') {
            source = 'itjobcafe';
        } else if (source === 'jobble') {
            source = 'jobble';
        } else if (source === 'connexus') {
            source = 'connexus';
        } else if (source === 'g4j') {
            source = 'g4j';
        } else if (source === 'jobsora') {
            source = 'jobsora';
        } else if (source === 'americanlisted') {
            source = 'americanlisted';
        } else if (source === 'jobintersection') {
            source = 'jobintersection';
        } else if (source === 'reticularmediaelse') {
            source = 'reticularmediaelse';
        } else if (source === 'collegerecruiter') {
            source = 'collegerecruiter';
        } else if (source === 'pjcenterprise') {
            source = 'pjcenterprise';
        } else if (source === 'ct-email') {
            source = 'ct-email';
        } else if (source === 'pandologic') {
            source = 'pandologic';
        } else if (source === 'recruitics') {
            source = 'recruitics';
        } else if (source === 'nexxt') {
            source = 'nexxt';
        } else if (source === 'joveo') {
            source = 'joveo';
        } else if (source === 'mhmetronorthjobs') {
            source = 'mhmetronorthjobs';
        } else if (source === 'mhcentraljobs') {
            source = 'mhcentraljobs';
        } else if (source === 'cmp2') {
            source = 'cmp2';
        } else if (source === 'getit') {
            source = 'getit';
        } else if (source === 'trovit') {
            source = 'trovit';
        } else if (source === 'jobisjob') {
            source = 'jobisjob';
        } else if (source === 'resultsgeneration') {
            source = 'resultsgeneration';
        } else if (source === 'jobswipecpa') {
            source = 'jobswipecpa';
        } else if (source === 'propel') {
            source = 'propel';
        } else if (source === 'talenize') {
            source = 'talenize';
        } else if (source === 'getit2') {
            source = 'getit2';
        } else if (source === 'kimblegroup') {
            source = 'kimblegroup';
        } else if (source === 'kb3') {
            source = 'kb3';
        } else if (source === 'uloop') {
            source = 'uloop';
        } else if (source === 'zoek') {
            source = 'zoek';
        } else {
            source = '';
        }
        if (source) {
            return `https://link.jobget.com/${source}`;
        } else {
            return '';
        }
    }
}

function handleRedirection(domain: string, source: string) {
    if (source === 'mhcentraljobs') {
        window.open(`https://link.jobget.com/mhcentraljobs`, '_blank');
    } else {
        if (source === 'adzuna') {
            source = `${domain}adz`;
        } else if (source === 'appcast') {
            source = `${domain}app`;
        } else if (source === 'top-usa-jobs') {
            source = `${domain}top`;
        } else if (source === 'ziprecruiter') {
            source = `${domain}zip`;
        } else if (source === 'neuvoo') {
            source = `${domain}neu`;
        } else if (source === 'jooble') {
            source = `${domain}joo`;
        } else if (source === 'trendingjobs') {
            source = 'trendingjobs';
        } else if (source === 'findjobs') {
            source = 'findjobs';
        } else if (source === 'jobadx') {
            source = 'jobadx';
        } else if (source === 'lensa') {
            source = 'lensa';
        } else if (source === 'whatjobs') {
            source = 'whatjobs';
        } else if (source === 'jobvertise') {
            source = 'jobvertise';
        } else if (source === 'attb') {
            source = 'attb';
        } else if (source === 'clicktrader') {
            source = 'clicktrader';
        } else if (source === 'upward') {
            source = 'upward';
        } else if (source === 'geebo') {
            source = 'geebo';
        } else if (source === 'aspen') {
            source = 'aspen';
        } else if (source === 'juju') {
            source = 'juju';
        } else if (source === 'jobg8') {
            source = 'jobg8';
        } else if (source === 'careerjet') {
            source = 'careerjet';
        } else if (source === 'recruitnet') {
            source = 'recruitnet';
        } else if (source === 'zippia') {
            source = 'zippia';
        } else if (source === 'talroo') {
            source = 'talroo';
        } else if (source === 'careerbliss') {
            source = 'careerbliss';
        } else if (source === 'myjobhelper') {
            source = 'myjobhelper';
        } else if (source === 'jobtome') {
            source = 'jobtome';
        } else if (source === 'googleforjobs') {
            source = 'googleforjobs';
        } else if (source === 'appjobs') {
            source = 'appjobs';
        } else if (source === 'cmp') {
            source = 'cmp';
        } else if (source === 'postjobfree') {
            source = 'postjobfree';
        } else if (source === 'jobrapido') {
            source = 'jobrapido';
        } else if (source === 'tideri') {
            source = 'tideri';
        } else if (source === 'flashrecruit') {
            source = 'flashrecruit';
        } else if (source === 'jora') {
            source = 'jora';
        } else if (source === 'jobswipe') {
            source = 'jobswipe';
        } else if (source === 'xpatjobs') {
            source = 'xpatjobs';
        } else if (source === 'firebrickjobs') {
            source = 'firebrickjobs';
        } else if (source === 'jobserve') {
            source = 'jobserve';
        } else if (source === 'joblift') {
            source = 'joblift';
        } else if (source === 'jobsflag') {
            source = 'jobsflag';
        } else if (source === 'jobdiagnosis') {
            source = 'jobdiagnosis';
        } else if (source === 'snagajob') {
            source = 'snagajob';
        } else if (source === 'mercadojobs') {
            source = 'mercadojobs';
        } else if (source === 'jobcom') {
            source = 'jobcom';
        } else if (source === 'jobnet') {
            source = 'jobnet';
        } else if (source === 'sercanto') {
            source = 'sercanto';
        } else if (source === 'jobilize') {
            source = 'jobilize';
        } else if (source === 'itjobcafe') {
            source = 'itjobcafe';
        } else if (source === 'jobble') {
            source = 'jobble';
        } else if (source === 'connexus') {
            source = 'connexus';
        } else if (source === 'g4j') {
            source = 'g4j';
        } else if (source === 'jobsora') {
            source = 'jobsora';
        } else if (source === 'americanlisted') {
            source = 'americanlisted';
        } else if (source === 'jobintersection') {
            source = 'jobintersection';
        } else if (source === 'reticularmediaelse') {
            source = 'reticularmediaelse';
        } else if (source === 'collegerecruiter') {
            source = 'collegerecruiter';
        } else if (source === 'pjcenterprise') {
            source = 'pjcenterprise';
        } else if (source === 'ct-email') {
            source = 'ct-email';
        } else if (source === 'pandologic') {
            source = 'pandologic';
        } else if (source === 'recruitics') {
            source = 'recruitics';
        } else if (source === 'nexxt') {
            source = 'nexxt';
        } else if (source === 'joveo') {
            source = 'joveo';
        } else if (source === 'mhmetronorthjobs') {
            source = 'mhmetronorthjobs';
        } else if (source === 'mhcentraljobs') {
            source = 'mhcentraljobs';
        } else if (source === 'cmp2') {
            source = 'cmp2';
        } else if (source === 'getit') {
            source = 'getit';
        } else if (source === 'trovit') {
            source = 'trovit';
        } else if (source === 'jobisjob') {
            source = 'jobisjob';
        } else if (source === 'resultsgeneration') {
            source = 'resultsgeneration';
        } else if (source === 'jobswipecpa') {
            source = 'jobswipecpa';
        } else if (source === 'propel') {
            source = 'propel';
        } else if (source === 'talenize') {
            source = 'talenize';
        } else if (source === 'getit2') {
            source = 'getit2';
        } else if (source === 'kimblegroup') {
            source = 'kimblegroup';
        } else if (source === 'kb3') {
            source = 'kb3';
        } else if (source === 'uloop') {
            source = 'uloop';
        } else if (source === 'zoek') {
            source = 'zoek';
        } else {
            source = `${domain}`;
        }
        window.open(`https://link.jobget.com/${source}`, '_blank');
    }
}

export default {
    jobType,
    history,
    userType,
    distance,
    capitalize,
    userId: '',
    prevUrl: '',
    requestType,
    userName: '',
    timeFormator,
    timeDuration,
    salaryParser,
    axiosService,
    secondAxiosService,
    isLogin: false,
    api_error_code,
    formatSearchUrl,
    experienceParser,
    addressFromLatLong,
    callTimeFormatter,
    countryCodeFinder,
    getJobUrlLink,
    handleRedirection,
    is_profile_added: 0,
    defaultLat: getLatitude(),
    defaultLng: getLongitude(),
    firebasePassword: 'Jobget12345',
    thirdPartJobImageParser: thirdPartJobImageParser,
    phoneRegex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    getEnumMonthsMatchResult,
    getYearsDropDownList,
    getMonthsDropDownList,
};

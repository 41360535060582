import React, { lazy, Profiler, useState, useEffect } from 'react';
import { Route, Switch, Router, BrowserRouter } from 'react-router-dom';
import SuspenseRoute from './suspenseRoute';
import Header from '../components/header/index';
import ErrorToast from '../components/error-toast';
import history from '../utils/history';
import SuccessToast from '../components/success-toast';
import './index.scss';
import { onRenderCallback } from '../utils/services/profiler';
import * as qs from 'query-string';
import Banner, { MobileBanner, JobDetailMobileBanner } from '../components/banner/bottom-banner';
import { getBannerSession, setBannerSession, setCookie, getCookie } from '../utils/services/sessions';
import RecruiterModule from './recruiter';

export default function Routes() {
    const JobsModule = lazy(() => import('./jobs'));
    const ProfileModule = lazy(() => import('./profile'));
    const [windowSizeFlag, setWindowSizeFlag] = useState(false);
    const [hideBanner, setHideBanner] = useState(false);
    const [mobileBanner, setMobileBanner] = useState(true);

    useEffect(() => {
        function setWindowState() {
            if (window.innerWidth <= 800) {
                setWindowSizeFlag(true);
            } else {
                setWindowSizeFlag(false);
            }
        }
        window.addEventListener('resize', setWindowState);

        return function removeListener() {
            window.removeEventListener('resize', setWindowState);
        };
    }, [window.innerWidth]);
    useEffect(() => {
        setWindowSizeFlag(window.innerWidth <= 800 ? true : false);
    }, []);
    useEffect(() => {
        const show: any = getBannerSession();
        setHideBanner(show);
    }, [getBannerSession()]);

    function closeBanner(bannerType = 1) {
        if (bannerType === 2) {
            setMobileBanner(false);
            setHideBanner(true);
            setBannerSession(true);
        } else {
            setHideBanner(true);
            setBannerSession(true);
        }
    }
    function closeMobileBanner() {
        setMobileBanner(false);
    }
    const parsed = qs.parse(window.location.search) || '';
    const source: any = Object.keys(parsed).length > 0 ? parsed.source : '';
    const showMobileBanner =
        mobileBanner &&
        (!(window.location.pathname.includes('/job/') || window.location.pathname.includes('/jobs/job')) ||
            source === '');

    if (source) setCookie('source', source, 30);
    return (
        <BrowserRouter basename="jobs">
            <Header />
            <ErrorToast />
            <SuccessToast />
            <main>
                <Switch>
                    <Route
                        path="/profile"
                        render={(props) => (
                            <SuspenseRoute>
                                <ProfileModule {...props} />
                            </SuspenseRoute>
                        )}
                    />
                    <Route
                        path="/recruiter"
                        render={(props) => (
                            <SuspenseRoute>
                                <RecruiterModule {...props} />
                            </SuspenseRoute>
                        )}
                    />
                    <Route
                        path="/"
                        render={(props) => (
                            <SuspenseRoute>
                                <JobsModule {...props} />
                            </SuspenseRoute>
                        )}
                    />
                </Switch>
            </main>
            {!hideBanner && (
                <>
                    {!windowSizeFlag ? (
                        !window.location.pathname.includes('/jobs/posting') ? (
                            <Banner setShowBanner={closeBanner} />
                        ) : (
                            <></>
                        )
                    ) : (
                        <>
                            {showMobileBanner ? (
                                <MobileBanner
                                    setShowBanner={closeBanner}
                                    closeMobileBanner={closeMobileBanner}
                                    source={getCookie('source') || ''}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            )}
        </BrowserRouter>
    );
}

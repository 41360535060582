import {
    Error,
    Success,
    JobModalType,
    AccountDeactivatedType,
    JobFilterPayload,
    MiscellaneousState,
} from '../../utils/types';
import { CustomDropdownList } from '../../modals';

export const SET_SIDE_DRAWER_SHOW = 'SET_SIDE_DRAWER_SHOW';
export interface SetSideDrawerShow {
    type: typeof SET_SIDE_DRAWER_SHOW;
    payload: boolean;
}

export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';
export interface SetPreviousLocation {
    type: typeof SET_PREVIOUS_LOCATION;
    payload: string;
}

export const SET_ERROR = 'SET_ERROR';
export interface SetError {
    type: typeof SET_ERROR;
    payload: Error;
}

export const SET_JOB_APPLIED_MODAL = 'SET_JOB_APPLIED_MODAL';
export interface SetJobAppliedModal {
    type: typeof SET_JOB_APPLIED_MODAL;
    payload: boolean;
}

export const SET_REFRESH_FLAG = 'SET_REFRESH_FLAG';
export interface SetRefreshFlag {
    type: typeof SET_REFRESH_FLAG;
    payload: boolean;
}

export const SET_SUCCESS = 'SET_SUCCESS';
export interface SetSuccess {
    type: typeof SET_SUCCESS;
    payload: Success;
}

export const SET_JOB_APPLIED_WHEN_LOGGED_OUT = 'SET_JOB_APPLIED_WHEN_LOGGED_OUT';
export interface SetJobAppliedWhenLoggedOut {
    type: typeof SET_JOB_APPLIED_WHEN_LOGGED_OUT;
    payload: Pick<JobModalType, '_id' | 'categoryId' | 'recruiterId'>;
}

export const UNSET_JOB_APPLIED_WHEN_LOGGED_OUT = 'UnSET_JOB_APPLIED_WHEN_LOGGED_OUT';
export interface UnsetJobAppliedWhenLoggedOut {
    type: typeof UNSET_JOB_APPLIED_WHEN_LOGGED_OUT;
}

export const ACCOUNT_DEACTIVATED = 'ACCOUNT_DEACTIVATED';
export interface AccountDeactivated {
    type: typeof ACCOUNT_DEACTIVATED;
    payload: AccountDeactivatedType;
}

export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export interface ContactUsSuccess {
    type: typeof CONTACT_US_SUCCESS;
    payload: boolean;
}

export const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN';
export interface SetPasswordResetToken {
    type: typeof SET_PASSWORD_RESET_TOKEN;
    payload: string;
}

export const REVIEW_EMP_SUCCESS = 'REVIEW_EMP_SUCCESS';
export interface ReviewEmpSuccess {
    type: typeof REVIEW_EMP_SUCCESS;
    payload: boolean;
}

export const SET_APPLIED_JOB_RECRUITER_ID = 'SET_APPLIED_JOB_RECRUITER_ID';
export interface SetAppliedJobRecruiterId {
    type: typeof SET_APPLIED_JOB_RECRUITER_ID;
    payload: string;
}

export const SET_JOB_DETAIL_CATEGORY_FILTER = 'SET_JOB_DETAIL_CATEGORY_FILTER';
export interface SetJobDetailCategoryFilter {
    type: typeof SET_JOB_DETAIL_CATEGORY_FILTER;
    payload: Array<CustomDropdownList>;
}

export const SET_JOB_DETAIL_AVAILABILITY_FILTER = 'SET_JOB_DETAIL_AVAILABILITY_FILTER';
export interface SetJobDetailAvailabilityFilter {
    type: typeof SET_JOB_DETAIL_AVAILABILITY_FILTER;
    payload: CustomDropdownList;
}

export const SET_JOB_DETAIL_RADIUS_FILTER = 'SET_JOB_DETAIL_RADIUS_FILTER';
export interface SetJobDetailRadiusFilter {
    type: typeof SET_JOB_DETAIL_RADIUS_FILTER;
    payload: CustomDropdownList;
}

export const REDIRECT_ON_JOB_DETAIL_FILTER = 'REDIRECT_ON_JOB_DETAIL_FILTER';
export interface RedirectOnJobDetailFilter {
    type: typeof REDIRECT_ON_JOB_DETAIL_FILTER;
    payload: JobFilterPayload;
}

export const RESET_JOB_DETAIL_FILTER = 'RESET_JOB_DETAIL_FILTER';
export interface ResetJobDetailFilter {
    type: typeof RESET_JOB_DETAIL_FILTER;
}

export const RESET_JOB_DETAIL_FILTER_SUCCESS = 'RESET_JOB_DETAIL_FILTER_SUCCESS';
export interface ResetJobDetailFilterSuccess {
    type: typeof RESET_JOB_DETAIL_FILTER_SUCCESS;
    payload: MiscellaneousState;
}

export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export interface SetNotificationCount {
    type: typeof SET_NOTIFICATION_COUNT;
}

export const SET_NOTIFICATION_COUNT_SUCCESS = 'SET_NOTIFICATION_COUNT_SUCCESS';
export interface SetNotificationCountSuccess {
    type: typeof SET_NOTIFICATION_COUNT_SUCCESS;
    payload: number;
}

export const APPLIED_RECRUITER_DETAIL_ID = 'APPLIED_RECRUITER_DETAIL_ID';
export interface SetAppliedRecruiterId {
    type: typeof APPLIED_RECRUITER_DETAIL_ID;
    payload: string;
}

export const WEB_CONTACTS_CONFIRMATION = 'WEB_CONTACTS_CONFIRMATION';
export interface WebContactsConfirmation {
    type: typeof WEB_CONTACTS_CONFIRMATION;
    payload: boolean;
}

export const DOWNLOAD_LINK_SEND_CONFIRMATION = 'DOWNLOAD_LINK_SEND_CONFIRMATION';
export interface DownloadLinkSendConfirmation {
    type: typeof DOWNLOAD_LINK_SEND_CONFIRMATION;
    payload: boolean;
}
export type types =
    | SetSideDrawerShow
    | SetPreviousLocation
    | SetError
    | SetJobAppliedModal
    | SetRefreshFlag
    | SetSuccess
    | SetJobAppliedWhenLoggedOut
    | UnsetJobAppliedWhenLoggedOut
    | AccountDeactivated
    | ContactUsSuccess
    | SetPasswordResetToken
    | ReviewEmpSuccess
    | SetAppliedJobRecruiterId
    | SetJobDetailAvailabilityFilter
    | SetJobDetailCategoryFilter
    | SetJobDetailRadiusFilter
    | RedirectOnJobDetailFilter
    | ResetJobDetailFilter
    | ResetJobDetailFilterSuccess
    | SetNotificationCount
    | SetNotificationCountSuccess
    | SetAppliedRecruiterId
    | WebContactsConfirmation
    | DownloadLinkSendConfirmation;

import constant from '../constant';
import OfflineError from '../offlineError';
import Axios from 'axios';

export function postCall(endPoint: string, params: Record<string, unknown> = {}): Promise<any> {
    if (!navigator.onLine) {
        throw new OfflineError('You are offline!');
    }
    return constant.axiosService.post(endPoint, params);
}

export function getCall(
    endPoint: string,
    queryParams: string,
    params: Record<string, unknown> = {},
    isSettings?: boolean,
): Promise<any> {
    if (!navigator.onLine) {
        throw new OfflineError('You are offline!');
    }
    if (isSettings) {
        return Axios.get(endPoint);
    }
    return constant.axiosService.get(`${endPoint}${queryParams ? `/${queryParams}` : ''}`, params);
}

export function putCall(endPoint: string, params: Record<string, unknown> = {}): Promise<any> {
    if (!navigator.onLine) {
        throw new OfflineError('You are offline!');
    }
    return constant.axiosService.put(endPoint, params);
}

export function deleteCall(endPoint: string, queryParams: string, params: Record<string, unknown> = {}): Promise<any> {
    if (!navigator.onLine) {
        throw new OfflineError('You are offline!');
    }
    return constant.axiosService.delete(`${endPoint}${queryParams ? `/${queryParams}` : ''}`, params);
}

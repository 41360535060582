import * as types from '../actionTypes/settings';
import { ChangePasswordType, RateAppType, DeactivateType } from '../../utils/types';

export function setFaqs(): types.SetFaqs {
    return {
        type: types.SET_FAQS,
    };
}

export function setFaqsSuccess(payload: string): types.SetFaqsSuccess {
    return {
        type: types.SET_FAQS_SUCCESS,
        payload,
    };
}

export function setTerms(): types.SetTerms {
    return {
        type: types.SET_TERMS,
    };
}

export function setTermsSuccess(payload: string): types.SetTermsSuccess {
    return {
        type: types.SET_TERMS_SUCCESS,
        payload,
    };
}

export function setPolicy(): types.SetPolicy {
    return {
        type: types.SET_POLICY,
    };
}

export function setPolicySuccess(payload: string): types.SetPolicySuccess {
    return {
        type: types.SET_POLICY_SUCCESS,
        payload,
    };
}

export function changePassword(payload: ChangePasswordType): types.ChangePassword {
    return {
        type: types.CHANGE_PASSWORD,
        payload,
    };
}

export function rateApp(payload: RateAppType): types.RateApp {
    return {
        type: types.RATE_APP,
        payload,
    };
}

export function deactivateAcc(payload: DeactivateType): types.DeactivateAccount {
    return {
        type: types.DEACTIVATE_ACCOUNT,
        payload,
    };
}

import { put, fork, select, takeLatest } from 'redux-saga/effects';
import * as actionCreators from '../actions/job';
import * as loaderActionCreators from '../actions/global-loader';
import * as actionTypes from '../actionTypes/job';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import Utils from '../../utils';
import { getCall, postCall } from '../../utils/services/network';
import { AppState } from '../reducers/rootReducer';
import {
    JobModalType,
    JobListType,
    Error,
    AppliedJobModalType,
    ShortListedJobModalType,
    Success,
} from '../../utils/types';
import { JobModal, JobListModal } from '../../modals';
import OfflineError from '../../utils/offlineError';
import { getJobFromWebIntialAsync, getJobFromWebLoadMoreAsync } from '../../utils/services/external-source-api';
import history from '../../utils/history';
import { getUserId } from '../../utils/services/sessions';

export function* workerFetchJobList(action: actionTypes.FetchJobList) {
    try {
        yield put(actionCreators.setJobList({ ...new JobListModal() }));
        const {
            currentPage,
            sort,
            next,
            search,
            isMap,
            jobByAge,
            jobInfo,
            jobList,
            thirdPartyNext,
            thirdPartyPage,
        } = yield select((state: AppState) => state.jobs.jobList);
        const {
            latitude,
            longitude,
            referral,
            searchKey,
            org,
            categoryId,
            jobType,
            locationCity,
            locationState,
            radius,
        } = action.payload;
        const userId = localStorage.getItem('candidate_user_id') || '';
        let endPoint;
        if (userId) {
            endPoint = Utils.endpoints.jobList(
                currentPage,
                sort,
                latitude,
                longitude,
                searchKey,
                categoryId,
                isMap,
                radius,
                jobType,
                jobByAge,
                org,
            );
        } else {
            endPoint = Utils.endpoints.guestJobList(
                currentPage,
                sort,
                latitude,
                longitude,
                searchKey,
                categoryId,
                isMap,
                radius,
                jobType,
                jobByAge,
                org,
            );
        }
        console.log(endPoint);
        yield put(loaderActionCreators.setActionLoader(true));
        const response = yield getCall(endPoint, '');
        if (response.data.code === 200) {
            const data: Array<JobModalType> = response.data.data.jobs;
            if (data.length > 0) {
                const payload: Partial<JobListType> = {
                    jobList: [...data],
                    next: response.data.next,
                    page: response.data.page,
                    currentPage: response.data.page,
                    primpage: response.data.primpage,
                    thirdPartyNext: data.length < 15 ? true : false,
                };
                payload.jobInfo = { ...data[0] };
                yield put(actionCreators.setJobList(payload));
                yield put(loaderActionCreators.setActionLoader(false));
                return;
            } else {
                const locationCityValue = locationCity ? locationCity : '';
                const locationStateValue = locationState ? locationState : '';

                const response = yield getJobFromWebIntialAsync(
                    searchKey,
                    locationCityValue,
                    locationStateValue,
                    thirdPartyNext,
                    thirdPartyPage,
                    categoryId,
                );
                if (response.status === 200) {
                    let data = response.data;
                    let payload: Partial<JobListType>;
                    if (data.length > 0) {
                        data = data.map((item: any) => ({
                            ...item,
                            thirdPartyJob: true,
                            jobDesc: item.description,
                            jobTitle: item.title,
                            companyName: item.company,
                            jobCity: item.city,
                            jobState: item.state,
                            created_at: item.posted_time,
                            categoryName: item.category,
                            jobType: item.job_type === 'Full-Time' ? 1 : 2,
                            jobImage: item.logo ? item.logo : '',
                            jobUrl: item.job_url,
                        }));
                        payload = {
                            jobList: data,
                            next: false,
                            page: 1,
                            currentPage: 1,
                            thirdPartyPage: thirdPartyPage + 1,
                            thirdPartyNext: data.length > 0 ? true : false,
                        };
                        payload.jobInfo = data[0];
                    } else {
                        payload = {
                            jobList: [],
                            next: false,
                            page: 0,
                            currentPage: 0,
                            thirdPartyPage: 0,
                            thirdPartyNext: false,
                        };
                        payload.jobInfo = { ...new JobModal() };
                    }
                    yield put(actionCreators.setJobList(payload));
                    yield put(loaderActionCreators.setActionLoader(false));
                    return;
                }
            }
        }
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherfetchJobList() {
    yield takeLatest(actionTypes.FETCH_JOB_LIST, workerFetchJobList);
}

export function* workerLoadMoreJobList(action: actionTypes.FetchJobList) {
    try {
        const {
            currentPage,
            sort,
            next,
            search,
            isMap,
            jobByAge,
            jobInfo,
            jobList,
            thirdPartyNext,
            thirdPartyPage,
        } = yield select((state: AppState) => state.jobs.jobList);
        const {
            latitude,
            longitude,
            referral,
            searchKey,
            org,
            categoryId,
            jobType,
            radius,
            locationCity,
            locationState,
        } = action.payload;
        const userId = localStorage.getItem('candidate_user_id') || '';
        let endPoint;
        if (userId) {
            endPoint = Utils.endpoints.jobList(
                currentPage,
                sort,
                latitude,
                longitude,
                searchKey,
                categoryId,
                isMap,
                radius,
                jobType,
                jobByAge,
                org,
            );
        } else {
            endPoint = Utils.endpoints.guestJobList(
                currentPage,
                sort,
                latitude,
                longitude,
                searchKey,
                categoryId,
                isMap,
                radius,
                jobType,
                jobByAge,
                org,
            );
        }
        console.log(endPoint);
        if (jobList.length === 0) {
            yield put(loaderActionCreators.setActionLoader(true));
        }
        const response = yield getCall(endPoint, '');
        if (response.data.code === 200) {
            const data: Array<JobModalType> = response.data.data.jobs;
            if (data.length > 0) {
                const payload: Partial<JobListType> = {
                    jobList: [...jobList, ...data],
                    next: response.data.next,
                    page: response.data.page,
                    currentPage: response.data.page,
                    primpage: response.data.primpage,
                    thirdPartyNext: data.length < 15 ? true : false,
                };
                yield put(actionCreators.setJobList(payload));
                yield put(loaderActionCreators.setActionLoader(false));
                return;
            } else {
                const locationCityValue = locationCity ? locationCity : '';
                const locationStateValue = locationState ? locationState : '';
                const response = yield getJobFromWebLoadMoreAsync(
                    searchKey,
                    locationCityValue,
                    locationStateValue,
                    thirdPartyNext,
                    thirdPartyPage,
                    categoryId,
                );
                console.log(response);
                if (response.status === 200) {
                    let data = response.data;
                    data = data.map((item: any) => ({
                        ...item,
                        thirdPartyJob: true,
                        jobDesc: item.description,
                        jobTitle: item.title,
                        companyName: item.company,
                        jobCity: item.city,
                        jobState: item.state,
                        created_at: item.posted_time,
                        categoryName: item.category,
                        jobType: item.job_type === 'Full-Time' ? 1 : 2,
                        jobImage: item.logo ? item.logo : '',
                        jobUrl: item.job_url,
                    }));

                    const { jobList, page, next, currentPage } = yield select((state: AppState) => state.jobs.jobList);

                    const payload = {
                        jobList: [...jobList, ...data],
                        thirdPartyPage: data.length > 0 ? thirdPartyPage + 1 : thirdPartyPage,
                        thirdPartyNext: data.length > 0 ? true : false,
                        page,
                        next,
                        currentPage,
                    };
                    yield put(actionCreators.setJobList(payload));
                    yield put(loaderActionCreators.setActionLoader(false));
                    return;
                }
            }
        }
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLoadMoreJobList() {
    yield takeLatest(actionTypes.LOAD_MORE_JOB_LIST, workerLoadMoreJobList);
}

export function* workerSetJobInfo(action: actionTypes.SetJobInfo) {
    try {
        const { jobList } = yield select((state: AppState) => state.jobs.jobList);
        const filteredJob = { ...jobList[action.payload] };
        yield put(actionCreators.setJobInfoSuccess(filteredJob));
    } catch (error) {
        const payload: Error = {
            msg: 'Something went wrong!',
        };
        yield put(miscellaneousActionCreators.setError(payload));
    }
}

function* watcherSetJobInfo() {
    yield takeLatest(actionTypes.SET_JOB_INFO, workerSetJobInfo);
}

export function* workerApplyJob(action: actionTypes.ApplyJob) {
    try {
        yield put(loaderActionCreators.setApplyJobLoader(true));
        const endPoint = Utils.endpoints.applyJob;
        const jobIndex = action.payload;
        const jobListState = yield select((state: AppState) => state.jobs.jobList);
        const profileState = yield select((state: AppState) => state.profileDetails);
        const payload = {
            recruiterId: jobListState.jobList[jobIndex].recruiterId._id,
            jobId: jobListState.jobList[jobIndex]._id,
            userId: profileState._id,
            categoryId: jobListState.jobList[jobIndex].categoryId,
        };
        const response = yield postCall(endPoint, payload);
        if (response.status) {
            const jobList = [...jobListState.jobList];
            jobList[jobIndex].isApplied = 1;
            const jobInfo = { ...jobListState.jobInfo };
            jobInfo.isApplied = 1;
            const payload = {
                jobList: jobList,
                jobInfo: jobInfo,
            };
            yield put(actionCreators.applyJobSuccess(payload));
            yield put(
                miscellaneousActionCreators.setAppliedJobRecruiterId(jobListState.jobList[jobIndex].recruiterId._id),
            );
            yield put(miscellaneousActionCreators.setJobAppliedModal(true));
        }
        yield put(loaderActionCreators.setApplyJobLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setApplyJobLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherApplyJob() {
    yield takeLatest(actionTypes.APPLY_JOB, workerApplyJob);
}

export function* workerFetchAppliedJobList() {
    try {
        const endPoint = Utils.endpoints.applyShortListedJob(0, 1, '');
        yield put(loaderActionCreators.setActionLoader(true));
        const response = yield getCall(endPoint, '');
        if (response.status) {
            const data: Array<JobModalType> = response.data.data.jobs;
            const payload: Partial<AppliedJobModalType> = {
                appliedJobList: data,
                applyNext: response.data.next,
                applyPage: response.data.page,
                applyCurrentPage: 1,
            };
            yield put(actionCreators.setAppliedJobList(payload));
            if (data.length > 0) yield put(actionCreators.setAppliedJobInfo(0));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherfetchAppliedJobList() {
    yield takeLatest(actionTypes.FETCH_APPLIED_JOB_LIST, workerFetchAppliedJobList);
}

export function* workerLoadMoreAppliedJobList() {
    try {
        const { applyCurrentPage, appliedJobList } = yield select((state: AppState) => state.jobs.appliedJobs);
        const endPoint = Utils.endpoints.applyShortListedJob(applyCurrentPage, 1, '');
        yield put(loaderActionCreators.setActionLoader(true));
        const response = yield getCall(endPoint, '');
        if (response.status) {
            const data: Array<JobModalType> = response.data.data.jobs;
            const payload: Partial<AppliedJobModalType> = {
                appliedJobList: [...appliedJobList, ...data],
                applyNext: response.data.next,
                applyPage: response.data.page,
                applyCurrentPage: applyCurrentPage + 1,
            };
            yield put(actionCreators.setAppliedJobList(payload));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLoadMoreAppliedJobList() {
    yield takeLatest(actionTypes.LOAD_MORE_APPLIED_JOB_LIST, workerLoadMoreAppliedJobList);
}

export function* workerFetchShortlistedJobList() {
    try {
        const endPoint = Utils.endpoints.applyShortListedJob(0, 2, '');
        yield put(actionCreators.unsetJobInfo());
        yield put(loaderActionCreators.setActionLoader(true));
        const response = yield getCall(endPoint, '');
        if (response.status) {
            const data: Array<JobModalType> = response.data.data.jobs;
            const payload: Partial<ShortListedJobModalType> = {
                shortListJobList: data,
                shortListNext: response.data.next,
                shortListPage: response.data.page,
                shortListCurrentPage: 1,
            };
            yield put(actionCreators.setShortlistedJobList(payload));
            if (data.length > 0) yield put(actionCreators.setShortlistedJobInfo(0));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherfetchShortlistedJobList() {
    yield takeLatest(actionTypes.FETCH_SHORTLISTED_JOB_LIST, workerFetchShortlistedJobList);
}

export function* workerLoadMoreShortlistedJobList() {
    try {
        const { shortListCurrentPage, shortListJobList } = yield select(
            (state: AppState) => state.jobs.shortlistedJobs,
        );
        const endPoint = Utils.endpoints.applyShortListedJob(shortListCurrentPage, 2, '');
        yield put(loaderActionCreators.setActionLoader(true));
        const response = yield getCall(endPoint, '');
        if (response.status) {
            const data: Array<JobModalType> = response.data.data.jobs;
            const payload: Partial<ShortListedJobModalType> = {
                shortListJobList: [...shortListJobList, ...data],
                shortListNext: response.data.next,
                shortListPage: response.data.page,
                shortListCurrentPage: shortListCurrentPage + 1,
            };
            yield put(actionCreators.setShortlistedJobList(payload));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLoadMoreShortlistedJobList() {
    yield takeLatest(actionTypes.LOAD_MORE_SHORTLISTED_JOB_LIST, workerLoadMoreShortlistedJobList);
}

export function* workerSetAppliedJobInfo(action: actionTypes.SetAppliedJobInfo) {
    try {
        const { appliedJobList } = yield select((state: AppState) => state.jobs.appliedJobs);
        const filteredJob = { ...appliedJobList[action.payload] };
        yield put(actionCreators.setJobInfoSuccess(filteredJob));
    } catch (error) {
        const payload: Error = {
            msg: 'Something went wrong!',
        };
        yield put(miscellaneousActionCreators.setError(payload));
    }
}

function* watcherSetAppliedJobInfo() {
    yield takeLatest(actionTypes.SET_APPLIED_JOB_INFO, workerSetAppliedJobInfo);
}

export function* workerSetShortlistedJobInfo(action: actionTypes.SetShortlistedJobInfo) {
    try {
        const { shortListJobList } = yield select((state: AppState) => state.jobs.shortlistedJobs);
        const filteredJob = { ...shortListJobList[action.payload] };
        yield put(actionCreators.setJobInfoSuccess(filteredJob));
    } catch (error) {
        const payload: Error = {
            msg: 'Something went wrong!',
        };
        yield put(miscellaneousActionCreators.setError(payload));
    }
}

function* watcherSetShortlistedJobInfo() {
    yield takeLatest(actionTypes.SET_SHORTLISTED_JOB_INFO, workerSetShortlistedJobInfo);
}

export function* workerApplyJobAfterLogin() {
    try {
        const endPoint = Utils.endpoints.applyJob;
        const jobListState = yield select((state: AppState) => state.jobs.jobList);
        const profileState = yield select((state: AppState) => state.profileDetails);
        const miscellaneousState = yield select((state: AppState) => state.miscellaneous);
        const payload = {
            recruiterId: miscellaneousState.jobAppliedWhenLoggedOutPayload.recruiterId,
            jobId: miscellaneousState.jobAppliedWhenLoggedOutPayload._id,
            userId: profileState._id,
            categoryId: miscellaneousState.jobAppliedWhenLoggedOutPayload.categoryId,
        };
        const response = yield postCall(endPoint, payload);
        if (response.status) {
            const jobList = [...jobListState.jobList];
            const jobIndex = jobList.findIndex(
                (ele: JobModalType) => ele._id === miscellaneousState.jobAppliedWhenLoggedOutPayload._id,
            );
            if (jobIndex !== -1) jobList[jobIndex].isApplied = 1;
            const payload = {
                jobList: jobList,
            };
            yield put(actionCreators.applyJobAfterLoginSuccess(payload));
            yield put(miscellaneousActionCreators.UnsetJobAppliedWhenLoggedOut());
            yield put(
                miscellaneousActionCreators.setAppliedJobRecruiterId(
                    miscellaneousState.jobAppliedWhenLoggedOutPayload.recruiterId,
                ),
            );
            yield put(miscellaneousActionCreators.setJobAppliedModal(true));
        }
    } catch (error) {
        let message = 'Something went wrong while applying to job!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
            yield put(miscellaneousActionCreators.UnsetJobAppliedWhenLoggedOut());
        }
    }
}

function* watcherApplyJobAfterLogin() {
    yield takeLatest(actionTypes.APPLY_JOB_AFTER_LOGIN, workerApplyJobAfterLogin);
}

export function* workerApplyJobFromIndependent(action: actionTypes.ApplyJobFromIndependent) {
    try {
        yield put(loaderActionCreators.setApplyJobLoader(true));
        const endPoint = Utils.endpoints.applyJob;
        const { categoryId, _id, recruiterId, isRecruiter } = action.payload;
        const jobListState = yield select((state: AppState) => state.jobs.jobList);
        const profileState = yield select((state: AppState) => state.profileDetails);
        const payload = {
            recruiterId: recruiterId,
            jobId: _id,
            categoryId,
            userId: profileState._id,
        };
        const response = yield postCall(endPoint, payload);
        if (response.status) {
            const jobIndex = jobListState.jobList.findIndex((ele: JobModalType) => ele._id === _id);
            if (jobIndex !== -1) {
                const jobList = [...jobListState.jobList];
                jobList[jobIndex].isApplied = 1;
                const jobInfo = { ...jobListState.jobInfo };
                const payload = {
                    jobList: jobList,
                    jobInfo: jobInfo,
                };
                yield put(actionCreators.applyJobSuccess(payload));
            }
            const successPayload: Success = {
                msg: 'Job Applied Successfully!',
            };
            yield put(loaderActionCreators.setApplyJobLoader(false));
            yield put(miscellaneousActionCreators.setSuccess(successPayload));
            if (isRecruiter) {
                yield put(miscellaneousActionCreators.setAppliedRecruiterDetailId(_id));
            } else {
                history.push('/applications');
            }
        }
        yield put(loaderActionCreators.setApplyJobLoader(false));
    } catch (error) {
        console.log('apply job from independent', error);
        yield put(loaderActionCreators.setApplyJobLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherApplyJobFromIndependent() {
    yield takeLatest(actionTypes.APPLY_JOB_FROM_INDEPENDENT, workerApplyJobFromIndependent);
}

export function* workerReportJob(action: actionTypes.ReportJob) {
    try {
        yield put(loaderActionCreators.setActionLoader(true));
        const endPoint = Utils.endpoints.reportJob;
        const { jobId, index, jobType, isJobDetailIndependent } = action.payload;
        const payload = {
            jobId,
        };
        const response = yield postCall(endPoint, payload);
        if (response.data.code === 200) {
            if (isJobDetailIndependent) {
                history.push('/');
            } else {
                const state = yield select((state: AppState) => state.jobs.jobList);
                const appLiedJobstate = yield select((state: AppState) => state.jobs.appliedJobs);
                const shortlistedJobstate = yield select((state: AppState) => state.jobs.shortlistedJobs);
                let jobList = [...state.jobList];
                if (jobType === 2) {
                    jobList = [...appLiedJobstate.appliedJobList];
                }
                if (jobType === 3) {
                    jobList = [...shortlistedJobstate.shortListJobList];
                }
                jobList.splice(index, 1);
                let jobInfo = new JobModal();
                if (jobList.length > 0) {
                    jobInfo = { ...jobList[0] };
                    if (index + 1 < jobList.length) {
                        jobInfo = { ...jobList[index + 1] };
                    }
                }
                const payload = {
                    jobList: jobList,
                    jobInfo: jobInfo,
                };
                if (jobType === 1) {
                    yield put(actionCreators.reportJobSuccess(payload));
                }
                if (jobType === 2) {
                    yield put(actionCreators.reportAppliedJobSuccess(payload));
                }
                if (jobType === 3) {
                    yield put(actionCreators.reportShortlistedJobSuccess(payload));
                }
            }

            yield put(miscellaneousActionCreators.setSuccess({ msg: response.data.message }));
        } else {
            yield put(miscellaneousActionCreators.setError({ msg: response.data.message }));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherReportJob() {
    yield takeLatest(actionTypes.REPORT_JOB, workerReportJob);
}

/**
 * Similar Job Saga
 */

export function* workerFetchSimilarJobList(action: actionTypes.FetchSimilarJobList) {
    try {
        yield put(loaderActionCreators.setSimilarJobLoader(true));
        const jobId: string = action.payload;
        const { page } = yield select((state) => state.jobs.similarJobs);
        const endpoint = getUserId()
            ? Utils.endpoints.similarJob(page, jobId)
            : Utils.endpoints.guestSimilarJob(page, jobId);
        const response = yield getCall(endpoint, '');
        // console.log(response);
        const { data, status } = response;
        if (status === 200) {
            if (data.data.jobs.length > 0) {
                const similarJobList = data.data.jobs;
                yield put(
                    actionCreators.setSimilarJobs({
                        jobList: similarJobList,
                        page: similarJobList.length + 1,
                        next: data.next,
                    }),
                );
            }
            yield put(loaderActionCreators.setSimilarJobLoader(false));
        } else {
            yield put(miscellaneousActionCreators.setError({ msg: 'Response status code is not 200 OK' }));
        }
    } catch (error) {
        yield put(loaderActionCreators.setSimilarJobLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherFetchSimilarJobList() {
    yield takeLatest(actionTypes.FETCH_SIMILAR_JOB_LIST, workerFetchSimilarJobList);
}

export function* workerLoadMoreSimilarJobList(action: actionTypes.LoadMoreSimilarJobList) {
    try {
        yield put(loaderActionCreators.setSimilarJobLoader(true));
        const jobId: string = action.payload;
        const { page, jobList } = yield select((state) => state.jobs.similarJobs);
        const endpoint = getUserId()
            ? Utils.endpoints.similarJob(page, jobId)
            : Utils.endpoints.guestSimilarJob(page, jobId);
        const response = yield getCall(endpoint, '');
        console.log(response);
        const { data, status } = response;
        if (status === 200) {
            if (data.data.jobs.length > 0) {
                const similarJobList = [...jobList, ...data.data.jobs];
                yield put(
                    actionCreators.setSimilarJobs({
                        jobList: similarJobList,
                        page: similarJobList.length + 1,
                        next: data.next,
                    }),
                );
            }
            yield put(loaderActionCreators.setSimilarJobLoader(false));
        }
    } catch (error) {
        yield put(loaderActionCreators.setSimilarJobLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLoadMoreSimilarJobList() {
    yield takeLatest(actionTypes.LOAD_MORE_SIMILAR_JOB_LIST, workerLoadMoreSimilarJobList);
}

/**
 * Other Job Saga
 */

export function* workerFetchOtherJobList(action: actionTypes.FetchOtherJobList) {
    try {
        yield put(loaderActionCreators.setOtherJobLoader(true));
        const companyName: string = action.payload;
        const endpoint = Utils.endpoints.otherJobs(companyName);
        const response = yield getCall(endpoint, '');
        // console.log(response);
        const { data, status } = response;
        if (status === 200) {
            if (data.data.jobs.length > 0) {
                const otherJobList = data.data.jobs;
                yield put(
                    actionCreators.setOtherJobs({
                        jobList: otherJobList,
                    }),
                );
            }
            yield put(loaderActionCreators.setOtherJobLoader(false));
        } else {
            yield put(miscellaneousActionCreators.setError({ msg: 'Response status code is not 200 OK.' }));
        }
    } catch (error) {
        yield put(loaderActionCreators.setOtherJobLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherFetchOtherJobList() {
    yield takeLatest(actionTypes.FETCH_OTHER_JOB_LIST, workerFetchOtherJobList);
}

const JobSagas = [
    fork(watcherfetchJobList),
    fork(watcherSetJobInfo),
    fork(watcherLoadMoreJobList),
    fork(watcherApplyJob),
    fork(watcherfetchAppliedJobList),
    fork(watcherLoadMoreAppliedJobList),
    fork(watcherfetchShortlistedJobList),
    fork(watcherLoadMoreShortlistedJobList),
    fork(watcherSetAppliedJobInfo),
    fork(watcherSetShortlistedJobInfo),
    fork(watcherApplyJobAfterLogin),
    fork(watcherApplyJobFromIndependent),
    fork(watcherReportJob),
    fork(watcherFetchSimilarJobList),
    fork(watcherLoadMoreSimilarJobList),
    fork(watcherFetchOtherJobList),
];

export default JobSagas;

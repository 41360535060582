import { put, takeEvery, fork, select } from 'redux-saga/effects';
import * as actionCreators from '../actions/miscellaneous';
import * as actionTypes from '../actionTypes/miscellaneous';
import { Error } from '../../utils/types';
import OfflineError from '../../utils/offlineError';
import history from '../../utils/history';
import { AppState } from '../reducers/rootReducer';
import Utils from '../../utils';
import { getCall } from '../../utils/services/network';

export function* workerRedirectOnJobDetailFilter(action: actionTypes.RedirectOnJobDetailFilter) {
    try {
        const { search, address, availability, category, radius } = action.payload;
        let url = `/search?`;
        if (search.trim().length !== 0) {
            url += `q=${search}`;
        }
        if (address.length !== 0 && search.length !== 0) {
            url += `&w=${address}`;
        } else {
            url += `w=${address}`;
        }
        if (category.length) yield put(actionCreators.setJobDetailCategoryFilter(category));
        if (availability) yield put(actionCreators.setJobDetailAvailabilityFilter(availability));
        if (radius) yield put(actionCreators.setJobDetailRadiusFilter(radius));
        history.push(url);
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        }
    }
}

function* watcherRedirectOnJobDetailFilter() {
    yield takeEvery(actionTypes.REDIRECT_ON_JOB_DETAIL_FILTER, workerRedirectOnJobDetailFilter);
}

export function* workerResetJobDetailFilter() {
    try {
        let miscellaneousState = yield select((state: AppState) => state.miscellaneous);
        miscellaneousState.jobDetailCategoryFilter = [];
        delete miscellaneousState.jobDetailAvailabilityFilter;
        delete miscellaneousState.jobDetailRadiusFilter;
        yield put(actionCreators.resetJobDetailFilterSuccess({ ...miscellaneousState }));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        }
    }
}

function* watcherResetJobDetailFilter() {
    yield takeEvery(actionTypes.RESET_JOB_DETAIL_FILTER, workerResetJobDetailFilter);
}

function* workerSetNotificationCount() {
    try {
        let endPoint = Utils.endpoints.getNotificationCount;
        const response = yield getCall(endPoint, '');
        console.log(response);
        if (response.data.code === 200) {
            yield put(actionCreators.setNotificationCountSuccess(response.data.data));
        }
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(actionCreators.setError(payload));
        }
    }
}

function* watcherSetNotificationCount() {
    yield takeEvery(actionTypes.SET_NOTIFICATION_COUNT, workerSetNotificationCount);
}

const miscellaneousSaga = [
    fork(watcherRedirectOnJobDetailFilter),
    fork(watcherResetJobDetailFilter),
    fork(watcherSetNotificationCount),
];

export default miscellaneousSaga;

import {
    OnBoardingType,
    SignUpType,
    ForgotPasswordPayloadType,
    WebContactsPayload,
    NewApplyJobPayload,
    GetAppNowPayload,
} from '../../utils/types';

export const SET_SHOW_ONBOARDING_MODAL = 'SET_SHOW_ONBOARDING_MODAL';
export interface SetShowOnBoardingModal {
    type: typeof SET_SHOW_ONBOARDING_MODAL;
    payload: OnBoardingType;
}

export const SIGN_UP = 'SIGN_UP';
export interface SignUp {
    type: typeof SIGN_UP;
    payload: SignUpType;
}

export const LOGIN = 'LOGIN';
export interface LogIn {
    type: typeof LOGIN;
    payload: Pick<SignUpType, 'email' | 'password'>;
}

export const LOGOUT = 'LOGOUT';
export interface LogOut {
    type: typeof LOGOUT;
}

export const LOGOUT_AFTER_DEACTIVATE = 'LOGOUT_AFTER_DEACTIVATE';
export interface LogOutAfterDeactivate {
    type: typeof LOGOUT_AFTER_DEACTIVATE;
}

export const FORGOT_PASSWORD_INITIATE = 'FORGOT_PASSWORD_INITIATE';
export interface ForgotPasswordInitiate {
    type: typeof FORGOT_PASSWORD_INITIATE;
    payload: string;
}

export const VERIFY_OTP = 'VERIFY_OTP';
export interface VerifyOtp {
    type: typeof VERIFY_OTP;
    payload: Pick<ForgotPasswordPayloadType, 'email' | 'otp'>;
}

export const RESET_PASSWORD = 'RESET_PASSWORD';
export interface ResetPassword {
    type: typeof RESET_PASSWORD;
    payload: Pick<ForgotPasswordPayloadType, 'email' | 'password'>;
}

export const WEB_CONTACTS = 'WEB_CONTACTS';
export interface WebContacts {
    type: typeof WEB_CONTACTS;
    payload: WebContactsPayload;
}

/** Apply Job with resume or no resume */
export const APPLY_JOB_NEW = 'APPLY_JOB_NEW';
export interface NewApplyJob {
    type: typeof APPLY_JOB_NEW;
    payload: NewApplyJobPayload;
}

export const GET_APP_NOW = 'GET_APP_NOW';
export interface GetAppNow {
    type: typeof GET_APP_NOW;
    payload: GetAppNowPayload;
}
export type types =
    | SetShowOnBoardingModal
    | SignUp
    | LogOut
    | LogIn
    | LogOutAfterDeactivate
    | ForgotPasswordInitiate
    | VerifyOtp
    | ResetPassword
    | WebContacts
    | NewApplyJob
    | GetAppNow;

import { put, takeEvery, fork, select } from 'redux-saga/effects';
import * as actionCreators from '../actions/settings';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import * as loaderActionCreators from '../actions/global-loader';
import * as actionTypes from '../actionTypes/settings';
import Utils from '../../utils';
import { getCall, postCall, putCall } from '../../utils/services/network';
import { Error, Success } from '../../utils/types';
import OfflineError from '../../utils/offlineError';
import { AppState } from '../reducers/rootReducer';
import { AccountDeactivatedModal } from '../../modals';

export function* workerSetFaqs() {
    try {
        let endPoint = 'https://app.jobget.com/' + Utils.endpoints.faq;

        const response = yield getCall(endPoint, '', {}, true);

        if (response.status === 200) yield put(actionCreators.setFaqsSuccess(response.data));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherSetFaqs() {
    yield takeEvery(actionTypes.SET_FAQS, workerSetFaqs);
}

export function* workerSetTerms() {
    try {
        let endPoint = 'https://app.jobget.com/' + Utils.endpoints.terms;
        const response = yield getCall(endPoint, '', {}, true);

        if (response.status === 200) yield put(actionCreators.setTermsSuccess(response.data));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherSetTerms() {
    yield takeEvery(actionTypes.SET_TERMS, workerSetTerms);
}

export function* workerSetPolicy() {
    try {
        let endPoint = 'https://app.jobget.com/' + Utils.endpoints.policy;
        const response = yield getCall(endPoint, '', {}, true);
        if (response.status === 200) yield put(actionCreators.setPolicySuccess(response.data));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherSetPolicy() {
    yield takeEvery(actionTypes.SET_POLICY, workerSetPolicy);
}

export function* workerChangePassword(action: actionTypes.ChangePassword) {
    try {
        yield put(loaderActionCreators.setSettingsLoader(true));
        const endPoint = Utils.endpoints.changePass;
        const payload = { ...action.payload };
        const response = yield postCall(endPoint, payload);

        if (response.data.code === 200) {
            yield put(loaderActionCreators.setSettingsLoader(false));
            let payload: Success = {
                msg: response.data.message,
            };
            yield put(miscellaneousActionCreators.setSuccess(payload));
        } else {
            yield put(loaderActionCreators.setSettingsLoader(false));
            let payload: Error = {
                msg: 'Something went wrong!',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    } catch (error) {
        yield put(loaderActionCreators.setSettingsLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherChangePassword() {
    yield takeEvery(actionTypes.CHANGE_PASSWORD, workerChangePassword);
}

export function* workerRateApp(action: actionTypes.RateApp) {
    try {
        yield put(loaderActionCreators.setSettingsLoader(true));
        let endPoint = Utils.endpoints.rateApp;
        const payload = { ...action.payload };
        const response = yield postCall(endPoint, payload);

        if (response.data.code === 200) {
            yield put(loaderActionCreators.setSettingsLoader(false));
            let payload: Success = {
                msg: response.data.message,
            };
            yield put(miscellaneousActionCreators.setSuccess(payload));
            if (action.payload.rating === 5) {
                window.open('https://play.google.com/store/apps/details?id=com.jobget', '_blank');
            }
        } else {
            yield put(loaderActionCreators.setSettingsLoader(false));
            let payload: Error = {
                msg: 'Something went wrong!',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    } catch (error) {
        yield put(loaderActionCreators.setSettingsLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherRateApp() {
    yield takeEvery(actionTypes.RATE_APP, workerRateApp);
}

export function* workerDeactivateAcc(action: actionTypes.DeactivateAccount) {
    try {
        yield put(loaderActionCreators.setSettingsLoader(true));
        let endPoint = Utils.endpoints.deActivateAccount;
        const payload = { ...action.payload };
        const response = yield putCall(endPoint, payload);

        if (response.data.code === 200) {
            yield put(loaderActionCreators.setSettingsLoader(false));
            const successPayload = new AccountDeactivatedModal();
            successPayload.state = true;
            yield put(miscellaneousActionCreators.AccountDeactivated(successPayload));
        } else {
            yield put(loaderActionCreators.setSettingsLoader(false));
            let payload: Error = {
                msg: 'Something went wrong!',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    } catch (error) {
        yield put(loaderActionCreators.setSettingsLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherDeactivateAcc() {
    yield takeEvery(actionTypes.DEACTIVATE_ACCOUNT, workerDeactivateAcc);
}

const settingsSagas = [
    fork(watcherSetFaqs),
    fork(watcherSetTerms),
    fork(watcherSetPolicy),
    fork(watcherChangePassword),
    fork(watcherRateApp),
    fork(watcherDeactivateAcc),
];

export default settingsSagas;

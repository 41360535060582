import * as types from '../actionTypes/global-loader';
import { GlobalLoader } from '../../utils/types';
import { GlobalLoaderInitial } from '../../modals';

const initialState: GlobalLoader = new GlobalLoaderInitial();

export default function GlobalLoaderReducer(state: GlobalLoader = initialState, action: types.types): GlobalLoader {
    switch (action.type) {
        case types.SET_ACTION_LOADER:
            return {
                ...state,
                actionLoading: action.payload,
            };

        case types.SET_NOTIFICATION_LOADER:
            return {
                ...state,
                notificationLoading: action.payload,
            };

        case types.SET_SIGNUP_LOGIN_LOADER:
            return {
                ...state,
                signUpLogInLoading: action.payload,
            };

        case types.SET_APPLY_JOB_LOADER:
            return {
                ...state,
                applyJobLoading: action.payload,
            };

        case types.SET_SETTINGS_LOADER:
            return {
                ...state,
                settingsLoading: action.payload,
            };

        case types.CONTACT_US_LOADER:
            return {
                ...state,
                contactUsLoading: action.payload,
            };

        case types.REVIEW_EMPLOYER_LOADER:
            return {
                ...state,
                reviewEmployerLoading: action.payload,
            };

        case types.SET_SIMILAR_JOB_LOADER:
            return {
                ...state,
                similarJobLoading: action.payload,
            };
        case types.SET_CHAT_MESSAGE_LOADER:
            return {
                ...state,
                chatMessageLoading: action.payload,
            };
        case types.SET_OTHER_JOB_LOADER:
            return {
                ...state,
                otherJobLoading: action.payload,
            };
        default:
            return state;
    }
}

import React, { memo, useState, useEffect } from 'react';
import Backdrop from '../../../components/backdrop';
import SignUpSignIn from '../component/sign-up-sign-in';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { setShowOnBoardingModal } from '../../../redux/actions/onBoarding';
import { OnBoardingType } from '../../../utils/types';
import { OnBoardingModal } from '../../../modals';
import Loader from '../../../components/loader';
import ForgotResetPassword from '../component/forgot-reset-password';

interface Props {
    signUpLogInLoading?: boolean;
    onBoardingModalName?: string;
    showOnBoardingModal?: boolean;
    setShowOnBoardingModal?: (param: OnBoardingType) => void;
}

const LoginSignUp = memo(function Login(props: Props) {
    function hideHandler() {
        props.setShowOnBoardingModal && props.setShowOnBoardingModal({ ...new OnBoardingModal() });
    }
    return (
        <>
            <Backdrop show={props.showOnBoardingModal || false} clickHandler={hideHandler} />
            <SignUpSignIn
                show={props.onBoardingModalName === 'login' || props.onBoardingModalName === 'signup' || false}
                showHandler={hideHandler}
                signUpFlag={props.onBoardingModalName === 'login' ? false : true}
            />
            <ForgotResetPassword
                show={props.onBoardingModalName === 'forgot' || props.onBoardingModalName === 'reset' || false}
                showHandler={hideHandler}
                forgotPasswordFlag={props.onBoardingModalName === 'reset' ? false : true}
            />
            <Loader show={props.signUpLogInLoading ? props.signUpLogInLoading : false} />
        </>
    );
});

function mapStateToProps(state: AppState) {
    const { onBoardingModalName, showOnBoardingModal } = state.onBoarding;
    const { signUpLogInLoading } = state.globalLoaders;
    return {
        onBoardingModalName,
        showOnBoardingModal,
        signUpLogInLoading,
    };
}
function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({ setShowOnBoardingModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignUp);

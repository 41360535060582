import {
    FetchInboxType,
    FetchMessageType,
    MessageStateType,
    SendMessageStateType,
    InboxStateType,
} from '../../utils/types';

export const FETCH_INBOX = 'FETCH_INBOX';
export const SET_INBOX = 'SET_INBOX';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_INITIAL_MESSAGE = 'FETCH_INITIAL_MESSAGE';
export const UPDATE_INBOX = 'UPDATE_INBOX';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_TYPING = 'SET_TYPING';
export const GET_TYPING = 'GET_TYPING';
export const GET_BADGE_COUNT = 'GET_BADGE_COUNT';
export const SET_BADGE_COUNT = 'SET_BADGE_COUNT';
export interface FetchInboxList {
    type: typeof FETCH_INBOX;
    payload: FetchInboxType;
}
export interface SetInboxList {
    type: typeof SET_INBOX;
    payload: InboxStateType;
}
export interface UpdateInbox {
    type: typeof UPDATE_INBOX;
    payload: { inbox: Array<any> };
}
export interface FetchMessage {
    type: typeof FETCH_MESSAGE;
    payload: FetchMessageType;
}
export interface FetchInitialMessage {
    type: typeof FETCH_INITIAL_MESSAGE;
    payload: FetchMessageType;
}
export interface SetMessage {
    type: typeof SET_MESSAGE;
    payload: MessageStateType;
}
export interface SendMessage {
    type: typeof SEND_MESSAGE;
    payload: SendMessageStateType;
}

export interface AddMessage {
    type: typeof ADD_MESSAGE;
    payload: { message: Array<any> };
}
export interface SetTyping {
    type: typeof SET_TYPING;
    payload: { typingStatus: boolean };
}
export interface GetTyping {
    type: typeof GET_TYPING;
    payload: { typingStatus: boolean };
}
export interface GetBadgeCount {
    type: typeof GET_BADGE_COUNT;
}

export interface SetBadgeCount {
    type: typeof SET_BADGE_COUNT;
    payload: { badgeCount: number };
}

export type types =
    | FetchInboxList
    | SetInboxList
    | FetchMessage
    | SetMessage
    | SendMessage
    | AddMessage
    | UpdateInbox
    | SetTyping
    | GetTyping
    | GetBadgeCount
    | SetBadgeCount
    | FetchInitialMessage;

import React from 'react';
import './index.scss';

interface Props {
    show: boolean;
    clickHandler: () => any;
}
function Backdrop(props: Props): any {
    return (
        <>
            {props.show ? (
                <div className="backdrop" data-test="backdrop" onClick={() => props.clickHandler()}></div>
            ) : null}
        </>
    );
}

export default Backdrop;

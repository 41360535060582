import * as types from '../actionTypes/profile';
import { ProfileDetails } from '../../utils/types';
import { ProfileDetailsInitial } from '../../modals';

const initialState: ProfileDetails = new ProfileDetailsInitial();

export default function profileReducer(state: ProfileDetails = initialState, action: types.types): ProfileDetails {
    switch (action.type) {
        case types.ADD_PROFILE_INFO_SUCCESS:
            return { ...state, ...action.payload };

        case types.EDIT_PROFILE_INFO_SUCCESS:
            return { ...state, ...action.payload };

        case types.SET_PROFILE_INFO_AFTER_ONBOARDING:
            return { ...state, ...action.payload };

        case types.SET_PROFILE_ON_REFRESH_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

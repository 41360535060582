import * as types from '../actionTypes/global-loader';

export function setActionLoader(payload: boolean): types.SetActionLoader {
    return {
        type: types.SET_ACTION_LOADER,
        payload,
    };
}

export function setNotificationLoader(payload: boolean): types.SetNotificationLoader {
    return {
        type: types.SET_NOTIFICATION_LOADER,
        payload,
    };
}

export function setSignUpLogInLoader(payload: boolean): types.SetSignUpLogInLoader {
    return {
        type: types.SET_SIGNUP_LOGIN_LOADER,
        payload,
    };
}

export function setApplyJobLoader(payload: boolean): types.SetApplyJobLoader {
    return {
        type: types.SET_APPLY_JOB_LOADER,
        payload,
    };
}

export function setSettingsLoader(payload: boolean): types.SetSettingsLoader {
    return {
        type: types.SET_SETTINGS_LOADER,
        payload,
    };
}

export function setContactUsLoader(payload: boolean): types.ContactUsLoader {
    return {
        type: types.CONTACT_US_LOADER,
        payload,
    };
}

export function setreviewEmployerLoader(payload: boolean): types.ReviewEmployerLoader {
    return {
        type: types.REVIEW_EMPLOYER_LOADER,
        payload,
    };
}

export function setSimilarJobLoader(payload: boolean): types.SetSimilarJobLoader {
    return {
        type: types.SET_SIMILAR_JOB_LOADER,
        payload,
    };
}

export function setChatMessageLoader(payload: boolean): types.SetChatMessageLoader {
    return {
        type: types.SET_CHAT_MESSAGE_LOADER,
        payload,
    };
}

export function setOtherJobLoader(payload: boolean): types.SetOtherJobLoader {
    return {
        type: types.SET_OTHER_JOB_LOADER,
        payload,
    };
}
import { NotificationState, DeleteNotificationPayload } from '../../utils/types';

export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST';
export interface FetchNotificationList {
    type: typeof FETCH_NOTIFICATION_LIST;
}

export const LOAD_MORE_NOTIFICATION_LIST = 'LOAD_MORE_NOTIFICATION_LIST';
export interface LoadMoreNotificationList {
    type: typeof LOAD_MORE_NOTIFICATION_LIST;
}

export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export interface SetNotificationList {
    type: typeof SET_NOTIFICATION_LIST;
    payload: NotificationState;
}

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export interface DeleteNotification {
    type: typeof DELETE_NOTIFICATION;
    payload: DeleteNotificationPayload;
}

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export interface ClearNotification {
    type: typeof CLEAR_NOTIFICATION;
}

export type types =
    | FetchNotificationList
    | LoadMoreNotificationList
    | SetNotificationList
    | DeleteNotification
    | ClearNotification;

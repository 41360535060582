import { all } from 'redux-saga/effects';
import MasterDataSagas from './masterDataSaga';
import ProfileSagas from './profileSaga';
import SettingsSagas from './settingsSaga';
import JobSagas from './jobSaga';
import ChatSagas from './chatSaga';
import NotificationSagas from './notificationSage';
import OnBoardingSagas from './onBoardingSaga';
import MiscellaneousSaga from './miscellaneousSaga';

export default function* rootSaga() {
    yield all([
        ...MasterDataSagas,
        ...SettingsSagas,
        ...ProfileSagas,
        ...JobSagas,
        ...ChatSagas,
        ...NotificationSagas,
        ...OnBoardingSagas,
        ...MiscellaneousSaga
    ]);
}

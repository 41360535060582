import React from 'react';
import classes from './index.module.scss';

interface Props {
    show: boolean;
    loaderStyle?: string;
    backdropStyle?: string;
}
const loader = (props: Props): any =>
    props.show ? (
        <div data-test="parent-wrapper">
            <div
                className={`${classes['backdrop']} ${props.backdropStyle ? props.backdropStyle : ''}`}
                data-test="backdrop"
            ></div>
            <div
                className={`${classes['loader-wrapper']} ${props.loaderStyle ? props.loaderStyle : ''}`}
                data-test="loader"
            ></div>
        </div>
    ) : null;

export default loader;

import React, { memo, ChangeEvent } from 'react';
import './custom-input.scss';

interface Props {
    id?: string;
    type: string;
    value?: string;
    imageSrc?: string;
    disable?: boolean;
    minLength?: number;
    maxLength?: number;
    imageStyle?: string;
    styleClass?: string;
    placeHolder?: string;
    imageRequired?: boolean;
    parentStyleClass?: string;
    onBlurHandler?: () => void;
    onSubmitHandler?: () => void;
    imageClickHandler?: () => void;
    onClickHandler?: () => void;
    onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default memo(function CustomDropdown(props: Props) {
    const onEnterPress = (event: any) => {
        if (event.key === 'Enter' && props.onSubmitHandler) {
            props.onSubmitHandler();
        }
    };

    const imageHandler = () => {
        if (props.imageClickHandler) {
            props.imageClickHandler();
        }
    };

    return (
        <div className={`custom-input-wrapper ${props.parentStyleClass ? props.parentStyleClass : ''}`}>
            {props.imageRequired && (
                <img
                    src={props.imageSrc ? require(`../../assets/images/${props.imageSrc}`) : ''}
                    className={props.imageStyle}
                    onClick={imageHandler}
                    alt="caption"
                    data-test="inserted-image"
                />
            )}
            <input
                minLength={props?.minLength}
                maxLength={props?.maxLength}
                type={props.type}
                className={`custom-input ${props.styleClass}`}
                data-test="custom-input"
                onChange={props.onChangeHandler}
                onBlur={props.onBlurHandler}
                disabled={props.disable ? props.disable : false}
                placeholder={props.placeHolder}
                onKeyDown={onEnterPress}
                id={props.id ? props.id : ''}
                value={props.value}
                onClick={props.onClickHandler}
                aria-label="Input a value"
            />
        </div>
    );
});

import React, { memo, useEffect, useState } from 'react';
import { AppState } from '../../redux/reducers/rootReducer';
import { connect } from 'react-redux';
import classes from './index.module.scss';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { setSuccess } from '../../redux/actions/miscellaneous';
import { Success } from '../../utils/types';

interface Props {
    store?: any;
    success?: Success;
    setSuccess?: (param: Success) => void;
}

const SuccessToast = memo(function SuccessToast(props: Props) {
    const [successMsg, setSuccessMsg] = useState<string>('');
    let timeOut: ReturnType<typeof setTimeout>;
    useEffect(() => {
        if (props.success?.msg) {
            setSuccessMsg(props.success.msg);
            timeOut = setTimeout(() => {
                closeHandler();
            }, 4000);
        }
        return () => {
            if (timeOut !== undefined) {
                clearTimeout(timeOut);
            }
        };
    }, [props.success?.msg]);

    function closeHandler() {
        if (timeOut !== undefined) {
            clearTimeout(timeOut);
        }
        let payload: Success = {
            msg: '',
        };
        props.setSuccess && props.setSuccess(payload);
    }

    return (
        <div
            className={classes['success-wrapper']}
            style={props.success?.msg ? { top: '8px' } : {}}
            data-test="success-wrapper"
        >
            {/* <div className={classes['close']} onClick={closeHandler} data-test="close"></div> */}
            {successMsg}
        </div>
    );
});

function mapStateToProps(state: AppState) {
    const { success } = state.miscellaneous;
    return {
        success,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({ setSuccess }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessToast);

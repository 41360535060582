import * as types from '../actionTypes/master-data';
import { CategoryList } from '../../utils/types';

export function setCategoryList(): types.SetCategoryList {
    return {
        type: types.SET_CATEGORY_LIST,
    };
}

export function setCategoryListSuccess(payload: CategoryList[]): types.SetCategoryListSuccess {
    return {
        type: types.SET_CATEGORY_LIST_SUCCESS,
        payload,
    };
}

import { put, takeEvery, fork, select, take, call, delay, takeLatest, cancel } from 'redux-saga/effects';
import * as actionCreators from '../actions/profile';
import * as loaderActionCreators from '../actions/global-loader';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import * as jobActionCreators from '../actions/job';
import * as actionTypes from '../actionTypes/profile';
import Utils from '../../utils';
import { getCall, postCall } from '../../utils/services/network';
import { setBannerSession } from '../../utils/services/sessions';
import { ProfileDetails, Error, Success } from '../../utils/types';
import OfflineError from '../../utils/offlineError';
import { AppState } from '../reducers/rootReducer';
import history from '../../utils/history';
import { Task } from 'redux-saga';

export function* workerAddProfileInfo(action: actionTypes.AddProfileInfo) {
    try {
        yield put(loaderActionCreators.setActionLoader(true));
        const { about, education, isUnderAge, jobType, experience, user_image } = action.payload;
        const { lat, lng, email } = yield select((state: AppState) => state.profileDetails);
        const payload = {
            userImage: user_image,
            education: education,
            about: about,
            is_profile_added: 1,
            experience: experience,
            isExperience: experience ? (experience.length > 0 ? 2 : 1) : 1,
            // durationType: 0,
            // city : city,
            // state : state,
            // country : country,
            // address : fullAddress,
            latitude: lat,
            longitude: lng,
            isUnderAge: isUnderAge,
            activeSearch: 1,
            isSearch: 1,
            jobType,
        };
        const endPoint = Utils.endpoints.addProfile;
        const response = yield postCall(endPoint, payload);

        if (response.status) {
            localStorage.setItem('is_profile_added', '1');
            const { jobAppliedWhenLoggedOutPayload, previousLocation } = yield select(
                (state: AppState) => state.miscellaneous,
            );

            if (
                jobAppliedWhenLoggedOutPayload._id &&
                jobAppliedWhenLoggedOutPayload.recruiterId &&
                jobAppliedWhenLoggedOutPayload.categoryId
            ) {
                yield put(jobActionCreators.applyJobAfterLogin());
            }
            // FacebookEvent.logCompleteProfileEvent(email);
            yield put(actionCreators.addProfileInfoSuccess(response.data.data));
            yield put(loaderActionCreators.setActionLoader(false));
            if (previousLocation) {
                history.replace(previousLocation);
            } else {
                history.replace('/');
            }
            const payload: Success = {
                msg: 'Profile Details added successfully!',
            };
            yield put(miscellaneousActionCreators.setSuccess(payload));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherAddProfileInfo() {
    yield takeLatest(actionTypes.ADD_PROFILE_INFO, workerAddProfileInfo);
}

export function* workerEditProfileInfo(action: actionTypes.EditProfileInfo) {
    try {
        yield put(loaderActionCreators.setActionLoader(true));
        const { _id } = yield select((state: AppState) => state.profileDetails);
        const endPoint = Utils.endpoints.addProfile;
        const data: any = action.payload;
        data['latitude'] = data.lat;
        data['longitude'] = data.lng;

        data['activeSearch'] = action.payload.isSearch;

        const response = yield postCall(endPoint, data);

        if (response.status) {
            // Utils.constant.addressFromLatLong(data.latitude, data.longitude, setCurrentAddress);
            // Utils.constant.defaultLat = data.latitude;
            // Utils.constant.defaultLng = data.longitude;
            // dispatch({ type: Utils.ActionName.UPDATE_CURRENT_ADDRESS, data: { latitude:  data.latitude, longitude:  data.longitude} });
            // getUserData(Utils.constant.userId);
            // data['_id'] = _id;
            // FirebaseService.updateUserProfile(data);
            let responseData: ProfileDetails = response.data.data;
            console.log(responseData);
            if (responseData.address.indexOf(responseData.city) === -1) responseData.city = '';
            responseData.isLogin = true;
            Utils.constant.userId = responseData._id;
            Utils.constant.isLogin = true;
            Utils.constant.is_profile_added = responseData.is_profile_added;
            Utils.constant.defaultLat = responseData.lat;
            Utils.constant.defaultLng = responseData.lng;
            localStorage.setItem('latitude', responseData.lat.toString());
            localStorage.setItem('longitude', responseData.lng.toString());
            yield put(actionCreators.editProfileInfoSuccess(responseData));
            setBannerSession(data.isMobileAppUsed == 1 ? true : false);
            yield put(loaderActionCreators.setActionLoader(false));
            const payload: Success = {
                msg: 'Profile Details updated successfully!',
            };
            history.replace('/profile');
            yield put(miscellaneousActionCreators.setSuccess(payload));
        }
        yield put(loaderActionCreators.setActionLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setActionLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherEditProfileInfo() {
    yield takeLatest(actionTypes.EDIT_PROFILE_INFO, workerEditProfileInfo);
}

export function* workerSetProfileOnRefresh() {
    try {
        yield put(miscellaneousActionCreators.setRefreshFlag(true));
        const endPoint = Utils.endpoints.userInfo;
        const response = yield getCall(endPoint, '');

        console.log('refresh', response);
        if (response && response.status === 200) {
            let data: ProfileDetails = response.data.data;
            if (data.address && data.address.indexOf(data.city) === -1) data.city = '';
            data.isLogin = true;
            Utils.constant.userId = data._id;
            Utils.constant.isLogin = true;
            Utils.constant.userName = data.full_name;
            Utils.constant.is_profile_added = data.is_profile_added;
            Utils.constant.defaultLat = data.lat;
            Utils.constant.defaultLng = data.lng;
            localStorage.setItem('latitude', data.lat.toString());
            localStorage.setItem('longitude', data.lng.toString());
            sessionStorage.removeItem('last_searched_address');
            yield put(actionCreators.setProfileOnRefreshSuccess(data));
            setBannerSession(data.isMobileAppUsed == 1 ? true : false);
        }
        yield put(miscellaneousActionCreators.setRefreshFlag(false));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(miscellaneousActionCreators.setRefreshFlag(false));
        console.log(error.response);
    }
}

function* watcherSetProfileOnRefresh() {
    yield takeEvery(actionTypes.SET_PROFILE_ON_REFRESH, workerSetProfileOnRefresh);
}

export function* workerContactUs(action: actionTypes.ContactUs) {
    try {
        yield put(loaderActionCreators.setContactUsLoader(true));
        const endPoint = Utils.endpoints.contactUs;
        const payload = { ...action.payload };
        const response = yield postCall(endPoint, payload);
        yield put(loaderActionCreators.setContactUsLoader(false));
        if (response.status === 200) {
            yield put(miscellaneousActionCreators.ContactUsSuccess(true));
        } else {
            const payload: Error = {
                msg: 'Something ent wrong!',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response && error.response.data.message) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(loaderActionCreators.setContactUsLoader(false));
    }
}

function* watcherContactUs() {
    yield takeLatest(actionTypes.CONTACT_US, workerContactUs);
}

function* workerReviewEmployer(action: actionTypes.ReviewEmployer) {
    try {
        yield put(loaderActionCreators.setreviewEmployerLoader(true));
        const endPoint = Utils.endpoints.review;
        const payload = { ...action.payload, type: 1 };
        const response = yield postCall(endPoint, payload);
        yield put(loaderActionCreators.setreviewEmployerLoader(false));
        if (response.status === 200) {
            yield put(miscellaneousActionCreators.setReviewEmpSuccess(false));
            yield put(miscellaneousActionCreators.setSuccess({ msg: 'Review Successful!' }));
        } else {
            const payload: Error = {
                msg: 'Something went wrong!',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response && error.response.data.message) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(loaderActionCreators.setreviewEmployerLoader(false));
    }
}

function* watcherReviewEmployer() {
    yield takeLatest(actionTypes.REVIEW_EMPLOYER, workerReviewEmployer);
}

const masterDataSagas = [
    fork(watcherAddProfileInfo),
    fork(watcherEditProfileInfo),
    fork(watcherSetProfileOnRefresh),
    fork(watcherContactUs),
    fork(watcherReviewEmployer),
];

export default masterDataSagas;

import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import Routes from './routes';
import FacebookEvent from './utils/services/facebookEvent';

const App: React.FC = () => {
    return (
        <Provider store={store()}>
            <Routes />
            {(function () {
                setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    FacebookEvent.initialize(window.FB_OBJ);
                }, 1000);
            })()}
        </Provider>
    );
};

export default App;

import * as types from '../actionTypes/onBoarding';
import {
    OnBoardingType,
    SignUpType,
    ForgotPasswordPayloadType,
    WebContactsPayload,
    NewApplyJobPayload,
    GetAppNowPayload,
} from '../../utils/types';

export function setShowOnBoardingModal(payload: OnBoardingType): types.SetShowOnBoardingModal {
    return {
        type: types.SET_SHOW_ONBOARDING_MODAL,
        payload,
    };
}

export function signUp(payload: SignUpType): types.SignUp {
    return {
        type: types.SIGN_UP,
        payload,
    };
}

export function logIn(payload: Pick<SignUpType, 'email' | 'password'>): types.LogIn {
    return {
        type: types.LOGIN,
        payload,
    };
}

export function logOut(): types.LogOut {
    return {
        type: types.LOGOUT,
    };
}

export function logOutAfterDeactivate(): types.LogOutAfterDeactivate {
    return {
        type: types.LOGOUT_AFTER_DEACTIVATE,
    };
}

export function forgotPasswordInitiate(payload: string): types.ForgotPasswordInitiate {
    return {
        type: types.FORGOT_PASSWORD_INITIATE,
        payload,
    };
}

export function verifyOtp(payload: Pick<ForgotPasswordPayloadType, 'email' | 'otp'>): types.VerifyOtp {
    return {
        type: types.VERIFY_OTP,
        payload,
    };
}

export function resetPassword(payload: Pick<ForgotPasswordPayloadType, 'email' | 'password'>): types.ResetPassword {
    return {
        type: types.RESET_PASSWORD,
        payload,
    };
}

export function webContacts(payload: WebContactsPayload): types.WebContacts {
    return {
        type: types.WEB_CONTACTS,
        payload,
    };
}

export function newJobApply(payload: NewApplyJobPayload): types.NewApplyJob {
    return {
        type: types.APPLY_JOB_NEW,
        payload,
    };
}

export function getAppNow(payload: GetAppNowPayload): types.GetAppNow {
    return {
        type: types.GET_APP_NOW,
        payload,
    };
}

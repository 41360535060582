import firebase from 'firebase';

const firebaseDevConfig = {
    apiKey: 'AIzaSyBMQBJinGQZz2ZCQDvZ2AQ2J0Yvp-shuu0',
    authDomain: 'jobget-dev-v1.firebaseapp.com',
    databaseURL: 'https://jobget-dev-v1.firebaseio.com',
    projectId: 'jobget-dev-v1',
    storageBucket: 'jobget-dev-v1.appspot.com',
    messagingSenderId: '508652316521',
    appId: '1:508652316521:web:47890109b2a496aeedbff8',
};
const firebaseProdConfig = {
    apiKey: 'AIzaSyAQNFMDTsVCHjgop1kjAyjqTKh1TpDpqZI',
    authDomain: 'jobget-5537a.firebaseapp.com',
    databaseURL: 'https://jobget-5537a.firebaseio.com',
    projectId: 'jobget-5537a',
    storageBucket: 'jobget-5537a.appspot.com',
    messagingSenderId: '835497019871',
    appId: '1:835497019871:web:a4829a907d3caf998d1c6a',
};
const config =
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' || process.env.REACT_APP_CUSTOM_NODE_ENV === 'live'
        ? firebaseProdConfig
        : firebaseDevConfig;
export const initializeFirebase = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }
};

export const sendPushNotification = async (payload: any) => {
    const FIREBASE_API_KEY =
        process.env.REACT_APP_CUSTOM_NODE_ENV === 'production' || process.env.REACT_APP_CUSTOM_NODE_ENV === 'live'
            ? 'AIzaSyCqAzwTVKv8_HPKLBaZz77C-J2kV_5SG70'
            : 'AIzaSyCnkq8hJfnKs3-w04MnFHrslGJa-RTgKHs';
    console.log('PUSH', payload);
    payload.to = payload.to.split(',');
    payload.to = payload.to?.pop();

    const message = payload;
    const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'key=' + FIREBASE_API_KEY,
    });

    let response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers,
        body: JSON.stringify(message),
    });
    response = await response.json();
    console.log('PUSH', message);
    console.log('PUSH', response);
};

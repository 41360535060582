import { getCall } from './network';
import axios from 'axios';

export function getJobFromWebLoadMoreAsync(
    searchKey: string,
    locationCity: string,
    locationState: string,
    thirdPartyNext: boolean,
    thirdPartyPagePassed: number,
    categoryId: string,
) {
    const thirdPartyPage = thirdPartyPagePassed;
    console.log(thirdPartyPage);
    const endPoint = `https://jobs-v2.jobget.com/jobs?search=${searchKey}&location=${locationCity},${locationState}&page=${thirdPartyPage}`;
    return getCall(endPoint, '');
}

export function getJobFromWebIntialAsync(
    searchKey: string,
    locationCity: string,
    locationState: string,
    thirdPartyNext: boolean,
    thirdPartyPagePassed: number,
    categoryId: string,
) {
    const thirdPartyPage = 0;
    const endPoint = `https://jobs-v2.jobget.com/jobs?search=${searchKey}&location=${locationCity},${locationState}&page=${thirdPartyPage}`;
    return getCall(endPoint, '');
}

export function sendMessageToWebContacts(payload: any) {
    const endPoint = `https://sms.jobget.com/webApplySms`;
    return axios.post(endPoint, payload);
}

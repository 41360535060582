import React, { memo, useRef, useEffect } from 'react';
import history from '../../../utils/history';
import classes from './index.module.scss';

interface Props {
    setBlurCheck: () => void;
    logOutHandler: () => void;
    setShowProfilePane: () => void;
}

export default memo(function ProfileOptionsPane(props: Props) {
    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        parentRef.current?.focus();
    }, []);

    function clickHandler(param: string) {
        if (param === 'logout') {
            props.logOutHandler();
        } else {
            history.push(`/profile${param ? `/${param}` : ''}`);
        }
        props.setShowProfilePane();
        props.setBlurCheck();
    }

    return (
        <div
            className={classes['profile-pane-parent-wrapper']}
            data-test="profile-pane-parent-wrapper"
            onBlur={() => {
                props.setShowProfilePane();
                props.setBlurCheck();
            }}
            ref={parentRef}
            tabIndex={0}
        >
            <div className={classes['contents']} data-test="contents">
                <div className={classes['individual']} onClick={() => clickHandler('')} data-test="profile">
                    Profile
                </div>
                <div className={classes['individual']} onClick={() => clickHandler('settings')} data-test="settings">
                    Settings
                </div>
                <div className={classes['individual']} onClick={() => clickHandler('logout')} data-test="logout">
                    Logout
                </div>
            </div>
        </div>
    );
});

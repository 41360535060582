import * as types from '../actionTypes/notification';
import { NotificationState, DeleteNotificationPayload } from '../../utils/types';

export function fetchNotificationList(): types.FetchNotificationList {
    return {
        type: types.FETCH_NOTIFICATION_LIST,
    };
}

export function loadMoreNotificationList(): types.LoadMoreNotificationList {
    return {
        type: types.LOAD_MORE_NOTIFICATION_LIST,
    };
}

export function setNotificationList(payload: NotificationState): types.SetNotificationList {
    return {
        type: types.SET_NOTIFICATION_LIST,
        payload,
    };
}

export function deleteNotification(payload: DeleteNotificationPayload): types.DeleteNotification {
    return {
        type: types.DELETE_NOTIFICATION,
        payload,
    };
}

export function clearNotification(): types.ClearNotification {
    return {
        type: types.CLEAR_NOTIFICATION,
    };
}

import React, { memo, useRef, useEffect } from 'react';
import classes from './index.module.scss';
import { NotificationData, DeleteNotificationPayload } from '../../../utils/types';
import InfiniteScroll from 'react-infinite-scroller';
import history from '../../../utils/history';

interface Props {
    next: boolean;
    setBlurCheck: () => void;
    notificationLoading: boolean;
    clearNotification: () => void;
    setShowNotificationPane: () => void;
    loadMoreNotificationList: () => void;
    notifications: Array<NotificationData>;
    deleteNotification: (payload: DeleteNotificationPayload) => void;
}

export default memo(function NotificationPane(props: Props) {
    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        parentRef.current?.focus();
    }, []);

    function deleteNotification(notificationId: string, index: number) {
        const payload = {
            notificationId,
            index,
        };
        props.deleteNotification && props.deleteNotification(payload);
    }

    function clearNotification() {
        props.clearNotification();
        props.setShowNotificationPane();
        props.setBlurCheck();
    }

    function redirectToJob(_id: string) {
        if (_id) history.push('/job/' + _id);
        props.setBlurCheck();
        props.setShowNotificationPane();
    }

    return (
        <div
            className={`${classes['notifications-parent-wrapper']} custom-child-scrollbar-global`}
            data-test="notifications-parent-wrapper"
            onBlur={() => {
                props.setShowNotificationPane();
                props.setBlurCheck();
            }}
            ref={parentRef}
            tabIndex={0}
        >
            <div className={classes['notifications-list-wrapper']} data-test="notifications-list-wrapper">
                {props.notifications && props.notifications.length > 0 ? (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={() => {
                            if (props.next) {
                                props.loadMoreNotificationList && props.loadMoreNotificationList();
                            }
                        }}
                        hasMore={props.next}
                        loader={
                            <div className="row justify-content-center" style={{ marginTop: 10 }} key={0}>
                                Loading ...
                            </div>
                        }
                    >
                        <div className={classes['clear']} onClick={clearNotification}>
                            CLEAR ALL
                        </div>
                        {props.notifications.map((ele: NotificationData, index: number) => (
                            <div
                                key={index}
                                className={classes['notification-individual']}
                                data-test="notification-individual"
                            >
                                <img
                                    src={'https://jobget-static.s3.amazonaws.com/new/close3x.svg'}
                                    onClick={() => deleteNotification(ele._id, index)}
                                    alt="close image"
                                />
                                Awesome news! You were shortlisted {ele.jobTitle ? `to` : 'for'}{' '}
                                <span onClick={() => redirectToJob(ele.moduleId)}>
                                    {ele.jobTitle ? ele.jobTitle : ele.companyName}
                                </span>
                            </div>
                        ))}
                    </InfiniteScroll>
                ) : (
                    <span className={classes['no-record']} data-test="no-found">
                        No Notifications Found
                    </span>
                )}
            </div>
            <div className={classes['hide-section']} data-test="hide-section"></div>
        </div>
    );
});

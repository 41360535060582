import * as types from '../actionTypes/miscellaneous';
import {
    Error,
    Success,
    JobModalType,
    AccountDeactivatedType,
    JobFilterPayload,
    MiscellaneousState,
} from '../../utils/types';
import { CustomDropdownList } from '../../modals';

export function setSideDrawerShow(payload: boolean): types.SetSideDrawerShow {
    return {
        type: types.SET_SIDE_DRAWER_SHOW,
        payload,
    };
}

export function setPreviousLocation(payload: string): types.SetPreviousLocation {
    return {
        type: types.SET_PREVIOUS_LOCATION,
        payload,
    };
}

export function setError(payload: Error): types.SetError {
    return {
        type: types.SET_ERROR,
        payload,
    };
}

export function setJobAppliedModal(payload: boolean): types.SetJobAppliedModal {
    return {
        type: types.SET_JOB_APPLIED_MODAL,
        payload,
    };
}

export function setRefreshFlag(payload: boolean): types.SetRefreshFlag {
    return {
        type: types.SET_REFRESH_FLAG,
        payload,
    };
}

export function setSuccess(payload: Success): types.SetSuccess {
    return {
        type: types.SET_SUCCESS,
        payload,
    };
}

export function SetJobAppliedWhenLoggedOut(
    payload: Pick<JobModalType, '_id' | 'categoryId' | 'recruiterId'>,
): types.SetJobAppliedWhenLoggedOut {
    return {
        type: types.SET_JOB_APPLIED_WHEN_LOGGED_OUT,
        payload,
    };
}

export function UnsetJobAppliedWhenLoggedOut(): types.UnsetJobAppliedWhenLoggedOut {
    return {
        type: types.UNSET_JOB_APPLIED_WHEN_LOGGED_OUT,
    };
}

export function AccountDeactivated(payload: AccountDeactivatedType): types.AccountDeactivated {
    return {
        type: types.ACCOUNT_DEACTIVATED,
        payload,
    };
}

export function ContactUsSuccess(payload: boolean): types.ContactUsSuccess {
    return {
        type: types.CONTACT_US_SUCCESS,
        payload,
    };
}

export function SetPasswordResetToken(payload: string): types.SetPasswordResetToken {
    return {
        type: types.SET_PASSWORD_RESET_TOKEN,
        payload,
    };
}

export function setReviewEmpSuccess(payload: boolean): types.ReviewEmpSuccess {
    return {
        type: types.REVIEW_EMP_SUCCESS,
        payload,
    };
}

export function setAppliedJobRecruiterId(payload: string): types.SetAppliedJobRecruiterId {
    return {
        type: types.SET_APPLIED_JOB_RECRUITER_ID,
        payload,
    };
}

export function setJobDetailCategoryFilter(payload: Array<CustomDropdownList>): types.SetJobDetailCategoryFilter {
    return {
        type: types.SET_JOB_DETAIL_CATEGORY_FILTER,
        payload,
    };
}

export function setJobDetailAvailabilityFilter(payload: CustomDropdownList): types.SetJobDetailAvailabilityFilter {
    return {
        type: types.SET_JOB_DETAIL_AVAILABILITY_FILTER,
        payload,
    };
}

export function setJobDetailRadiusFilter(payload: CustomDropdownList): types.SetJobDetailRadiusFilter {
    return {
        type: types.SET_JOB_DETAIL_RADIUS_FILTER,
        payload,
    };
}

export function redirectOnJobFilter(payload: JobFilterPayload): types.RedirectOnJobDetailFilter {
    return {
        type: types.REDIRECT_ON_JOB_DETAIL_FILTER,
        payload,
    };
}

export function resetJobDetailFilter(): types.ResetJobDetailFilter {
    return {
        type: types.RESET_JOB_DETAIL_FILTER,
    };
}

export function resetJobDetailFilterSuccess(payload: MiscellaneousState): types.ResetJobDetailFilterSuccess {
    return {
        type: types.RESET_JOB_DETAIL_FILTER_SUCCESS,
        payload,
    };
}

export function setNotificationCount(): types.SetNotificationCount {
    return {
        type: types.SET_NOTIFICATION_COUNT,
    };
}

export function setNotificationCountSuccess(payload: number): types.SetNotificationCountSuccess {
    return {
        type: types.SET_NOTIFICATION_COUNT_SUCCESS,
        payload,
    };
}

export function setAppliedRecruiterDetailId(payload: string): types.SetAppliedRecruiterId {
    return {
        type: types.APPLIED_RECRUITER_DETAIL_ID,
        payload,
    };
}

export function setWebContactsConfirmation(payload: boolean): types.WebContactsConfirmation {
    return {
        type: types.WEB_CONTACTS_CONFIRMATION,
        payload,
    };
}

export function setDownloadLinkSendConfirmation(payload: boolean): types.DownloadLinkSendConfirmation {
    return {
        type: types.DOWNLOAD_LINK_SEND_CONFIRMATION,
        payload,
    };
}

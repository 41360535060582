import React, { memo } from 'react';
import classes from './banner-download.module.scss';

interface Props {
    code: string;
    mobile: string;
    buttonEnable: boolean;
    successMessage: string;
    getMobile: (value: string) => void;
    sendDownload: (value: number) => void;
    toggleCountryCode: () => void;
}

export default memo(function BannerDownload(props: Props) {
    return (
        <>
            <div className={classes['parent-wrapper']}>
                <div className={classes['heading']}>Download the JobGet App</div>
                {props.successMessage ? (
                    <p className={classes['download-success']}>Hooray! Download link has been sent to your phone</p>
                ) : (
                    <>
                        <div className={classes['field-container']}>
                            <div
                                className={classes[`banner-field-input-code`]}
                                onClick={() => props.toggleCountryCode()}
                            >
                                <img
                                    src={'https://jobget-static.s3.amazonaws.com/ic-phone.svg'}
                                    className={classes['input-icon']}
                                    alt="country-code"
                                />
                                <span className="text-center w-100">{props.code}</span>
                            </div>
                            <input
                                className={classes[`banner-field-input`]}
                                value={props.mobile}
                                onChange={(e) => props.getMobile(e.target.value)}
                                placeholder={'Enter your mobile number'}
                                type={'text'}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter') {
                                        props.sendDownload(1);
                                    }
                                }}
                                aria-label="Input a value for mobile number"
                            />
                        </div>
                        <button
                            onClick={() => props.sendDownload(1)}
                            className={`${classes['active-btn']} ${classes[props.buttonEnable ? 'disable-btn' : '']}`}
                        >
                            Send to phone
                        </button>
                    </>
                )}
            </div>
        </>
    );
});

import { postCall, putCall } from '../../utils/services/network';
import { put, fork, takeLatest, select } from 'redux-saga/effects';
import * as actionCreators from '../actions/onBoarding';
import * as loaderActionCreators from '../actions/global-loader';
import * as profileActionCreators from '../actions/profile';
import * as jobActionCreators from '../actions/job';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import * as actionTypes from '../actionTypes/onBoarding';
import { ProfileDetails, Error } from '../../utils/types';
import Utils from '../../utils';
import { setSession, getCookie, removeSession, setBannerSession } from '../../utils/services/sessions';
import { OnBoardingModal, ProfileDetailsInitial, JobListModal } from '../../modals';
import { AppState } from '../reducers/rootReducer';
import history from '../../utils/history';
import OfflineError from '../../utils/offlineError';
import FirebaseService from '../../utils/services/firebase-service';
import { sendMessageToWebContacts } from '../../utils/services/external-source-api';
import FacebookEvent from '../../utils/services/facebookEvent';
export function* workerSignUp(action: actionTypes.SignUp) {
    try {
        yield put(actionCreators.setShowOnBoardingModal({ ...new OnBoardingModal() }));
        yield put(loaderActionCreators.setSignUpLogInLoader(true));

        const { firstName, lastName, email, password, referralCode, mobile } = action.payload;
        const phoneNo = mobile;
        const { previousLocation } = yield select((state: AppState) => state.miscellaneous);
        const urlParams = new URLSearchParams(window.location.search);
        // const source = urlParams.get('source');
        // const ccuid = urlParams.get('ccuid');
        const pathName = window.location.pathname;
        let jobId: any = '';
        if (pathName) {
            const array = pathName.split('/');
            if (array.length > 1) {
                jobId = array.pop();
            }
        }

        const data = {
            email: email,
            jobId: '',
            ccuid: '',
            source: '',
            device_type: 3,
            isSearch: true,
            password: password,
            last_name: lastName.trim(),
            referral_code: referralCode,
            first_name: firstName.trim(),
            mobile: phoneNo.replace(/ |-/g, ''),
            user_type: Utils.constant.userType.candidate,
            device_token: localStorage.getItem('deviceToken') || '',
            latitude: localStorage.getItem('latitude') || '0',
            longitude: localStorage.getItem('longitude') || '0',
        };

        data.source = getCookie('source') || '';
        data.ccuid = getCookie('ccuid') || '';
        data.jobId = getCookie('jobId') || '';
        const endPoint = Utils.endpoints.signUp;

        const response = yield postCall(endPoint, data);
        if (response.status === 200) {
            const data: ProfileDetails = response.data.data;

            data.isLogin = true;
            Utils.constant.userId = data._id;
            Utils.constant.isLogin = true;
            Utils.constant.userName = data.full_name;
            Utils.constant.is_profile_added = data.is_profile_added;
            localStorage.setItem('is_profile_added', data.is_profile_added.toString());
            const sessionData = {
                accessToken: data.authToken,
                expiresIn: 0,
                user_id: data.user_id,
                full_name: data.full_name,
            };
            setSession(sessionData);
            // getRecommendedListing(dispatch, getState);
            // getCategory();

            // if (history.location.pathname.indexOf('/job/') === -1) {
            if (previousLocation) {
                history.push('/profile/profile-complete');
            }
            // }

            yield FirebaseService.createUserWithEmailAndPassword(email, Utils.constant.firebasePassword);
            const userInfo = {
                device_token: localStorage.getItem('deviceToken') || '',
                device_type: '3',
                email: data.email,
                first_name: data.first_name,
                image: data.user_image,
                isOnline: true,
                last_name: data.last_name,
                mobile: data.mobile,
                user_id: data._id,
            };
            if (data._id) yield FirebaseService.createUserNode(userInfo);
            // sessionStorage.removeItem('last_searched_address');
            yield put(jobActionCreators.setJobList({ ...new JobListModal() }));
            yield put(profileActionCreators.setProfileInfoAfterOnboarding({ ...data }));
            //getBadgeCount(data._id);
        } else {
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    }
}

function* watcherSignUp() {
    yield takeLatest(actionTypes.SIGN_UP, workerSignUp);
}

export function* workerLogIn(action: actionTypes.LogIn) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));

        const { email, password } = action.payload;
        const { previousLocation } = yield select((state: AppState) => state.miscellaneous);

        const data = {
            email: email,
            password: password,
            device_type: 3,
            user_type: Utils.constant.userType.candidate,
            device_token: localStorage.getItem('deviceToken') || '',
        };
        const endPoint = Utils.endpoints.login;
        const response = yield postCall(endPoint, data);
        if (response.status === 200) {
            const data: ProfileDetails = response.data.data;
            data.isLogin = true;
            Utils.constant.userId = data._id;
            Utils.constant.isLogin = true;
            Utils.constant.userName = data.full_name;
            Utils.constant.is_profile_added = data.is_profile_added;

            Utils.constant.defaultLat = data.lat;
            Utils.constant.defaultLng = data.lng;

            const sessionData = {
                accessToken: data.authToken,
                //expiresIn: remember ? 100000 : 0,
                expiresIn: 0,
                user_id: data.user_id,
                full_name: data.full_name,
            };
            localStorage.setItem('is_profile_added', data.is_profile_added.toString());
            setSession(sessionData);

            // getRecommendedListing(dispatch, getState);
            // getCategory();
            setBannerSession(data.isMobileAppUsed == 1 ? true : false);

            yield FirebaseService.connectUserByAuthorizingWith(email, Utils.constant.firebasePassword);
            const userInfo = {
                device_token: localStorage.getItem('deviceToken') || '',
                device_type: '3',
                email: data.email,
                first_name: data.first_name,
                image: data.user_image,
                isOnline: true,
                last_name: data.last_name,
                mobile: data.mobile,
                user_id: data._id,
            };
            if (data._id) yield FirebaseService.createUserNode(userInfo);
            //getBadgeCount(data._id);

            if (previousLocation) {
                if (data.is_profile_added) {
                    yield put(miscellaneousActionCreators.setPreviousLocation(''));
                    // history.push('/');
                } else {
                    history.push('/profile/profile-complete');
                }
            }
            // sessionStorage.removeItem('last_searched_address');
            yield put(actionCreators.setShowOnBoardingModal({ ...new OnBoardingModal() }));
            yield put(profileActionCreators.setProfileInfoAfterOnboarding({ ...data }));
            if (data.is_profile_added) {
                const { jobAppliedWhenLoggedOutPayload } = yield select((state: AppState) => state.miscellaneous);

                if (
                    jobAppliedWhenLoggedOutPayload._id &&
                    jobAppliedWhenLoggedOutPayload.recruiterId &&
                    jobAppliedWhenLoggedOutPayload.categoryId
                ) {
                    yield put(jobActionCreators.applyJobAfterLogin());
                }
            }
            yield put(jobActionCreators.setJobList({ ...new JobListModal() }));
        } else {
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        console.log(error);
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    }
}

function* watcherLogIn() {
    yield takeLatest(actionTypes.LOGIN, workerLogIn);
}

export function* workerLogOut() {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const { _id } = yield select((state: AppState) => state.profileDetails);
        const endPoint = Utils.endpoints.logout;
        Utils.constant.isLogin = false;
        Utils.constant.userName = '';
        Utils.constant.is_profile_added = 0;
        // if (_id) {
        //   FirebaseService.logoutWithFirebase(_id);
        // }
        const response = yield putCall(endPoint, {});

        yield put(jobActionCreators.setJobList({ ...new JobListModal() }));
        removeSession();
        Utils.constant.defaultLat = 42.361145;
        Utils.constant.defaultLng = -71.057083;
        localStorage.setItem('latitude', '42.361145');
        localStorage.setItem('longitude', '-71.057083');
        Utils.constant.userId = '';
        setBannerSession(false);
        sessionStorage.removeItem('last_searched_address');
        yield put(profileActionCreators.setProfileInfoAfterOnboarding({ ...new ProfileDetailsInitial() }));
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        history.push('/');
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLogOut() {
    yield takeLatest(actionTypes.LOGOUT, workerLogOut);
}

export function* workerLogOutAfterDeactivate() {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const { _id } = yield select((state: AppState) => state.profileDetails);
        Utils.constant.isLogin = false;
        Utils.constant.userName = '';
        Utils.constant.is_profile_added = 0;
        // if (_id) {
        //   FirebaseService.logoutWithFirebase(_id);
        // }
        yield put(jobActionCreators.setJobList({ ...new JobListModal() }));
        removeSession();
        Utils.constant.defaultLat = 42.361145;
        Utils.constant.defaultLng = -71.057083;
        localStorage.setItem('latitude', '42.361145');
        localStorage.setItem('longitude', '-71.057083');
        Utils.constant.userId = '';
        setBannerSession(false);
        yield put(profileActionCreators.setProfileInfoAfterOnboarding({ ...new ProfileDetailsInitial() }));
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        history.push('/search');
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherLogOutAfterDeactivate() {
    yield takeLatest(actionTypes.LOGOUT_AFTER_DEACTIVATE, workerLogOutAfterDeactivate);
}

export function* workerForgotPassword(action: actionTypes.ForgotPasswordInitiate) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const endPoint = Utils.endpoints.forgotPass;
        const data = {
            email: action.payload,
            user_type: Utils.constant.userType.candidate,
        };
        const response = yield postCall(endPoint, data);

        if (response.data.code === 200) {
            yield put(miscellaneousActionCreators.setSuccess({ msg: 'OTP sent successfully!' }));
        } else {
            throw new Error();
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherForgotPassword() {
    yield takeLatest(actionTypes.FORGOT_PASSWORD_INITIATE, workerForgotPassword);
}

export function* workerVerifyOtp(action: actionTypes.VerifyOtp) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const endPoint = Utils.endpoints.emailVerify;
        const { email, otp } = action.payload;
        const payload = {
            email: email,
            user_type: Utils.constant.userType.candidate,
            otp: otp,
        };
        const response = yield postCall(endPoint, payload);

        if (response.status) {
            if (response.data.is_verified_otp === 1) {
                yield put(miscellaneousActionCreators.SetPasswordResetToken(response.data.resetToken));
                yield put(miscellaneousActionCreators.setSuccess({ msg: 'OTP verified successfully!' }));
                yield put(
                    actionCreators.setShowOnBoardingModal({ onBoardingModalName: 'reset', showOnBoardingModal: true }),
                );
            } else if (response.data.is_verified_otp === 0) {
                yield put(miscellaneousActionCreators.setError({ msg: response.data.message }));
            }
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherVerifyOtp() {
    yield takeLatest(actionTypes.VERIFY_OTP, workerVerifyOtp);
}

export function* workerResetPassword(action: actionTypes.ResetPassword) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const { passwordResetToken } = yield select((state: AppState) => state.miscellaneous);
        const { email, password } = action.payload;
        const payload = {
            password: password,
            email: email,
            user_type: Utils.constant.userType.candidate,
            resetToken: passwordResetToken,
        };
        const endPoint = Utils.endpoints.newResetPassword;
        const response = yield postCall(endPoint, payload);

        if (response.status) {
            yield put(miscellaneousActionCreators.setSuccess({ msg: 'Password changed successfully!' }));
            yield put(
                actionCreators.setShowOnBoardingModal({ onBoardingModalName: 'login', showOnBoardingModal: true }),
            );
            yield put(miscellaneousActionCreators.SetPasswordResetToken(''));
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherResetPassword() {
    yield takeLatest(actionTypes.RESET_PASSWORD, workerResetPassword);
}

export function* workerWebContacts(action: actionTypes.WebContacts) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const payload = { ...action.payload };
        delete payload.job_url;
        const endPoint = Utils.endpoints.webContacts;
        const response = yield postCall(endPoint, payload);
        console.log(response);
        if (response && response.data && response.data.code === 200) {
            const msgPayload = {
                phoneNumber: action.payload.mobile,
                countryCode: action.payload.country_code,
                downloadLink: action.payload.job_url,
            };
            console.log(msgPayload);
            const msgResponse = yield sendMessageToWebContacts(msgPayload);
            console.log(msgResponse);
            FacebookEvent.logCustomEvent('web_modal_apply');
            yield put(miscellaneousActionCreators.setWebContactsConfirmation(true));
        } else {
            const payload: Error = {
                msg: '',
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherWebContacts() {
    yield takeLatest(actionTypes.WEB_CONTACTS, workerWebContacts);
}

export function* workerApplyJob(action: actionTypes.NewApplyJob) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const payload = { ...action.payload };
        const endPoint = Utils.endpoints.webContacts;
        console.log(payload, endPoint);
        const response = yield postCall(endPoint, payload);
        // console.log(response);
        // console.log(response, 'jobApplyResponse');
        // if (response && response.data && response.data.code === 200) {

        // } else {
        //     const payload: Error = {
        //         msg: '',
        //     };
        //     yield put(miscellaneousActionCreators.setError(payload));
        // }
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherApplyJob() {
    yield takeLatest(actionTypes.APPLY_JOB_NEW, workerApplyJob);
}

export function* workerGetAppNow(action: actionTypes.GetAppNow) {
    try {
        yield put(loaderActionCreators.setSignUpLogInLoader(true));
        const msgResponse = yield sendMessageToWebContacts(action.payload);
        console.log(msgResponse);
        FacebookEvent.logCustomEvent('web_modal_apply');
        yield put(miscellaneousActionCreators.setDownloadLinkSendConfirmation(true));
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setSignUpLogInLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherGetAppNow() {
    yield takeLatest(actionTypes.GET_APP_NOW, workerGetAppNow);
}

const onBoardingSagas = [
    fork(watcherSignUp),
    fork(watcherLogOut),
    fork(watcherLogIn),
    fork(watcherLogOutAfterDeactivate),
    fork(watcherForgotPassword),
    fork(watcherVerifyOtp),
    fork(watcherResetPassword),
    fork(watcherWebContacts),
    fork(watcherApplyJob),
    fork(watcherGetAppNow),
];

export default onBoardingSagas;

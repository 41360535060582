import { Route, Redirect } from 'react-router-dom';
import { getUserId } from '../utils/services/sessions';
import React from 'react';

interface Props {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}
const PrivateRoute = ({ component: Component, ...otherProps }: Props): JSX.Element => (
    <Route {...otherProps} render={(props) => (getUserId() ? <Component {...props} /> : <Redirect to="/" />)} />
);
export default PrivateRoute;

import { put, takeEvery, fork } from 'redux-saga/effects';
import * as actionCreators from '../actions/master-data';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import * as actionTypes from '../actionTypes/master-data';
import Utils from '../../utils';
import { getCall } from '../../utils/services/network';
import { Error } from '../../utils/types';
import OfflineError from '../../utils/offlineError';

export function* workerSetCategoryList() {
    try {
        const endPoint = Utils.endpoints.categoryList;
        const response = yield getCall(endPoint, '');
        if (response.data.code === 200) yield put(actionCreators.setCategoryListSuccess(response.data.data));
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherSetCategoryList() {
    yield takeEvery(actionTypes.SET_CATEGORY_LIST, workerSetCategoryList);
}

const masterDataSagas = [fork(watcherSetCategoryList)];

export default masterDataSagas;

import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import masterDataReducer from './masterDataReducer';
import SettingsReducer from './settingsReducer';
import globalLoaderReducer from './globalLoaderReducer';
import JobReducer from './jobReducer';
import MessageReducer from './messageReducer';
import NotificationReducer from './notificationReducer';
import MiscellaneousReducer from './miscellaneousReducer';
import OnBoardingReducer from './onBoardingReducer';

const rootReducer = combineReducers({
    jobs: JobReducer,
    message: MessageReducer,
    settings: SettingsReducer,
    onBoarding: OnBoardingReducer,
    profileDetails: profileReducer,
    notifications: NotificationReducer,
    globalLoaders: globalLoaderReducer,
    miscellaneous: MiscellaneousReducer,
    masterDataDetails: masterDataReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;

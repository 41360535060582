import React, { memo, useState, useEffect } from 'react';
import classes from './sign-up-sign-in.module.scss';
import ProgressiveImage from 'react-progressive-image';
import { connect } from 'react-redux';
import CustomInput from '../../../components/custom-elements/custom-input';
import CustomButton from '../../../components/custom-elements/custom-button';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { setShowOnBoardingModal, signUp, logIn, webContacts } from '../../../redux/actions/onBoarding';
import { setWebContactsConfirmation } from '../../../redux/actions/miscellaneous';
import { OnBoardingType, SignUpType, WebContactsPayload } from '../../../utils/types';
import Utils from '../../../utils';
import { getCookie } from '../../../utils/services/sessions';
import { AppState } from '../../../redux/reducers/rootReducer';
import CountryPicker from '../../../components/banner/country';
import ConfirmationModal from '../../../components/confirmation-modal';
import Backdrop from '../../../components/backdrop';
import * as qs from 'query-string';

interface Props {
    store?: any;
    show: boolean;
    companyName?: string;
    jobTitle?: string;
    jobAddress?: string;
    jobId?: string;
    signUpFlag: boolean;
    showHandler: () => void;
    previousLocation?: string;
    signUp?: (param: SignUpType) => void;
    webContactsConfirmationFlag?: boolean;
    webContacts?: (param: WebContactsPayload) => void;
    setWebContactsConfirmation?: (param: boolean) => void;
    setShowOnBoardingModal?: (param: OnBoardingType) => void;
    logIn?: (param: Pick<SignUpType, 'email' | 'password'>) => void;
    pageType?: string;
}

const SignUpSignIn = memo(function SignUpSignIn(props: Props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [passwordTypeText, setPasswordTypeText] = useState(false);
    const [errorCheck, setErrorCheck] = useState(false);
    const [referralCodeCheck, setReferralCodeCheck] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('+1');
    const [countryCodeToggler, setCountryCodeToggler] = useState(false);

    useEffect(() => {
        reset();
        if (props.show && props.signUpFlag) {
            const referal_code = getCookie('referal_code');
            if (referal_code) {
                setReferralCodeCheck(true);
                setReferralCode(referal_code);
            }
        }
    }, [props.show]);

    function logInHandler() {
        const payload: OnBoardingType = {
            onBoardingModalName: 'login',
            showOnBoardingModal: true,
        };
        props.setShowOnBoardingModal && props.setShowOnBoardingModal(payload);
    }

    function singUpHandler() {
        const payload: OnBoardingType = {
            onBoardingModalName: 'signup',
            showOnBoardingModal: true,
        };
        props.setShowOnBoardingModal && props.setShowOnBoardingModal(payload);
    }

    function forgotPasswordHandler() {
        const payload: OnBoardingType = {
            onBoardingModalName: 'forgot',
            showOnBoardingModal: true,
        };
        props.setShowOnBoardingModal && props.setShowOnBoardingModal(payload);
    }

    function reset() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setPhoneNumber('');
        setErrorCheck(false);
    }

    function submitSignUpHandler() {
        // if (
        //     !Utils.validators.validateFirstName(firstName) &&
        //     !Utils.validators.validateLastName(lastName) &&
        //     !Utils.validators.validateEmail(email) &&
        //     !Utils.validators.validatePhoneNumber(phoneNumber) &&
        //     !Utils.validators.validatePassword(password)
        // ) {
        //     const payload: SignUpType = {
        //         firstName,
        //         lastName,
        //         email,
        //         password,
        //         referralCode,
        //         mobile: phoneNumber.replace(/ |-/g, ''),
        //     };
        //     props.signUp && props.signUp(payload);
        // } else {
        //     setErrorCheck(true);
        // }
        const src = props.pageType && props.pageType === 'posting' ? 'g4j' : getCookie('source') || '';
        if (
            !Utils.validators.validateFirstName(firstName) &&
            !Utils.validators.validateLastName(lastName) &&
            !Utils.validators.validateEmail(email) &&
            !Utils.validators.validatePhoneNumber(phoneNumber)
        ) {
            let payload: WebContactsPayload = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                mobile: phoneNumber.replace(/ |-/g, ''),
                job_url: Utils.constant.getJobUrlLink(src),
                country_code: code,
                companyName: props.companyName,
                jobTitle: props.jobTitle,
                jobAddress: props.jobAddress,
                source: src,
            };
            if (props.pageType && props.pageType === 'posting') {
                payload = {
                    ...payload,
                    job_external_id: props.jobId,
                };
            } else {
                payload = {
                    ...payload,
                    jobId: props.jobId,
                };
            }
            props.webContacts && props.webContacts(payload);
        } else {
            setErrorCheck(true);
        }
    }

    function submitLogInHandler() {
        if (!Utils.validators.validateEmail(email) && !Utils.validators.validatePassword(password)) {
            const payload: Pick<SignUpType, 'email' | 'password'> = {
                email,
                password,
            };
            props.logIn && props.logIn(payload);
        } else {
            setErrorCheck(true);
        }
    }
    function onSubmitHandler() {
        props.signUpFlag ? submitSignUpHandler() : submitLogInHandler();
    }

    function formatPhoneNumber(str: string) {
        const input = str.replace(/\D/g, '').substring(0, 10);
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) {
            return `${zip} - ${middle} - ${last}`;
        } else if (input.length > 3) {
            return `${zip} - ${middle}`;
        } else if (input.length > 0) {
            return `${zip}`;
        }
    }

    function countryCodeSelect(val: string) {
        setCode(val);
        setCountryCodeToggler(false);
    }

    function webContactsConfirmation() {
        props.setWebContactsConfirmation && props.setWebContactsConfirmation(false);
    }

    return (
        <>
            {props.show ? (
                <>
                    <div
                        className={`${classes['signup-parent-wrapper']} custom-scrollbar-global`}
                        data-test="signup-parent-wrapper"
                        style={countryCodeToggler ? { zIndex: 109 } : {}}
                    >
                        <div
                            className={classes['close-image']}
                            onClick={() => props.showHandler()}
                            data-test="close-image"
                        ></div>
                        <ProgressiveImage
                            src={'https://jobget-static.s3.amazonaws.com/new/Group38.png'}
                            placeholder={'https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg'}
                        >
                            {(src: string) => <img style={{ cursor: 'pointer' }} src={src} alt="main" />}
                        </ProgressiveImage>
                        {/* <div className={classes['fb-connect']}>
                            <CustomButton
                                styleClass={`btn-primary ${classes['adjustments']}`}
                                onClickHandler={() => console.log('clicked')}
                            >
                                <span>f</span> Continue with Facebook
                            </CustomButton>
                        </div> */}
                        <div className={classes['title']}>
                            {/* {props.previousLocation
                                ? props.signUpFlag
                                    ? 'Sign up to apply for the job'
                                    : 'Login to apply for the job'
                                : props.signUpFlag
                                ? 'Create an Account'
                                : 'Welcome back!'} */}
                            Enter your contact details to continue with your application
                        </div>
                        <div className={classes['details-wrapper']}>
                            {props.signUpFlag && (
                                <div className={classes['details']} data-test="first-name">
                                    <label htmlFor="first-name">First Name</label>
                                    <CustomInput
                                        type="text"
                                        styleClass={classes['custom-input-adjustments']}
                                        onChangeHandler={(e: any) => setFirstName(e.target.value)}
                                        placeHolder="Enter First Name"
                                        id="first-name"
                                        value={firstName}
                                    />
                                    {errorCheck && (
                                        <div className="error">{Utils.validators.validateFirstName(firstName)}</div>
                                    )}
                                </div>
                            )}
                            {props.signUpFlag && (
                                <div className={classes['details']} data-test="last-name">
                                    <label htmlFor="last-name">Last Name</label>
                                    <CustomInput
                                        type="text"
                                        styleClass={classes['custom-input-adjustments']}
                                        onChangeHandler={(e: any) => setLastName(e.target.value)}
                                        placeHolder="Enter Last Name"
                                        id="last-name"
                                        value={lastName}
                                    />
                                    {errorCheck && (
                                        <div className="error">{Utils.validators.validateLastName(lastName)}</div>
                                    )}
                                </div>
                            )}
                            <div className={classes['details']}>
                                <label htmlFor="email" data-test="email">
                                    Email
                                </label>
                                <CustomInput
                                    type="text"
                                    styleClass={classes['custom-input-adjustments']}
                                    onChangeHandler={(e: any) => setEmail(e.target.value)}
                                    placeHolder="Enter Email"
                                    id="email"
                                    value={email}
                                />
                                {errorCheck && <div className="error">{Utils.validators.validateEmail(email)}</div>}
                            </div>
                            {props.signUpFlag && (
                                <div className={classes['details']} data-test="phone">
                                    <label htmlFor="phone">Phone Number</label>
                                    <div className={classes['phone-number']}>
                                        <div
                                            className={classes['country-code']}
                                            onClick={() => setCountryCodeToggler(true)}
                                        >
                                            {code}
                                        </div>
                                        <CustomInput
                                            type="text"
                                            styleClass={classes['custom-input-adjustments']}
                                            parentStyleClass={classes['phone-number-input-parent']}
                                            onChangeHandler={(e: any) =>
                                                setPhoneNumber(formatPhoneNumber(e.target.value) || '')
                                            }
                                            onSubmitHandler={onSubmitHandler}
                                            placeHolder="Enter Phone Number"
                                            id="phone"
                                            value={phoneNumber}
                                        />
                                    </div>
                                    {errorCheck && (
                                        <div className="error">{Utils.validators.validatePhoneNumber(phoneNumber)}</div>
                                    )}
                                </div>
                            )}
                            {/* <div className={classes['details']} data-test="password">
                                <label htmlFor="password">Password</label>
                                <CustomInput
                                    type={passwordTypeText ? 'text' : 'password'}
                                    styleClass={classes['custom-input-adjustments']}
                                    onChangeHandler={(e: any) => setPassword(e.target.value)}
                                    placeHolder="Enter Password"
                                    id="password"
                                    imageRequired={true}
                                    imageSrc="Group 4@3x.svg"
                                    imageStyle={classes['password-image']}
                                    imageClickHandler={() => {
                                        setPasswordTypeText(!passwordTypeText);
                                    }}
                                    value={password}
                                    onSubmitHandler={onSubmitHandler}
                                />
                                {errorCheck && (
                                    <div className="error">{Utils.validators.validatePassword(password)}</div>
                                )}
                            </div> */}
                        </div>
                        {/* {props.signUpFlag && (
                            <>
                                {!referralCodeCheck && (
                                    <div
                                        className={classes['referral']}
                                        onClick={() => setReferralCodeCheck(true)}
                                        data-test="referral"
                                    >
                                        Redeem Referral Code (Optional)
                                    </div>
                                )}
                                {referralCodeCheck && (
                                    <div className={classes['referral-details']}>
                                        <label htmlFor="first-name">Referral Code</label>
                                        <CustomInput
                                            type="text"
                                            styleClass={classes['custom-input-adjustments']}
                                            onChangeHandler={(e: any) => setReferralCode(e.target.value)}
                                            placeHolder="Enter Referral Code"
                                            id="referral-code"
                                            value={referralCode}
                                            onSubmitHandler={onSubmitHandler}
                                        />
                                    </div>
                                )}
                            </>
                        )} */}
                        <div className={classes['sign-up-btn-wrapper']} data-test="btn-wrapper">
                            <CustomButton
                                styleClass={`btn-primary ${classes['sign-up-adjustments']}`}
                                onClickHandler={onSubmitHandler}
                            >
                                {/* {props.signUpFlag ? 'Sign Up' : 'Log in'} */}
                                Submit
                            </CustomButton>
                        </div>
                        <div className={classes['terms-and-conditions']}>
                            <p>
                                By clicking the button above, I agree to the&nbsp;
                                <a href={'https://jobget.com/termsconditions.html'} target="__blank">
                                    Terms
                                </a>{' '}
                                and &nbsp;
                                <a href={'https://jobget.com/privacypolicy.html'} target="__blank">
                                    Privacy Policy
                                </a>
                                , and agree to receive email job alerts.
                            </p>
                        </div>
                        {!props.signUpFlag && (
                            <div
                                onClick={forgotPasswordHandler}
                                className={classes['forgot-password']}
                                data-test="forgot-password"
                            >
                                Forgot Password ?
                            </div>
                        )}
                        {/* {props.signUpFlag ? (
                            <div className={classes.login} data-test="login">
                                Have an account? <span onClick={logInHandler}>Log In</span>
                            </div>
                        ) : (
                            <div className={classes.signup} data-test="sign-up">
                                Don't have an account? <span onClick={singUpHandler}>Sign up</span>
                            </div>
                        )} */}
                    </div>
                </>
            ) : (
                <> </>
            )}
            {countryCodeToggler && (
                <div className={classes['country-code-parent-wrapper']}>
                    <div
                        className={classes['close-image']}
                        onClick={() => setCountryCodeToggler(!countryCodeToggler)}
                    ></div>
                    <CountryPicker confirm={countryCodeSelect} />
                </div>
            )}
            {props.webContactsConfirmationFlag && (
                <>
                    <ConfirmationModal
                        title="Download the JobGet App to Continue"
                        subTitle="We have sent a download link to your mobile number. Please download the JobGet app to continue with your application."
                        positiveBtnText="Okay"
                        positiveBtnHandler={webContactsConfirmation}
                        negativeBtnText="No"
                        negativeBtnHandler={() => {}}
                        removeNegativeBtn={true}
                    />
                    <Backdrop show={props.webContactsConfirmationFlag} clickHandler={webContactsConfirmation} />
                </>
            )}
        </>
    );
});

function mapStateToProps(state: AppState) {
    const { previousLocation, webContactsConfirmationFlag } = state.miscellaneous;
    return {
        previousLocation,
        webContactsConfirmationFlag,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators(
        { setShowOnBoardingModal, signUp, logIn, webContacts, setWebContactsConfirmation },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpSignIn);

import { OnBoardingModal } from '../../modals';
import * as types from '../actionTypes/onBoarding';
import { OnBoardingType } from '../../utils/types';

export default function OnBoardingReducer(
    state: OnBoardingType = new OnBoardingModal(),
    action: types.types,
): OnBoardingModal {
    switch (action.type) {
        case types.SET_SHOW_ONBOARDING_MODAL:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

import { JobStateType } from '../../utils/types';
import { JobStateInitial, JobModal } from '../../modals';
import * as types from '../actionTypes/job';

const initialState: JobStateType = new JobStateInitial();

export default function JobReducer(state: JobStateType = initialState, action: types.types): JobStateType {
    switch (action.type) {
        case types.SET_SIMILAR_JOB_LIST:
            const { payload } = action;
            return {
                ...state,
                similarJobs: {
                    ...state.similarJobs,
                    page: payload.page ? payload.page : 0,
                    next: payload.next ? payload.next : false,
                    jobList: payload.jobList ? [...payload.jobList] : [],
                },
            };
        case types.SET_JOB_LIST:
            return { ...state, jobList: { ...state.jobList, ...action.payload } };

        case types.SET_JOB_INFO_SUCCESS:
            return { ...state, jobList: { ...state.jobList, jobInfo: action.payload } };

        case types.UNSET_JOB_INFO:
            return { ...state, jobList: { ...state.jobList, jobInfo: { ...new JobModal() } } };

        case types.APPLY_JOB_SUCCESS:
            return { ...state, jobList: { ...state.jobList, ...action.payload } };

        case types.SET_APPLIED_JOB_LIST:
            return { ...state, appliedJobs: { ...state.appliedJobs, ...action.payload } };

        case types.SET_SHORTLISTED_JOB_LIST:
            return { ...state, shortlistedJobs: { ...state.shortlistedJobs, ...action.payload } };

        case types.APPLY_JOB_AFTER_LOGIN_SUCCESS:
            return { ...state, jobList: { ...state.jobList, ...action.payload } };

        case types.REPORT_JOB_SUCCESS:
            return { ...state, jobList: { ...state.jobList, ...action.payload } };

        case types.REPORT_APPLIED_JOB_SUCCESS:
            return {
                ...state,
                jobList: { ...state.jobList, jobInfo: { ...action.payload.jobInfo } },
                appliedJobs: { ...state.appliedJobs, appliedJobList: [...action.payload.jobList] },
            };

        case types.REPORT_SHORTLISTED_JOB_SUCCESS:
            return {
                ...state,
                jobList: { ...state.jobList, jobInfo: { ...action.payload.jobInfo } },
                shortlistedJobs: { ...state.shortlistedJobs, shortListJobList: [...action.payload.jobList] },
            };
        case types.SET_OTHER_JOB_LIST:
            return {
                ...state,
                otherJobs: { ...state.otherJobs, jobList: action.payload.jobList ? [...action.payload.jobList] : [] },
            };

        default:
            return state;
    }
}

import React, { memo, useState, useEffect, useRef } from 'react';
import JobGetLogo from '../../assets/images/JobGet.png';
import NavigationItems from './navigation-items';
import SideDrawer from './side-drawer/index';
import SideDrawerToggler from './side-drawer/side-drawer-toggler/index';
import ProgressiveImage from 'react-progressive-image';
import NotificationPane from './notification/index';
import LoginSignUp from '../../modules/onboarding/screen/login-sign-up';
import './index.scss';
import { AppState } from '../../redux/reducers/rootReducer';
import { connect } from 'react-redux';
import {
    fetchNotificationList,
    loadMoreNotificationList,
    deleteNotification,
    clearNotification,
} from '../../redux/actions/notification';
import { getBadgeCount } from '../../redux/actions/chat';
import { setProfileOnRefresh } from '../../redux/actions/profile';
import { logOut } from '../../redux/actions/onBoarding';
import { NotificationData, DeleteNotificationPayload } from '../../utils/types';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import ProfileOptionsPane from './profile-other-options/index';
import ConfirmationModal from '../confirmation-modal';
import Backdrop from '../backdrop/index';
import {
    setSideDrawerShow,
    setJobAppliedModal,
    setAppliedJobRecruiterId,
    setNotificationCount,
} from '../../redux/actions/miscellaneous';
import { setCategoryList } from '../../redux/actions/master-data';
import { getAccessToken, getUserId } from '../../utils/services/sessions';
import Utils from '../../utils';
// import history from '../../utils/history';
import CustomInput from '../custom-elements/custom-input';
import { LocationAutoComplete } from '../custom-elements/custom-location-autocomplete';
import CustomDropdown from '../custom-elements/custom-dropdown';
import CustomButton from '../custom-elements/custom-button';
import { useHistory } from 'react-router-dom';

interface Props {
    store?: any;
    _id?: string;
    next?: boolean;
    user_image?: string;
    first_name: string;
    last_name: string;
    logOut?: () => void;
    refreshFlag?: boolean;
    jobAppliedFlag?: boolean;
    sideDrawerShow?: boolean;
    getBadgeCount: () => void;
    notificationCount?: number;
    setCategoryList?: () => void;
    notificationLoading?: boolean;
    appliedJobRecruiterId?: string;
    clearNotification?: () => void;
    setProfileOnRefresh?: () => void;
    setNotificationCount?: () => void;
    fetchNotificationList?: () => void;
    loadMoreNotificationList?: () => void;
    notifications?: Array<NotificationData>;
    setSideDrawerShow?: (param: boolean) => void;
    setJobAppliedModal?: (param: boolean) => void;
    setAppliedJobRecruiterId?: (param: string) => void;
    deleteNotification?: (payload: DeleteNotificationPayload) => void;
}

const Header = memo(function Header(props: Props) {
    const [showNotificationPane, setShowNotificationPane] = React.useState(false);
    const [blurCheck, setBlurCheck] = useState(false);
    const [showProfilePane, setShowProfilePane] = React.useState(false);
    const [profileBlurCheck, setProfileBlurCheck] = useState(false);
    const [logOutConfirmation, setLogOutConfirmation] = React.useState(false);
    const [windowFlag, setWindowFlag] = useState<boolean>(false);
    let timeOut: ReturnType<typeof setTimeout>;
    let profileTimeOut: ReturnType<typeof setTimeout>;

    const history = useHistory();

    useEffect(() => {
        props.setCategoryList && props.setCategoryList();
        if (getUserId() !== null && getUserId()) {
            loggedInInitializer();
        } else {
            initializer();
        }
        if (window.matchMedia('(max-width: 800px)').matches) {
            setWindowFlag(true);
        }
    }, []);

    useEffect(() => {
        if (props._id && props.notifications && props.notifications.length <= 0) {
            props.fetchNotificationList && props.fetchNotificationList();
        }
        if (props._id) {
            props.getBadgeCount();
        }
    }, [props._id]);

    useEffect(() => {
        if (blurCheck) {
            if (timeOut === undefined) {
                setTimeout(() => {
                    setBlurCheck(false);
                }, 500);
            } else {
                clearTimeout(timeOut);
            }
        }
    }, [blurCheck]);

    useEffect(() => {
        if (profileBlurCheck) {
            if (profileTimeOut === undefined) {
                setTimeout(() => {
                    setProfileBlurCheck(false);
                }, 500);
            } else {
                clearTimeout(profileTimeOut);
            }
        }
    }, [profileBlurCheck]);

    const initializer = () => {
        Utils.constant.defaultLat = 42.361145;
        Utils.constant.defaultLng = -71.057083;
        localStorage.setItem('latitude', '42.361145');
        localStorage.setItem('longitude', '-71.057083');
    };

    const loggedInInitializer = () => {
        getAccessToken();
        props.setProfileOnRefresh && props.setProfileOnRefresh();
        let lat: any = localStorage.getItem('latitude') || Utils.constant.defaultLat;
        let long: any = localStorage.getItem('longitude') || Utils.constant.defaultLng;
        if (typeof lat == 'string') {
            lat = parseFloat(lat);
        }
        if (typeof long == 'string') {
            long = parseFloat(long);
        }
        props.setNotificationCount && props.setNotificationCount();
        // let queryParams = getSearchFromUrl();
        // let pathName = window.location.pathname;
        // if (pathName.includes('/search') || pathName === '/') {
        //     if (queryParams.location) {
        //         let response = getAddressFromQueryParams(queryParams.location);
        //         response.then((payload) => {
        //             if (Object.keys(payload).length > 0) {
        //                 setCurrentAddress(payload);
        //             } else {
        //                 Utils.constant.location.addressFromLatLong(lat, long, setCurrentAddress);
        //             }
        //         });
        //     } else {
        //         Utils.constant.location.addressFromLatLong(lat, long, setCurrentAddress);
        //     }
        // } else {
        //     Utils.constant.location.addressFromLatLong(lat, long, setCurrentAddress);
        // }

        // if (pathName === '/jobs/' || pathName === '/jobs/search' || pathName.includes('/jobs/search')) {
        //     setQueryParamsSearch();
        // }
    };

    const sideDrawerHandler = () => {
        props.setSideDrawerShow && props.setSideDrawerShow(!props.sideDrawerShow);
    };

    const redirectToHome = () => {
        history.push('/');
    };

    const notificationsHandler = () => {
        if (!blurCheck) setShowNotificationPane(!showNotificationPane);
    };

    const profilePaneHandler = () => {
        if (!profileBlurCheck) setShowProfilePane(!showProfilePane);
    };

    const logOutHandler = () => {
        setLogOutConfirmation(true);
    };

    const logOutConfirmed = () => {
        setLogOutConfirmation(false);
        props.logOut && props.logOut();
    };

    const logOutCancelled = () => {
        setLogOutConfirmation(false);
    };

    const deleteNotification = (payload: DeleteNotificationPayload) => {
        props.deleteNotification && props.deleteNotification(payload);
    };

    const clearNotification = () => {
        props.clearNotification && props.clearNotification();
    };

    const messageRecruiter = () => {
        if (props.appliedJobRecruiterId) history.push('/messages?recruiterId=' + props.appliedJobRecruiterId);
        props.setJobAppliedModal && props.setJobAppliedModal(false);
        props.setAppliedJobRecruiterId && props.setAppliedJobRecruiterId('');
    };

    const cancelMessageRecruiter = () => {
        props.setAppliedJobRecruiterId && props.setAppliedJobRecruiterId('');
        props.setJobAppliedModal && props.setJobAppliedModal(false);
    };

    const getUserProfileInitialsLetters = (first_name: string, last_name: string): string => {
        return `${first_name.slice(0, 1)}${last_name.slice(0, 1)}`.toUpperCase();
    };

    const redirectToProfile = () => {
        history.push('/profile');
    };

    return (
        <>
            <div
                className="header-skip-to-main-content"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') document.getElementById('job-listing-search')?.focus();
                }}
            >
                Skip to main content
            </div>
            {props.jobAppliedFlag && (
                <>
                    <ConfirmationModal
                        title="Congratulations!"
                        subTitle="You've just applied to this job. Send them a message to stand out from the rest."
                        positiveBtnText="MESSAGE"
                        positiveBtnHandler={messageRecruiter}
                        negativeBtnText="SKIP FOR NOW"
                        negativeBtnHandler={cancelMessageRecruiter}
                    />
                    <Backdrop show={props.jobAppliedFlag} clickHandler={cancelMessageRecruiter} />
                </>
            )}
            <LoginSignUp />
            {logOutConfirmation && (
                <>
                    <ConfirmationModal
                        title="Log Out"
                        subTitle="Are you sure you want to log out?"
                        positiveBtnText="Yes"
                        positiveBtnHandler={logOutConfirmed}
                        negativeBtnText="No"
                        negativeBtnHandler={logOutCancelled}
                        data-test="logout-confirmation-modal"
                    />
                    <Backdrop show={logOutConfirmation} clickHandler={() => {}} data-test="backdrop" />
                </>
            )}
            <header>
                <div data-test="left-section" className="left-section">
                    <div data-test="logo" className="logo" onClick={redirectToHome}>
                        <img src={JobGetLogo} alt="JobGet logo" />
                    </div>
                </div>
                {!props.refreshFlag &&
                    // <div data-test="right-section" className="right-section">
                    //     <div className="big-screens">
                    //         <NavigationItems />
                    //     </div>
                    //     {props._id ? (
                    //         <>
                    //             <div className="profile-container" data-test="profile-container">
                    //                 <figure
                    //                     className="profile-image"
                    //                     onClick={windowFlag ? redirectToProfile : profilePaneHandler}
                    //                 >
                    //                     <ProgressiveImage
                    //                         src={props.user_image ? props.user_image : ''}
                    //                         placeholder={
                    //                             'https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg'
                    //                         }
                    //                     >
                    //                         {(src: string, loading: boolean) => (
                    //                             <img style={{ cursor: 'pointer' }} src={src} alt="candidate-img" />
                    //                         )}
                    //                     </ProgressiveImage>
                    //                     {props.user_image ? (
                    //                         ''
                    //                     ) : (
                    //                         <label className={'profile-image-intials'}>
                    //                             {getUserProfileInitialsLetters(props.first_name, props.last_name)}
                    //                         </label>
                    //                     )}
                    //                 </figure>
                    //             </div>
                    //             {/* <SideDrawerToggler showHandler={sideDrawerHandler} /> */}
                    //             {!windowFlag && (
                    //                 <div
                    //                     className={`notification-container ${showNotificationPane && 'toggled-class'}`}
                    //                 >
                    //                     {props.notificationCount ? (
                    //                         <span className="digit">
                    //                             {/* <span className="notifyCounter">{props.notificationCount}</span> */}
                    //                         </span>
                    //                     ) : null}
                    //                     <img
                    //                         src={'https://jobget-static.s3.amazonaws.com/new/NotificationVector.svg'}
                    //                         alt="candidate-img"
                    //                         id="notification-bell"
                    //                         onClick={notificationsHandler}
                    //                     />
                    //                 </div>
                    //             )}
                    //         </>
                    //     ) : // <SideDrawerToggler showHandler={sideDrawerHandler} />
                    //     null}
                    // </div>
                    null}
            </header>
            {/* <SideDrawer show={sideDrawerHandler} open={props.sideDrawerShow || false} logOutHandler={logOutHandler} /> */}
            {showNotificationPane && !windowFlag && (
                <NotificationPane
                    setShowNotificationPane={() => setShowNotificationPane(false)}
                    setBlurCheck={() => setBlurCheck(true)}
                    next={props.next || false}
                    notifications={props.notifications || []}
                    notificationLoading={props.notificationLoading || false}
                    loadMoreNotificationList={
                        props.loadMoreNotificationList ? props.loadMoreNotificationList : () => {}
                    }
                    deleteNotification={deleteNotification}
                    clearNotification={clearNotification}
                    data-test="notifications-pane"
                />
            )}
            {showProfilePane && (
                <ProfileOptionsPane
                    setShowProfilePane={() => setShowProfilePane(false)}
                    setBlurCheck={() => setProfileBlurCheck(true)}
                    logOutHandler={logOutHandler}
                    data-test="profile-options-pane"
                />
            )}
        </>
    );
});

function mapStateToProps(state: AppState) {
    const { _id, user_image, first_name, last_name } = state.profileDetails;
    const { next, notifications } = state.notifications;
    const { notificationLoading } = state.globalLoaders;
    const {
        sideDrawerShow,
        refreshFlag,
        jobAppliedFlag,
        appliedJobRecruiterId,
        notificationCount,
    } = state.miscellaneous;
    return {
        _id,
        next,
        user_image,
        first_name,
        last_name,
        refreshFlag,
        notifications,
        jobAppliedFlag,
        sideDrawerShow,
        notificationCount,
        notificationLoading,
        appliedJobRecruiterId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators(
        {
            fetchNotificationList,
            loadMoreNotificationList,
            logOut,
            getBadgeCount,
            setSideDrawerShow,
            setCategoryList,
            setProfileOnRefresh,
            deleteNotification,
            clearNotification,
            setJobAppliedModal,
            setAppliedJobRecruiterId,
            setNotificationCount,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

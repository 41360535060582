import React, { memo, useEffect, useState } from 'react';
import { AppState } from '../../redux/reducers/rootReducer';
import { connect } from 'react-redux';
import classes from './index.module.scss';
import { Dispatch, AnyAction, bindActionCreators } from 'redux';
import { setError } from '../../redux/actions/miscellaneous';
import { Error } from '../../utils/types';
import { logOutAfterDeactivate } from '../../redux/actions/onBoarding';

interface Props {
    store?: any;
    error?: Error;
    setError?: (param: Error) => void;
    logOutAfterDeactivate?: () => void;
}

const ErrorToast = memo(function ErrorToast(props: Props) {
    const [errorMsg, setErrorMsg] = useState<string>('');
    let timeOut: ReturnType<typeof setTimeout>;
    useEffect(() => {
        if (props.error?.msg === 'This user does not exist or is logged into another device.') {
            props.logOutAfterDeactivate && props.logOutAfterDeactivate();
        }
        if (props.error?.msg) {
            setErrorMsg(props.error.msg);
            timeOut = setTimeout(() => {
                closeHandler();
            }, 4000);
        }
        return () => {
            if (timeOut !== undefined) {
                clearTimeout(timeOut);
            }
        };
    }, [props.error?.msg]);

    function closeHandler() {
        if (timeOut !== undefined) {
            clearTimeout(timeOut);
        }
        const payload: Error = {
            msg: '',
        };
        props.setError && props.setError(payload);
    }

    return (
        <div
            className={classes['error-wrapper']}
            data-test="error-wrapper"
            style={props.error?.msg ? { top: '8px' } : {}}
        >
            {/* <div className={classes['close']} data-test="close" onClick={closeHandler}></div> */}
            {errorMsg}
        </div>
    );
});

function mapStateToProps(state: AppState) {
    const { error } = state.miscellaneous;
    return {
        error,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return bindActionCreators({ setError, logOutAfterDeactivate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorToast);

import * as types from '../actionTypes/master-data';
import { MasterDataStateType } from '../../utils/types';
import { MasterDataInitial } from '../../modals';

const initialState: MasterDataStateType = new MasterDataInitial();

export default function MasterDataReducer(
    state: MasterDataStateType = initialState,
    action: types.types,
): MasterDataStateType {
    switch (action.type) {
        case types.SET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
            };
        default:
            return state;
    }
}

import rootReducer, { AppState } from '../redux/reducers/rootReducer';
import { createStore } from 'redux';

const findByTestAtrr = (component: any, attr: any) => {
    const wrapper = component.find(`[data-test='${attr}']`);
    return wrapper;
};

const storeFactory = () => {
    return createStore(rootReducer);
};

export default {
    findByTestAtrr,
    storeFactory,
};

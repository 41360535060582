import { NotificationState } from '../../utils/types';
import { NotificationStateInitial } from '../../modals';
import * as types from '../actionTypes/notification';

const initialState: NotificationState = new NotificationStateInitial();

export default function NotificationReducer(state = initialState, action: types.types): NotificationState {
    switch (action.type) {
        case types.SET_NOTIFICATION_LIST:
            return { ...state, ...action.payload };

        default:
            return state;
    }
}

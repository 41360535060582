import {
    JobListType,
    CompanyType,
    CategoryList,
    GlobalLoader,
    JobStateType,
    MessageState,
    JobModalType,
    OnBoardingType,
    ProfileDetails,
    OtherJobListType,
    NotificationData,
    ExperienceDetails,
    NotificationState,
    SettingsStateType,
    // JobDetailModalType,
    RecruiterModalType,
    SimilarJobListType,
    MasterDataStateType,
    AppliedJobModalType,
    ShortListedJobModalType,
    LocationType,
    UserLoginSessionType,
    MiscellaneousState,
    Error,
    AccountDeactivatedType,
} from '../utils/types';

export class CustomDropdownList {
    text = '';
    value: any;
}

export class SettingsStateInitial implements SettingsStateType {
    faqs = '';
    terms = '';
    policy = '';
}

export class ProfileDetailsInitial implements ProfileDetails {
    jobType = 0;
    // aboutMe = '';
    education = '';
    // profileImg = '';
    // lastName = '';
    isUnderAge = false;
    // firstName = '';
    // experienceAdded = [];
    email = '';
    // phone = '';
    // searchConcernPermission = -1;
    lat = 0;
    lng = 0;
    _id = '';
    city = '';
    state = '';
    status = 0;
    isRead = 0;
    stars = 10;
    about = '';
    gender = '';
    isNotify = 1;
    jobByAge = 0;
    isSearch = 0;
    addedVia = 1;
    mobile = '';
    user_id = '';
    address = '';
    user_type = 2;
    freeStars = 3;
    shareUrl = '';
    google_id = '';
    authToken = '';
    visitCount = 0;
    last_name = '';
    created_at = 0;
    updated_at = 0;
    full_name = '';
    inviteUrl = '';
    resumeUrl = '';
    userImage = '';
    user_image = '';
    callStatus = '';
    first_name = '';
    twitter_id = '';
    isLogin = false;
    profileView = 0;
    isExperience = 0;
    is_exp_added = 0;
    referralUrl = '';
    facebook_id = '';
    isEmailVerify = 0;
    isTextMessage = 1;
    freeStarLimit = 3;
    jobApplyCount = 0;
    referralCode = '';
    date_created = '';
    starsUsedCount = 0;
    jobCreatedCount = 0;
    isMobileAppUsed = 0;
    firebaseUserId = '';
    messageTemplate = '';
    isEmailSubscribe = 1;
    is_profile_added = 0;
    totalNotification = 0;
    visitors: Array<any> = [];
    experience: Array<any> = [];
    reportedUsers: Array<any> = [];
    company: CompanyType = new Company();
    locations: LocationType = new Location();
    user_login_session: UserLoginSessionType = new UserLoginSession();
}

export class GlobalLoaderInitial implements GlobalLoader {
    actionLoading = false;
    notificationLoading = false;
    signUpLogInLoading = false;
    applyJobLoading = false;
    settingsLoading = false;
    contactUsLoading = false;
    reviewEmployerLoading = false;
    similarJobLoading = false;
    otherJobLoading = false;
    chatMessageLoading = false;
}

export class MasterDataInitial implements MasterDataStateType {
    categoryList = [];
}

export class JobStateInitial implements JobStateType {
    jobList = new JobListModal();
    // jobDetails = new JobDetailInitial();
    appliedJobs = new AppliedJobModalIntial();
    similarJobs = new SimilarJobListModalInitial();
    otherJobs = new OtherJobListModalInitial();
    shortlistedJobs = new ShortListJobModalInitial();
}

// export class JobDetailInitial implements JobDetailModalType {
//     _id = '';
//     step = '';
//     status = 0;
//     isExp = '';
//     jobType = 0;
//     isSaved = 0;
//     planId = '';
//     reason = '';
//     latitude = 0;
//     jobDesc = '';
//     address = '';
//     jobCity = '';
//     salaryTo = '';
//     duration = '';
//     jobTitle = '';
//     jobImage = '';
//     jobState = '';
//     shareUrl = '';
//     longitude = 0;
//     isPremium = 0;
//     isApplied = 0;
//     created_at = 0;
//     updated_at = 0;
//     endYearExp = '';
//     salaryFrom = '';
//     premiumEnd = '';
//     categoryId = '';
//     isShortlist = 0;
//     isCommision = 0;
//     endMonthExp = '';
//     companyName = '';
//     companyDesc = '';
//     toExperience = '';
//     categoryName = '';
//     startYearExp = '';
//     date_created = '';
//     premiumStart = '';
//     startMonthExp = '';
//     totalViewCount = 0;
//     isUnderAge = false;
//     companyWebsite = '';
//     fromExperience = '';
//     companyAddress = '';
//     experienceType = '';
//     companyLatitude = 0;
//     totalApplyCount = 0;
//     totalExperience = '';
//     companyLongitude = 0;
//     extraCompensation = '';
//     recruiterId = new RecruiterModal();
// }

export class SimilarJobListModalInitial implements SimilarJobListType {
    sort = 0;
    page = 0;
    search = '';
    jobType = 0;
    jobList = [];
    next = false;
    categoryId = '';
    currentPage = 0;
    lat = parseInt(localStorage.getItem('latitude') || '-91');
    lng = parseInt(localStorage.getItem('longitude') || '-181');
}

export class OtherJobListModalInitial implements OtherJobListType {
    jobList = [];
}

export class AppliedJobModalIntial implements AppliedJobModalType {
    sort = 0;
    applyPage = 0;
    applySearch = '';
    applyJobType = 0;
    applyNext = false;
    appliedJobList = [];
    applyCategoryId = '';
    applyCurrentPage = 0;
    applyLatitude = parseInt(localStorage.getItem('latitude') || '-91');
    applyLongitude = parseInt(localStorage.getItem('longitude') || '-181');
}
export class ShortListJobModalInitial implements ShortListedJobModalType {
    shortListSort = 0;
    shortListPage = 0;
    shortListSearch = '';
    shortListJobType = 0;
    shortListNext = false;
    shortListLatitude = 0;
    shortListJobList = [];
    shortListLongitude = 0;
    shortListCategoryId = '';
    shortListCurrentPage = 0;
}

export class RecruiterModal implements RecruiterModalType {
    _id = '';
    email = '';
    mobile = '';
    last_name = '';
    first_name = '';
    user_image = '';
    isExperience = 0;
}

export class JobModal implements JobModalType {
    __v = 0;
    _id = '';
    step = '';
    isExp = 0;
    status = 0;
    jobUrl = '';
    planId = '';
    reason = '';
    isSaved = 0;
    jobType = 0;
    jobDesc = '';
    address = '';
    jobCity = '';
    jobTitle = '';
    jobImage = '';
    jobState = '';
    salaryTo = '';
    duration = '';
    shareUrl = '';
    isPremium = 0;
    isApplied = 0;
    applyTime = '';
    isCommision = 0;
    categoryId = '';
    premiumEnd = '';
    salaryFrom = '';
    endYearExp = '';
    created_at = '';
    updated_at = '';
    recruiterId = '';
    companyName = '';
    companyDesc = '';
    endMonthExp = '';
    toExperience = 0;
    date_created = '';
    categoryName = '';
    startYearExp = '';
    premiumStart = '';
    isShortlisted = 0;
    fromExperience = 0;
    totalViewCount = 0;
    startMonthExp = '';
    isUnderAge = false;
    companyWebsite = '';
    companyAddress = '';
    experienceType = '';
    companyLatitude = 0;
    totalApplyCount = 0;
    totalExperience = '';
    companyLongitude = 0;
    thirdPartyJob = false;
    extraCompensation = '';
    latitude = parseInt(localStorage.getItem('latitude') || '-91');
    longitude = parseInt(localStorage.getItem('longitude') || '-181');
}

export class JobListModal implements JobListType {
    sort = 2;
    page = 0;
    isMap = 0;
    search = '';
    jobType = 0;
    radius = 20;
    next = false;
    jobByAge = 0;
    jobList = [];
    primpage = 0;
    currentPage = 0;
    categoryId = [];
    thirdPartyPage = 0;
    thirdPartyNext = false;
    recommendedJobList = [];
    jobInfo = new JobModal();
    lat = parseInt(localStorage.getItem('latitude') || '-91');
    lng = parseInt(localStorage.getItem('longitude') || '-181');
}

export class MessageStateInitial implements MessageState {
    inbox = [];
    roomId = '';
    messages = [];
    inboxLimit = 0;
    badgeCount = 0;
    receiverId = '';
    messageLimit = 0;
    lastInboxKey = 0;
    lastMessageKey = 0;
    inboxListLength = 0;
    typingStatus = false;
    hasMoreInbox = false;
    messageListLength = 0;
    hasMoreMessage = false;
}

export class NotificationStateInitial implements NotificationState {
    page = 0;
    next = false;
    notifications = [];
}
export class NotificationDataInitial implements NotificationData {
    type = 0;
    _id = '';
    toRead = 0;
    message = '';
    fromRead = 0;
    senderId = '';
    moduleId = '';
    jobTitle = '';
    createdAt = '';
    senderName = '';
    receiverId = '';
    companyName = '';
    senderImage = '';
    receiverName = '';
}

export class ExperienceDetailsInitial implements ExperienceDetails {
    expType = 0;
    duration = 0;
    position = '';
    categoryId = '';
    companyName = '';
    durationType = 0;
    totalDuration = 0;
    categoryTitle = '';
    jobDescription = '';
    isCurrentCompany = 0;
}

export class CategoryListInitial implements CategoryList {
    _id = '';
    status = 1;
    created_at = '';
    updated_at = '';
    categoryTitle = '';
    categoryImage = '';
    selectedImage = '';
    isLastCategory = 1;
    categoryImageWeb = '';
    selectedCategoryImageWeb = '';
}
export class OnBoardingModal implements OnBoardingType {
    onBoardingModalName = '';
    showOnBoardingModal = false;
}

export class Company implements CompanyType {
    otp = '6829';
    companyName = '';
    mobile = '123456';
    otpExpireTime = '';
    countryCode = '91';
    isOtpVerified = '';
    mobileFormat = '+91 87568658635';
}

export class Location implements LocationType {
    type = '';
    coordinates: Array<any> = [];
}

export class UserLoginSession implements UserLoginSessionType {
    _id = '';
    fcm_token = '';
    authToken = '';
    device_type = '';
    device_token = '';
    loginStatus = false;
}

export class MiscellaneousStateInitial implements MiscellaneousState {
    sideDrawerShow = false;
    previousLocation = '';
    error = new ErrorInitial();
    jobAppliedFlag = false;
    refreshFlag = false;
    success = new SuccessInitial();
    jobAppliedWhenLoggedOutPayload = new (class {
        categoryId = '';
        _id = '';
        recruiterId = '';
    })();
    accountDeactivateModal = new AccountDeactivatedModal();
    contactUsSuccess = false;
    passwordResetToken = '';
    reviewEmpSuccess = false;
    appliedJobRecruiterId = '';
    jobDetailCategoryFilter = [];
    jobDetailAvailabilityFilter = undefined;
    jobDetailRadiusFilter = undefined;
    notificationCount = 0;
    appliedJobFromRecruiterIdPayload = '';
    webContactsConfirmationFlag = false;
    downloadLinkSendConfirmationFlag = false;
}
export class SuccessInitial implements Error {
    msg = '';
}
export class ErrorInitial implements Error {
    msg = '';
}

export class FullAddressModal {
    city = '';
    state = '';
    country = '';
    fullAddress = '';
    latitude = 42.361145;
    longitude = -71.057083;
}

export class AccountDeactivatedModal implements AccountDeactivatedType {
    state = false;
}
export class BasicUserInfoModal {
    image = '';
    email = '';
    mobile = '';
    user_id = '';
    last_name = '';
    isOnline = false;
    first_name = '';
    device_type = '';
    device_token = '';
}
export class MessageModal {
    inbox = [];
    user_id = '';
    roomId = '';
    message = [];
    user_name = '';
    user_image = '';
    user_email = '';
    recruiterId = '';
    user_mobile = '';
    lastInboxKey = '';
    badgeCount = 0;
    lastMessageKey = '';
    hasMoreInbox = false;
    typingStatus = false;
    hasMoreMessage = false;
    // messages: Array<any> = [];
    // lastMessageKey = 0;
    // lastInboxKey = 0;
    // hasMoreMessage = false;
    // hasMoreInbox = false;
    // inboxLimit = 10;
    // messageLimit = 10;
    // inboxListLength = 0;
    // messageListLength = 0;
    // receiverId = '';
    // badgeCount = 0;
}

import React, { memo } from 'react';
import classes from './index.module.scss';
import CustomButton from '../custom-elements/custom-button';

interface Props {
    title: string;
    subTitle?: string;
    positiveBtnText: string;
    negativeBtnText: string;
    removeNegativeBtn?: true;
    positiveBtnHandler: () => void;
    negativeBtnHandler: () => void;
}

export default memo(function ConfirmationModal(props: Props) {
    return (
        <div className={classes['parent-wrapper']} data-test="parent-wrapper">
            <div className={classes['title']} data-test="title">
                {props.title}
            </div>
            <div className={classes['sub-title']} data-test="sub-title">
                {props.subTitle}
            </div>
            <div className={classes['btn-wrapper']}>
                {props.removeNegativeBtn ? (
                    ''
                ) : (
                    <CustomButton
                        styleClass={classes['adjustment-btn-2']}
                        onClickHandler={props.negativeBtnHandler}
                        data-test="neg-button"
                    >
                        {props.negativeBtnText}
                    </CustomButton>
                )}
                <CustomButton
                    styleClass={classes['adjustment']}
                    onClickHandler={props.positiveBtnHandler}
                    data-test="pos-button"
                >
                    {props.positiveBtnText}
                </CustomButton>
            </div>
        </div>
    );
});

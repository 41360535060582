export default {
    faq: `faq`,
    review: `review`,
    logout: 'logout',
    login: 'users/login',
    rateApp: '/rate-app',
    isNotify: 'is-notify',
    forgot: 'users/forgot',
    signUp: 'users/signup',
    webContacts: 'save/contacts',
    terms: 'term-conditions',
    policy: 'privacy-policy',
    reportJob: '/report-job',
    contactUs: '/contact-us',
    applyJob: `users/apply-job`,
    userInfo: '/user/profile/info',
    categoryList: '/category-list',
    addProfile: 'users/add-profile',
    forgotPass: 'users/forgot/password',
    emailVerify: 'users/email/verifyOtp',
    resetPassword: 'users/resetPassword',
    changePass: '/users/change-password',
    downloadLink: 'send/app/downloadLink',
    notificationList: '/notification-list',
    downloadResume: 'download/user/resume',
    facebookLogin: '/users/facebook-login',
    deActivateAccount: 'deactivate-account',
    notificationDelete: '/notification-delete',
    getNotificationCount: '/get/notification/count',
    newResetPassword: '/users/resetPassword/via/otp',
    notificationDeleteOne: '/delete-one-notifiaction',
    applyRecommendedJob: `/users/apply/recommended/jobs`,
    jobDetail: (jobId: string): string => `job/view-detail?jobId=${jobId}`,
    partnerJobDetail: (jobId: string): string => `job/view-detail?jobId=${jobId}`,
    guestJodDetail: (jobId: string): string => `guest/users/job-detail?jobId=${jobId}`,
    otherJobs: (companyName: string): string => `more/jobs?companyName=${companyName}&page=0`,
    similarJob: (page: number, jobId: string): string => `similar-jobs?page=${page}&jobId=${jobId}`,
    guestSimilarJob: (page: number, jobId: string): string => `guest/similar-jobs?page=${page}&jobId=${jobId}`,
    recommendedJob: (lat: number, lng: number): string => `get/recommended/jobs?latitude=${lat}&longitude=${lng}`,
    applyShortListedJob: (page: number, jobType: number, search: string): string =>
        `/users/my-jobs?page=${page}&type=${jobType}&search=${search}`,
    recruiterView: (recruiterId: string, page: number): string =>
        `/recruiter-view?recruiterId=${recruiterId}&page=${page}`,
    guestJobList: (
        page: number,
        sort: number,
        lat: number,
        lng: number,
        search: string,
        categoryId: string,
        isMap = 0,
        radius = 0,
        jobType: number,
        jobByAge: number,
        org = '',
    ): string =>
        `/guest/users/job-list?page=${page}&latitude=${lat}&longitude=${lng}&sort=${sort}&search=${search}&categoryId=${categoryId}&radius=${radius}&primpage=0&isMap=${isMap}&jobType=${
            jobType || 3
        }&isUnderAge=${jobByAge || 3}&org=${org}`,
    jobList: (
        page: number,
        sort: number,
        lat: number,
        lng: number,
        search: string,
        categoryId: string,
        isMap = 0,
        radius = 0,
        jobType: number,
        jobByAge: number,
        org = '',
    ): string =>
        `/users/job-list?page=${page}&latitude=${lat}&longitude=${lng}&sort=${sort}&search=${search}&categoryId=${categoryId}&radius=${radius}&primpage=0&isMap=${isMap}&jobType=${
            jobType || 3
        }&isUnderAge=${jobByAge || 3}&org=${org}`,
};

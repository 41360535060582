import React, { memo } from 'react';
import classes from './banner-job.module.scss';
import ProgressiveImage from 'react-progressive-image';

interface Props {
    jobName: string;
    jobImage: string;
    recruiterName: string;
    handleInterstitialClick: () => void;
}

export default memo(function BannerJob(props: Props) {
    return (
        <>
            <div className={classes['parent-wrapper']}>
                <div className={classes['heading']}>
                    <ProgressiveImage
                        src={props.jobImage}
                        placeholder="https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg"
                    >
                        {(src: string, loading: boolean) => (
                            <img className={classes['banner']} src={src} alt="Job thumbnail" />
                        )}
                    </ProgressiveImage>
                    {/* <img
                        className={classes['banner']}
                        src="https://jobget-static.s3.amazonaws.com/ic-image-placeholder_blue.svg"
                    /> */}
                    <img
                        className={classes['logo']}
                        src={'https://jobget-static.s3.amazonaws.com/new/job-get-baner.svg'}
                        alt="Job Logo"
                    />
                </div>
                <div className={classes['body']}>
                    <div className={classes['title']}>{props.jobName}</div>
                    <span className={classes['info']}>@{props.recruiterName}</span>
                    <button className={classes['btn']} onClick={props.handleInterstitialClick}>
                        Download to apply
                    </button>
                    {/* <span className={classes['dismiss-banner']} onClick={() => props.closeMobileBanner()}>
                        I already have the app
                    </span> */}
                </div>
            </div>
        </>
    );
});

import { put, takeEvery, fork, select } from 'redux-saga/effects';
import * as actionCreators from '../actions/notification';
import * as loaderActionCreators from '../actions/global-loader';
import * as miscellaneousActionCreators from '../actions/miscellaneous';
import * as actionTypes from '../actionTypes/notification';
import Utils from '../../utils';
import { getCall, postCall, deleteCall } from '../../utils/services/network';
import { AppState } from '../reducers/rootReducer';
import { Error } from '../../utils/types';
import OfflineError from '../../utils/offlineError';

export function* workerSetNotificationList() {
    try {
        const { page, notifications } = yield select((state: AppState) => state.notifications);
        let endPoint = Utils.endpoints.notificationList;
        if (page) {
            endPoint = `${endPoint}?page=${page}`;
        }
        yield put(loaderActionCreators.setNotificationLoader(true));
        const response = yield getCall(endPoint, '');

        if (response.data.code === 200) {
            const payload = {
                notifications: [...notifications, ...response.data.data.notifications],
                page: response.data.page,
                next: response.data.next,
            };
            yield put(actionCreators.setNotificationList(payload));
        }
        yield put(loaderActionCreators.setNotificationLoader(false));
    } catch (error) {
        yield put(loaderActionCreators.setNotificationLoader(false));
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            const payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherFetchNotificationList() {
    yield takeEvery(actionTypes.FETCH_NOTIFICATION_LIST, workerSetNotificationList);
}

function* watcherLoadMoreNotificationList() {
    yield takeEvery(actionTypes.LOAD_MORE_NOTIFICATION_LIST, workerSetNotificationList);
}

function* workerDeleteNotification(action: actionTypes.DeleteNotification) {
    try {
        let endPoint = Utils.endpoints.notificationDeleteOne;
        const { notificationId, index } = action.payload;
        const payload = {
            notificationId,
        };
        const response = yield postCall(endPoint, payload);

        if (response.data.code === 200) {
            const { next, page, notifications } = yield select((state: AppState) => state.notifications);
            notifications.splice(index, 1);
            let payload = {
                notifications: [...notifications],
                page: page - 1,
                next: notifications.length === 0 ? false : next,
            };
            yield put(actionCreators.setNotificationList(payload));
        }
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherDeleteNotification() {
    yield takeEvery(actionTypes.DELETE_NOTIFICATION, workerDeleteNotification);
}

function* workerClearNotification() {
    try {
        let endPoint = Utils.endpoints.notificationDelete;
        const response = yield deleteCall(endPoint, '');

        if (response.data.code === 200) {
            let payload = {
                notifications: [],
                page: 0,
                next: false,
            };
            yield put(actionCreators.setNotificationList(payload));
        }
    } catch (error) {
        let message = 'Something went wrong!';
        if (error instanceof OfflineError) {
            message = error.message;
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        } else {
            if (error.response) {
                message = error.response.data.message;
            }
            let payload: Error = {
                msg: message,
            };
            yield put(miscellaneousActionCreators.setError(payload));
        }
    }
}

function* watcherClearNotification() {
    yield takeEvery(actionTypes.CLEAR_NOTIFICATION, workerClearNotification);
}

const NotificationSagas = [
    fork(watcherFetchNotificationList),
    fork(watcherLoadMoreNotificationList),
    fork(watcherDeleteNotification),
    fork(watcherClearNotification),
];

export default NotificationSagas;

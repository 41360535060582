import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import PrivateRoute from './private';
import RecruiterDetail from '../components/recruiter-detail';

interface MatchProps {}

type Props = RouteComponentProps<MatchProps>;

export default function RecruiterModule(props: Props) {
    return (
        <>
            <PrivateRoute path={`${props.match?.path}/:recruiterId`} component={RecruiterDetail} />
        </>
    );
}

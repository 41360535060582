import { fork, select, put, takeLatest, take, cancel } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as actionTypes from '../actionTypes/chat';
import { AppState } from '../reducers/rootReducer';
import FirebaseService from '../../utils/services/firebase-service';
import * as loaderActionCreators from '../actions/global-loader';
import * as actionCreators from '../actions/chat';

function* workerInboxList(action: actionTypes.FetchInboxList) {
    const profileState = yield select((state: AppState) => state.profileDetails);
    const { recruiterId } = action.payload;
    let isInitial = true;
    yield put(loaderActionCreators.setActionLoader(true));
    const response = yield FirebaseService.getInitialInbox(profileState._id, recruiterId, '', false);
    console.log('lastInboxTimeStamp', response);
    //const payload = { inbox: res, lastInboxKey: lastInboxTimeStamp, hasMoreInbox: totalInbox > res.length ? true : false, roomId: recruiterId !== "" ? res[0].roomId : roomId,recruiterId: recruiterId  } }
    const payload = { inbox: [], lastInboxKey: '', hasMoreInbox: false };
    if (response.inbox.length > 0) {
        payload.inbox = response.inbox;
        payload.lastInboxKey = response.lastInboxTimeStamp;
        payload.hasMoreInbox = response.total > response.inbox.length ? true : false;
        //payload.roomId = recruiterId !== '' ? response.inbox.roomId : '';
    }
    yield put(loaderActionCreators.setActionLoader(false));
    yield put(actionCreators.setInboxList(payload));

    const updateChannel = createInboxEventChannel(profileState._id, recruiterId);
    while (true) {
        const item: any = yield take(updateChannel);
        if (isInitial) {
            isInitial = false;
        } else {
            const messageState = yield select((state: AppState) => state.message);
            let inboxList = messageState.inbox;

            const index = inboxList.findIndex((data: any) => data.roomId === item.roomId);
            // console.log('ind', index);
            // console.log('item', item);
            // if (index === -1 && item.type === 1) {
            if (index === -1) {
                // inboxList.unshift(item);
                inboxList[0] = item;
            } else {
                //inboxList[index] = item;
                //for IE support extracting removed item manually
                // const removedItem = { ...inboxList[index] };
                // const temp = [...inboxList];
                // temp.splice(index, 1);
                // temp.splice(0, 0, removedItem);
                // inboxList = [...temp];
                inboxList.splice(index, 1);
                inboxList.unshift(item);
            }
            inboxList = inboxList.slice();
            console.log('fixed', inboxList);

            yield put(actionCreators.updateInboxList({ inbox: inboxList }));
        }
    }
}
function createInboxEventChannel(_id: string, recruiterId: string) {
    let isInitial = true;
    const listener: any = eventChannel((emit) => {
        FirebaseService.registerInboxListener(_id, recruiterId, (data: any) => {
            if (isInitial) {
                isInitial = false;
            } else {
                if (data) {
                    emit(data);
                }
            }
        });
        return () => FirebaseService.removeMessageListener(_id, listener);
    });

    return listener;
}
function* watcherInboxList() {
    yield takeLatest(actionTypes.FETCH_INBOX, workerInboxList);
}
function createEventChannel(_id: string, roomId: string) {
    let isInitial = true;
    const listener: any = eventChannel((emit) => {
        FirebaseService.getMessageListener(_id, roomId, function callback(message: any) {
            if (isInitial) {
                isInitial = false;
            } else {
                if (message) {
                    emit(message);
                }
            }
        });
        return () => FirebaseService.removeMessageListener(roomId, listener);
    });

    return listener;
}

function createEventChannelGetTyping(roomId: string, _id: string) {
    const listener: any = eventChannel((emit) => {
        FirebaseService.getTypingStatus(roomId, _id, function callback(typingStatus: boolean) {
            emit(typingStatus);
        });
        return () => FirebaseService.removeGetTypingStatus(roomId, listener);
    });

    return listener;
}

function* WorkerGetMessage(action: actionTypes.FetchMessage) {
    let isInitial = true;
    const profileState = yield select((state: AppState) => state.profileDetails);
    const messageState = yield select((state: AppState) => state.message);
    const { roomId } = action.payload;
    if (!roomId) {
        const index = messageState.inbox.findIndex((item: any) => item.roomId === roomId);
        //return;
        if (index !== -1) {
            const userInfo = messageState.inbox[index].user;
            const payload = {
                roomId: '',
                message: [],
                user_id: userInfo.user_id,
                user_name: `${userInfo.first_name} ${userInfo.last_name}`,
                user_image: userInfo.image,
                user_email: userInfo.email,
                user_mobile: userInfo.mobile,
                recruiterId: userInfo.user_id,
                lastMessageKey: '',
                hasMoreMessage: false,
            };
            yield put(actionCreators.setMessageList(payload));
        }
    } else {
        // yield put(loaderActionCreators.setChatMessageLoader(true));
        const response = yield FirebaseService.getMessage(profileState._id, roomId);
        const payload = {
            roomId: '',
            message: [],
            user_id: '',
            user_name: '',
            user_image: '',
            user_email: '',
            user_mobile: '',
            recruiterId: '',
            lastMessageKey: '',
            hasMoreMessage: false,
        };

        const index = messageState.inbox.findIndex((item: any) => item.roomId === roomId);
        if (response.message.length > 0) {
            if (index !== -1) {
                console.log(messageState.inbox[index]);
                const userInfo = messageState.inbox[index].user;
                payload.user_name = `${userInfo.first_name} ${userInfo.last_name}`;
                payload.user_image = userInfo.image;
                payload.user_email = userInfo.email;
                payload.user_mobile = userInfo.mobile;
                payload.user_id = userInfo.user_id;
                payload.message = response.message;
                payload.recruiterId = userInfo.user_id;
                payload.roomId = roomId;
                payload.hasMoreMessage = response.total > response.message.length ? true : false;

                const newBadgeCount = messageState.badgeCount - messageState.inbox[index].unreadCount;
                FirebaseService.updateUnreadBadgeCount(profileState._id, roomId, newBadgeCount);
                const inboxList = messageState.inbox;
                inboxList[index].unreadCount = 0;
                yield put(actionCreators.updateInboxList({ inbox: inboxList }));
            }

            //payload.roomId = recruiterId !== '' ? response.inbox.roomId : '';
        }

        yield put(actionCreators.setMessageList(payload));
        yield put(loaderActionCreators.setChatMessageLoader(false));
        const updateChannel = createEventChannel(profileState._id, roomId);

        while (true) {
            const item = yield take(updateChannel);
            const messageState = yield select((state: AppState) => state.message);
            let messageList = messageState.message;
            messageList.push({ ...item });
            messageList = messageList.slice();
            const payload = {
                message: messageList,
            };
            yield put(actionCreators.addMessageList(payload));
        }
    }
}

function* WorkerGetTyping(action: actionTypes.FetchMessage) {
    const { roomId, recieverId } = action.payload;
    if (!roomId) {
        return false;
    }
    FirebaseService.removeGetTypingStatus(roomId, recieverId);
    const updateTypingChannel = createEventChannelGetTyping(roomId, recieverId);
    while (true) {
        const typingStatus = yield take(updateTypingChannel);
        yield put(actionCreators.getTypingStatus({ typingStatus: typingStatus }));
    }
}

function* watcherGetMessage() {
    yield takeLatest(actionTypes.FETCH_MESSAGE, WorkerGetMessage);
    yield takeLatest(actionTypes.FETCH_MESSAGE, WorkerGetTyping);
}

function* watcherInitialGetMessage() {
    yield takeLatest(actionTypes.FETCH_INITIAL_MESSAGE, WorkerGetMessage);
}

function* WorkerSendMessage(action: actionTypes.SendMessage) {
    const messageState = yield select((state: AppState) => state.message);
    const profileState = yield select((state: AppState) => state.profileDetails);
    const { messageText, callbackFn } = action.payload;
    if (!messageState.roomId && profileState._id) {
        if (messageState.recruiterId) {
            const roomId = yield FirebaseService.sendInitialMessage(
                messageText,
                profileState._id,
                messageState.recruiterId,
                profileState.first_name,
                profileState.last_name,
            );

            // callbackFn(roomId);
            // if (roomId) {
            //     const updateChannel = createEventChannel(profileState._id, roomId);
            //     while (true) {
            //         const item = yield take(updateChannel);
            //         const messageState = yield select((state: AppState) => state.message);
            //         let messageList = messageState.message;
            //         messageList.push({ ...item });
            //         messageList = messageList.slice();
            //         const payload = {
            //             message: messageList,
            //         };
            //         console.log(payload);
            //         yield put(actionCreators.addMessageList(payload));
            //     }
            // }
        } else {
            return false;
        }
    } else {
        if (profileState._id) {
            const index = messageState.inbox.findIndex((item: any) => item.roomId === messageState.roomId);
            let deviceToken = '';
            let deviceType = 1;
            if (index !== -1) {
                deviceToken = messageState.inbox[index].user.device_token;
                deviceType = messageState.inbox[index].user.device_type;
            }
            FirebaseService.sendMessage(
                messageState.roomId,
                messageText,
                profileState._id,
                messageState.recruiterId,
                profileState.first_name,
                profileState.last_name,
                deviceToken,
                deviceType,
                profileState.email,
                profileState.user_image,
                profileState.mobile,
            );
        }
    }
}

function* watcherSendMessage() {
    yield takeLatest(actionTypes.SEND_MESSAGE, WorkerSendMessage);
}

function* WorkerSetTyping(action: actionTypes.GetTyping) {
    const { typingStatus } = action.payload;
    const profileState = yield select((state: AppState) => state.profileDetails);
    const messageState = yield select((state: AppState) => state.message);
    if (!messageState.roomId) {
        return false;
    }

    FirebaseService.setTypingStatus(messageState.roomId, profileState._id, typingStatus);
}

function* watcherSetTypingStatus() {
    yield takeLatest(actionTypes.SET_TYPING, WorkerSetTyping);
}

function createEventBadgeCount(userId: string) {
    const listener: any = eventChannel((emit) => {
        FirebaseService.getBadgeCount(userId, function callback(count: number) {
            emit(count);
        });
        return () => FirebaseService.removeGetBadgeCount(userId, listener);
    });

    return listener;
}

function* WorkerBadgeCount(action: actionTypes.GetTyping) {
    const profileState = yield select((state: AppState) => state.profileDetails);
    const updateGetBadgeCount = createEventBadgeCount(profileState._id);
    while (true) {
        const count = yield take(updateGetBadgeCount);
        yield put(actionCreators.setBadgeCount({ badgeCount: count }));
    }
}

function* watcherBadgeCount() {
    yield takeLatest(actionTypes.GET_BADGE_COUNT, WorkerBadgeCount);
}

const ChatSagas = [
    fork(watcherInboxList),
    fork(watcherGetMessage),
    fork(watcherSendMessage),
    fork(watcherSetTypingStatus),
    fork(watcherBadgeCount),
    fork(watcherInitialGetMessage),
];
export default ChatSagas;

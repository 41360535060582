import { CategoryList } from '../../utils/types';
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST';
export interface SetCategoryList {
    type: typeof SET_CATEGORY_LIST;
}

export const SET_CATEGORY_LIST_SUCCESS = 'SET_CATEGORY_LIST_SUCCESS';
export interface SetCategoryListSuccess {
    type: typeof SET_CATEGORY_LIST_SUCCESS;
    payload: CategoryList[];
}

export type types = SetCategoryList | SetCategoryListSuccess;
